import Loading from "@notemeal/shared/ui/global/Loading";
import TablePage from "apps/web/src/components/universal/TablePage";
import { RefetchQueriesContextProvider } from "apps/web/src/contexts/RefetchQueries";
import { MealMenusWithCheckInInRangeDocument, TeamMealMenuPreviewFragment, useMealMenusWithCheckInInRangeQuery } from "apps/web/src/types";
import { getRowsForTablePage, useOffsetPagination } from "apps/web/src/utils/pagination";
import { AttendanceError } from "apps/web/src/views/MenuAttendance/ViewAttendance/AttendanceError";
import { exportCrossMealReportToExcel } from "apps/web/src/views/MenuAttendance/ViewAttendance/ExportCrossMealReport";
import { ViewAttendanceTableHeaderRow, ViewAttendanceTableRow } from "apps/web/src/views/MenuAttendance/ViewAttendance/ViewAttendanceRows";
import { ViewAttendanceTableToolbar } from "apps/web/src/views/MenuAttendance/ViewAttendance/ViewAttendanceToolbar";
import { MealMenusCheckInDataToViewAttendanceRows } from "apps/web/src/views/MenuAttendance/ViewAttendance/utils";
import React, { useState } from "react";

const EMPTY_RETURN_ERROR_TITLE = "No Meals";
const EMPTY_RETURN_ERROR_DESCRIPTION =
  "There are no meals with check-in enabled in the specified date range. Please try again by adjusting the dates.";
const QUERY_ERROR_TITLE = "Error";
const QUERY_ERROR_DESCRIPTION = "Unable to build the report.";

interface ViewAttendancePageProps {
  start: string;
  end: string;
}

export const ViewAttendancePage = ({ start, end }: ViewAttendancePageProps) => {
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
  const [selectedTeams, setSelectedTeams] = useState<TeamMealMenuPreviewFragment[]>([]);
  const [selectedMealType, setSelectedMealType] = useState("all");
  const [isQueryError, setIsQueryError] = useState(false);

  const { data, loading } = useMealMenusWithCheckInInRangeQuery({ variables: { start, end }, onError: () => setIsQueryError(true) });

  const refetchQueries = [{ query: MealMenusWithCheckInInRangeDocument, variables: { start, end } }];

  const paginationHooks = useOffsetPagination();
  const { page, limit, resetOffset } = paginationHooks;

  if (loading) {
    return <Loading />;
  }

  const handleChangeHasAccess = (newHasAccess: boolean | null) => {
    setHasAccess(newHasAccess);
    resetOffset();
  };

  const handleChangeTeams = (newSelectedTeams: readonly TeamMealMenuPreviewFragment[]) => {
    setSelectedTeams([...newSelectedTeams]);
    resetOffset();
  };

  const handleChangeMealType = (newSelectedMealType: string) => {
    setSelectedMealType(newSelectedMealType);
    resetOffset();
  };

  const filterRowTeamIdsAgainstSelectedTeamIds = (rowTeamIds: string[], selectedTeamIds: string[]): boolean => {
    const overlap = rowTeamIds.filter(id => selectedTeamIds.includes(id));
    return overlap.length > 0;
  };

  // tidy up after above TODOs
  const allRows = data?.mealMenusWithCheckInInRange ? MealMenusCheckInDataToViewAttendanceRows([...data.mealMenusWithCheckInInRange]) : [];
  const filteredTeamRows =
    selectedTeams.length > 0
      ? allRows.filter(r =>
          filterRowTeamIdsAgainstSelectedTeamIds(
            r.teamIds,
            selectedTeams.map(t => t.id)
          )
        )
      : allRows;
  const rows = selectedMealType === "all" ? filteredTeamRows : filteredTeamRows.filter(r => r.type === selectedMealType);

  const allTeams: { id: string; name: string }[] = data?.mealMenusWithCheckInInRange
    ? data.mealMenusWithCheckInInRange.flatMap(mm => [...mm.mealMenu.teams])
    : [];

  const allMealTypes: string[] = data?.mealMenusWithCheckInInRange ? data.mealMenusWithCheckInInRange.map(mm => mm.mealMenu.type) : [];

  const exportData = () => exportCrossMealReportToExcel({ fileName: "Attendance Report", rows });

  const pageRows = getRowsForTablePage({ rows, limit, page });

  const isEmptyError = allRows.length === 0;
  const isError = isQueryError || isEmptyError;
  const errorTitle = isEmptyError ? EMPTY_RETURN_ERROR_TITLE : QUERY_ERROR_TITLE;
  const errorDescription = isEmptyError ? EMPTY_RETURN_ERROR_DESCRIPTION : QUERY_ERROR_DESCRIPTION;

  return (
    <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
      {isError ? (
        <AttendanceError title={errorTitle} description={errorDescription} />
      ) : (
        <TablePage
          tableHeaderRow={<ViewAttendanceTableHeaderRow />}
          header={
            <ViewAttendanceTableToolbar
              mealTypes={[...new Set(allMealTypes)]}
              setSelectedMealType={handleChangeMealType}
              teams={[...new Set(allTeams)]}
              selectedTeams={selectedTeams}
              onChangeTeams={handleChangeTeams}
              onChangeHasAccess={handleChangeHasAccess}
              exportData={exportData}
            />
          }
          paginationHooks={paginationHooks}
          total={rows.length}
          tableBodyRows={pageRows.map(row => (
            <ViewAttendanceTableRow input={row} hasAccess={hasAccess} />
          ))}
          loading={false}
        />
      )}
    </RefetchQueriesContextProvider>
  );
};
