import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LightbulbIcon from "@mui/icons-material/EmojiObjects";
import ErrorIcon from "@mui/icons-material/Error";
import MenuIcon from "@mui/icons-material/Menu";
import SubjectIcon from "@mui/icons-material/Subject";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, Divider, IconButton, Menu, MenuItem, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { initMacros, macroGPerKgToGramsMetricMacros } from "@notemeal/shared/utils/macro-protocol";
import React, { useReducer, useState } from "react";
import { v4 as uuid } from "uuid";
import { ConfirmationDialog } from "../../componentLibrary";
import { TWTabGroup } from "../../componentLibrary/TWTabGroup/TWTabGroup";
import TWItemizedTooltip from "../../componentLibrary/TWTooltip/TWItemizedTooltip";
import {
  DeleteMacroMealPlanTemplatePendingStateInput,
  FullMealPlanTemplateFragment,
  GoalTypeFragment,
  MacroMealPlanTemplatePendingStateInput,
  PublishMacroMealPlanTemplateInput,
} from "../../types";
import FoodPreferencesChipList from "../FoodPreference/MealPlan/FoodPreferenceChipList";
import FoodPreferenceModal from "../FoodPreference/MealPlan/FoodPreferenceModal";
import GenerateMealOptionsDialog, { GeneratedMealOptions } from "../MealPlan/GenerateMealOptions/ConfigurationDialog";
import { MealOptionInput } from "../MealPlan/GenerateMealOptions/useGenerateMealOptions";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import EditMealPlanStepper, { EditMealPlanSteps } from "./EditMealPlanTemplateStepper";
import EditSectionHeader from "./EditSectionHeader";
import FoodPreferencesContextProvider from "./FoodPreferences/ContextProvider";
import SampleDataModal from "./MacroProtocol/Anthropometry/SampleDataModal";
import SampleDataSummary from "./MacroProtocol/Anthropometry/SampleDataSummary";
import GoalsChipList from "./MacroProtocol/Goals/GoalsChipList";
import MacroProtocolSummary from "./MacroProtocol/MacroProtocolSummary";
import { MacroProtocolState } from "./MacroProtocol/reducer/macroProtocolReducer";
import { getAnthroSnapshotFromAnthroState } from "./MacroProtocol/utils/macroProtocolUtils";
import { EditSchedule } from "./Schedule/EditSchedule";
import Schedule from "./Schedule/Schedule";
import { MealOptionInputItemWithId } from "./Schedule/scheduleReducer";
import { buildInitialEditStateFromMealPlanTemplate, editMealPlanTemplateReducer } from "./mealPlanTemplateReducer";
import {
  buildMacroMealPlanTemplatePendingStateInputFromState,
  buildPublishMacroMealPlanTemplateInputFromState,
  convertMPTMacroAnthroInputsToLocale,
  getCanSaveMacroMealPlanTemplateToolTips,
  getGoalAlert,
  getTotalParts,
} from "./mealPlanTemplateUtils";

const useStyles = makeStyles(({ spacing, palette: { secondary, text, grey } }: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "1px 300px 10px 1fr 1px",
      gridTemplateRows: "1px 160px 10px 1fr 1px",
      minWidth: "970px",
      minHeight: "830px",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      gridRow: 2,
      gridColumnStart: 2,
      gridColumnEnd: 5,
      display: "grid",
      gridTemplateColumns: "35px 500px 1fr",
      gridTemplateRows: "1fr 1fr",
      alignItems: "center",
    },
    headerClose: {
      gridColumn: 1,
      padding: 0,
    },
    headerName: {
      gridColumn: 2,
      gridRow: 1,
      margin: 5,
    },
    headerDescription: {
      gridColumn: 2,
      gridRow: 2,
      margin: 5,
    },
    headerHint: {
      gridColumnStart: 2,
      gridColumnEnd: 4,
      gridRow: 3,
      color: text.secondary,
    },
    headerBottomDivider: {
      gridRow: 3,
      gridColumnStart: 1,
      gridColumnEnd: 5,
      borderColor: "black",
    },
    summaries: {
      gridRow: 4,
      gridColumn: 2,
      display: "flex",
      flexDirection: "column",
      marginTop: spacing(2),
      paddingRight: spacing(2),
    },
    summary: {
      paddingBottom: spacing(3),
    },
    summariesScheduleDivider: {
      gridColumn: 3,
      gridRow: 4,
      borderColor: "black",
    },
    schedule: {
      gridRow: 4,
      gridColumn: 4,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    verticalContainer: {
      display: "flex",
      flexDirection: "column",
    },
    toggleButton: {
      height: 35,
      color: grey[500],
      borderColor: grey[500],
    },
    menuItemIcon: {
      marginRight: spacing(1),
    },
    goalIcon: {
      marginLeft: spacing(1),
      marginBottom: spacing(0.5),
      verticalAlign: "middle",
    },
  })
);

interface EditMealPlanTemplateProps {
  viewOnly?: boolean;
  isShared?: boolean;
  mealPlanTemplate: FullMealPlanTemplateFragment;
  editMealPlanTemplate: (input: MacroMealPlanTemplatePendingStateInput) => void;
  publishMealPlanTemplate: (input: PublishMacroMealPlanTemplateInput) => void;
  deletePendingState: (input: DeleteMacroMealPlanTemplatePendingStateInput) => void;
  onClose: () => void;
  goalTypes: readonly GoalTypeFragment[];
}

const EditMealPlanTemplate = ({
  viewOnly,
  isShared = false,
  mealPlanTemplate,
  editMealPlanTemplate,
  publishMealPlanTemplate,
  deletePendingState,
  goalTypes,
  onClose,
}: EditMealPlanTemplateProps) => {
  const classes = useStyles();
  const { isMetricLocale } = useLocaleContext();

  const initialEditState = convertMPTMacroAnthroInputsToLocale(
    buildInitialEditStateFromMealPlanTemplate(mealPlanTemplate, isMetricLocale),
    isMetricLocale
  );

  const initialPendingState = buildMacroMealPlanTemplatePendingStateInputFromState(initialEditState).pendingState;
  const [editScheduleOpen, setEditScheduleOpen] = useState(false);
  const [editSampleDataOpen, setEditSampleDataOpen] = useState(false);
  const [generateMealOptionsModalOpen, setGenerateMealOptionsModalOpen] = useState(false);
  const [editFoodPreferencesOpen, setEditFoodPreferencesOpen] = useState(false);
  const [initialStep, setInitialStep] = useState<EditMealPlanSteps | null>(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLElement | null>(null);
  const [state, dispatch] = useReducer(editMealPlanTemplateReducer, initialEditState);
  const canSaveTooltips = getCanSaveMacroMealPlanTemplateToolTips(state);
  const hasPendingState = !!mealPlanTemplate.pendingState;
  const editedPendingState = buildMacroMealPlanTemplatePendingStateInputFromState(state).pendingState;
  const editedInitial = initialPendingState !== editedPendingState;
  const editedPending = hasPendingState && mealPlanTemplate.pendingState !== editedPendingState;
  const canSaveDraft = editedPending || editedInitial;
  const canPublish = hasPendingState || canSaveDraft;
  const { goals } = state.macroProtocol.calorieBudget;
  const { setMessage } = useSnackbar();
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);

  const MACRO_TARGETS = "Macro Targets";
  const MEAL_OPTIONS = "Meal Options";
  const [selected, setSelected] = useState(MACRO_TARGETS);
  const buttons = [MACRO_TARGETS, MEAL_OPTIONS];

  const handleDeletePendingState = () => {
    deletePendingState({ macroMealPlanTemplateId: mealPlanTemplate.id });
    const mealPlanTemplateWithoutPending = { ...mealPlanTemplate, pendingState: null };
    dispatch({
      type: "RESET",
      payload: convertMPTMacroAnthroInputsToLocale(
        buildInitialEditStateFromMealPlanTemplate(mealPlanTemplateWithoutPending, isMetricLocale),
        isMetricLocale
      ),
    });
  };

  const { cho, pro, fat } = state.macroProtocol;
  const { weightInKg } = getAnthroSnapshotFromAnthroState(state.macroProtocol.anthropometry);
  const macros = macroGPerKgToGramsMetricMacros(cho, pro, fat, weightInKg);
  const { cho: choGrams, pro: proGrams, fat: fatGrams } = macros;
  const totalParts = getTotalParts(state.meals);
  const mealOptionInputs: MealOptionInput[] = state.meals.map(mealTemplate => {
    const {
      id: mealTemplateId,
      choRatio,
      proRatio,
      fatRatio,
      meal: { type: mealType },
    } = mealTemplate;
    const { choTotalParts, proTotalParts, fatTotalParts } = totalParts;
    const cho = choGrams * (choRatio / choTotalParts);
    const pro = proGrams * (proRatio / proTotalParts);
    const fat = fatGrams * (fatRatio / fatTotalParts);
    const macros = initMacros(cho, pro, fat);

    return { mealTemplateId, mealType, macros };
  });

  const onGenerateMealOptionsClose = () => setGenerateMealOptionsModalOpen(false);
  const onGenerateMealOptionsComplete = (generatedMealOptions: GeneratedMealOptions) => {
    const { removeExistingMealOptions, mealOptionsForMealTemplates } = generatedMealOptions;
    if (mealOptionsForMealTemplates.length === 0) {
      setMessage("error", "Meal options fitting this meal plan template were not found.");
    } else {
      const mealOptionsForMealTemplatesMap = new Map(
        mealOptionsForMealTemplates.map(mealOptionsForMealTemplate => [
          mealOptionsForMealTemplate.mealTemplateId,
          mealOptionsForMealTemplate,
        ])
      );
      state.meals.forEach(meal => {
        const oldMealOptions: MealOptionInputItemWithId[] = removeExistingMealOptions ? [] : meal.mealOptions.concat();
        const newMealOptions =
          mealOptionsForMealTemplatesMap.get(meal.id)?.mealOptions.map(mealOption => ({ ...mealOption, id: uuid(), name: null })) || [];
        const mealOptions = oldMealOptions.concat(newMealOptions).map((mealOption, index) => ({ ...mealOption, position: index }));
        const newState = { ...meal, mealOptions };
        dispatch({
          type: "EDIT_MEAL",
          payload: newState,
        });
      });
    }
    setMessage("success", "Generated meal options for meal plan template");
    onGenerateMealOptionsClose();
  };

  const getGoalAlertIcon = (): React.ReactElement | undefined => {
    const goalAlert = getGoalAlert(state.macroProtocol);
    return goalAlert ? (
      <TWItemizedTooltip title={goalAlert.reason} items={goalAlert.goals}>
        {goalAlert.type === "warn" ? (
          <WarningIcon className={classes.goalIcon} fontSize="inherit" />
        ) : (
          <ErrorIcon
            className={classes.goalIcon}
            color="error"
            fontSize="inherit" />
        )}
      </TWItemizedTooltip>
    ) : undefined;
  };

  const handleClose = () => {
    if (canSaveDraft) {
      setConfirmCloseOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <FoodPreferencesContextProvider foodPreferences={state.foodPreferences}>
      <div className={classes.root}>
        <div className={classes.header}>
          <IconButton
            className={classes.headerClose}
            onClick={handleClose}
            size="large">
            <CloseIcon fontSize="large" />
          </IconButton>
          <TextField
            autoFocus
            className={classes.headerName}
            inputProps={{ maxLength: 50 }}
            disabled={viewOnly}
            label="Name"
            onChange={e => dispatch({ type: "EDIT_NAME", payload: e.target.value })}
            value={state.name}
            sx={{ fontSize: "20px" }}
          />
          <Tooltip className={classes.headerDescription} title={state.description || ""}>
            <TextField
              inputProps={{ maxLength: 2000 }}
              disabled={viewOnly}
              label="Description"
              onChange={e => dispatch({ type: "EDIT_DESCRIPTION", payload: e.target.value })}
              value={state.description || ""}
            />
          </Tooltip>
          <Typography className={classes.headerHint} variant="subtitle1">
            * Nutrient targets (daily and meal) are based off of sample values. The actual values will be different for each athlete, and
            meal options will be scaled accordingly.
          </Typography>
          {!viewOnly && (
            <Box sx={{ gridColumn: 3, gridRow: 1, justifyContent: "flex-end", display: "flex", gap: 2 }}>
              {hasPendingState && (
                <Button variant="outlined" onClick={() => handleDeletePendingState()}>
                  Discard Draft
                </Button>
              )}
              <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
                <Button
                  variant="outlined"
                  disabled={!canSaveDraft}
                  onClick={() => {
                    if (canSaveTooltips.length > 0) {
                      return;
                    }
                    editMealPlanTemplate(buildMacroMealPlanTemplatePendingStateInputFromState(state));
                  }}
                >
                  Save Draft
                </Button>
              </TWItemizedTooltip>
              <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
                <Button
                  disabled={!canPublish}
                  onClick={() => {
                    if (canSaveTooltips.length > 0) {
                      return;
                    }
                    publishMealPlanTemplate(buildPublishMacroMealPlanTemplateInputFromState(state, isMetricLocale));
                  }}
                >
                  Publish Changes
                </Button>
              </TWItemizedTooltip>
            </Box>
          )}
        </div>
        <Divider className={classes.headerBottomDivider} />
        <div className={classes.summaries}>
          <div className={classes.summary}>
            <EditSectionHeader
              title="Daily Macros"
              showEdit={!viewOnly}
              onEdit={() => setInitialStep(EditMealPlanSteps.MacroProtocol)} />
            <MacroProtocolSummary macroProtocol={state.macroProtocol} />
          </div>

          <div className={classes.summary}>
            <EditSectionHeader
              title="Sample Data"
              showEdit={!viewOnly}
              onEdit={() => setInitialStep(EditMealPlanSteps.SampleData)} />
            <SampleDataSummary
              state={state.macroProtocol.anthropometry}
              sampleGoal={state.macroProtocol.calorieBudget.selectedPreviewGoal}
              usePrefix
            />
          </div>

          <div className={classes.summary}>
            <EditSectionHeader
              title="Goals"
              showEdit={!viewOnly}
              onEdit={() => setInitialStep(EditMealPlanSteps.Goals)}
              icon={getGoalAlertIcon()}
            />
            <GoalsChipList goals={goals} />
          </div>

          <div className={classes.summary}>
            <EditSectionHeader
              title="Food Preferences"
              showEdit={!viewOnly}
              onEdit={() => setEditFoodPreferencesOpen(true)} />
            <FoodPreferencesChipList foodPreferenceState={state.foodPreferences} />
          </div>
        </div>
        <Divider
          className={classes.summariesScheduleDivider}
          orientation="vertical"
          flexItem />
        <div className={classes.schedule}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1, mx: 1, mb: 0 }}>
            <TWTabGroup tabs={buttons} onSelected={selected => setSelected(selected)} />
            {!viewOnly && (
              <Button
                variant="outlined"
                startIcon={<MenuIcon />}
                onClick={e => setActionsAnchorEl(e.currentTarget)}>
                Actions
              </Button>
            )}
            <Menu
              open={actionsAnchorEl !== null}
              anchorEl={actionsAnchorEl}
              onClose={() => setActionsAnchorEl(null)}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                key="distribute-macros"
                onClick={() => {
                  dispatch({ type: "DISTRIBUTE_MACROS" });
                  setActionsAnchorEl(null);
                }}
              >
                <SubjectIcon className={classes.menuItemIcon} fontSize="small" />
                Reset Default Ratios
              </MenuItem>
              <MenuItem
                key="edit-schedule"
                onClick={() => {
                  setEditScheduleOpen(true);
                  setActionsAnchorEl(null);
                }}
              >
                <EditIcon
                  className={classes.menuItemIcon}
                  fontSize="small"
                  color="action" />
                Edit Schedule
              </MenuItem>
              {
                <MenuItem
                  key="generate-meal-options"
                  onClick={() => {
                    setGenerateMealOptionsModalOpen(true);
                    setActionsAnchorEl(null);
                  }}
                >
                  <LightbulbIcon
                    className={classes.menuItemIcon}
                    fontSize="small"
                    color="action" />
                  Generate Meal Options
                </MenuItem>
              }
            </Menu>
          </Box>
          <Box sx={{ pt: 1, overflowY: "auto" }}>
            <Schedule
              showMealMacroTargets={selected === MACRO_TARGETS}
              viewOnly={viewOnly}
              isShared={isShared}
              scheduleState={{
                meals: state.meals,
                activities: state.activities,
              }}
              macros={macros}
              dispatch={dispatch}
            />
          </Box>
          {initialStep !== null && (
            <EditMealPlanStepper
              initialMacroProtocolState={state.macroProtocol}
              goalTypes={goalTypes}
              initialStep={initialStep}
              onClose={() => {
                setInitialStep(null);
              }}
              onDone={(macroProtocol: MacroProtocolState) => {
                setInitialStep(null);
                dispatch({ type: "UPDATE_MACRO_PROTOCOL", payload: macroProtocol });
              }}
            />
          )}
          {editFoodPreferencesOpen && (
            <FoodPreferenceModal
              initialFoodPreferenceState={state.foodPreferences}
              open={editFoodPreferencesOpen}
              onClose={() => setEditFoodPreferencesOpen(false)}
              onDone={newState => {
                dispatch({
                  type: "UPDATE_FOOD_PREFERENCES",
                  payload: newState,
                });
                setEditFoodPreferencesOpen(false);
              }}
            />
          )}
          {editScheduleOpen && (
            <EditSchedule
              open={editScheduleOpen}
              onClose={() => setEditScheduleOpen(false)}
              dispatch={dispatch}
              state={{ meals: state.meals, activities: state.activities }}
            />
          )}
          {generateMealOptionsModalOpen && (
            <GenerateMealOptionsDialog
              open={generateMealOptionsModalOpen}
              onClose={onGenerateMealOptionsClose}
              mealOptionInputs={mealOptionInputs}
              onCompleted={onGenerateMealOptionsComplete}
            />
          )}
          {editSampleDataOpen && (
            <SampleDataModal
              open={editSampleDataOpen}
              onClose={() => setEditSampleDataOpen(false)}
              onDone={(macroProtocol: MacroProtocolState) => {
                dispatch({ type: "UPDATE_MACRO_PROTOCOL", payload: macroProtocol });
                dispatch({ type: "DISTRIBUTE_MACROS" });
              }}
              initialMacroProtocolState={state.macroProtocol}
            />
          )}
          {confirmCloseOpen && (
            <ConfirmationDialog
              open={confirmCloseOpen}
              onCancel={() => setConfirmCloseOpen(false)}
              onConfirm={onClose}
              variant="containedDestructive"
              message="Are you sure you want to go back? All unsaved changes will be discarded."
              confirmLabel="Discard"
              title="Discard Unsaved Changes"
            />
          )}
        </div>
      </div>
    </FoodPreferencesContextProvider>
  );
};

export default EditMealPlanTemplate;
