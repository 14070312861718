import { alpha, Chip, useTheme } from "@mui/material";
import React from "react";

export interface NewChipProps {
  variant?: "default" | "nav";
}

export const NewChip = ({ variant }: NewChipProps) => {
  const {
    palette: { info, common },
    spacing,
  } = useTheme();

  return (
    <Chip
      size="small"
      sx={{
        marginLeft: spacing(1),
        backgroundColor: variant === "nav" ? alpha(common.white, 0.15) : info.lighter,
        color: variant === "nav" ? common.white : info.main,
        fontWeight: 500,
        fontSize: "11px",
        lineHeight: "14px",
        height: "20px",
      }}
      label="New"
    />
  );
};
