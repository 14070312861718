import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FormControl, IconButton, Menu, MenuItem, Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import { useOrgRecipesTableQuery } from "apps/web/src/types";
import { useUser } from "apps/web/src/utils/tokens";
import { Score } from "libs/shared/ui/src/lib/Score/Score";
import React, { useEffect } from "react";
import { AiAugmentedRecipeName } from "../AiAugmentedRecipeName";
import { useRecipesPage } from "../useRecipesPage";

const MoreInfoMenu = () => {
  const user = useUser();

  const {
    setMoreInfo,
    moreInfo,
    setDuplicateRecipeId,
    setDeleteableRecipe,
    setPrintRecipeId,
    setMoveToOrgGroupRecipe,
    hasOrgGroupResourceSharing,
  } = useRecipesPage();

  if (!moreInfo) {
    return null;
  }

  return (
    <Menu
      anchorEl={moreInfo?.moreAnchorElement}
      open={!!moreInfo}
      onClose={() => {
        setMoreInfo(null);
      }}
      keepMounted
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        dense
        onClick={() => {
          setDuplicateRecipeId(moreInfo.recipe.id);
          setMoreInfo(null);
        }}
      >
        Duplicate
      </MenuItem>
      <MenuItem
        dense
        onClick={() => {
          setDeleteableRecipe(moreInfo.recipe);
          setMoreInfo(null);
        }}
      >
        Delete
      </MenuItem>
      <MenuItem
        dense
        onClick={() => {
          setPrintRecipeId(moreInfo.recipe.id);
          setMoreInfo(null);
        }}
      >
        Print
      </MenuItem>
      {user?.orgMembership?.isAdmin && hasOrgGroupResourceSharing && (
        <MenuItem
          dense
          onClick={() => {
            setMoveToOrgGroupRecipe(moreInfo.recipe);
            setMoreInfo(null);
          }}
        >
          Move to Org Group
        </MenuItem>
      )}
    </Menu>
  );
};

export const OrgRecipeRows = () => {
  const {
    paginationHooks,
    handleEditIsShared,
    scoringSystem,
    createdById,
    selectedMealTypes,
    setEditRecipe,
    setMoreInfo,
    setTotalRows,
    setRowsLoading,
  } = useRecipesPage();
  const { limit, offset, query } = paginationHooks;
  const user = useUser();

  const { data: recipeData, loading: recipeLoading } = useOrgRecipesTableQuery({
    variables: {
      input: { limit, offset },
      sort: null,
      query,
      createdById,
      mealTypes: selectedMealTypes.length > 0 ? selectedMealTypes : null,
      orgId: user?.orgMembership?.org.id || null,
    },
    onCompleted: data => {
      if (data) {
        setTotalRows(data.recipeOffsetConnection.pageInfo.total);
      }
    },
  });

  useEffect(() => {
    setRowsLoading(recipeLoading);
  }, [recipeLoading, setRowsLoading]);

  const rows = recipeData?.recipeOffsetConnection.edges || [];

  return (
    <>
      {rows.map(row => {
        const { id, name, isShared, createdBy, mealTypes, servings, score, isAiGenerated, isAiTranscribed } = row;
        const aiAugmented = isAiGenerated || isAiTranscribed;
        return (
          <TableRow
            key={id}
            id={id}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => setEditRecipe(row)}>
            <TableCell>
              <Tooltip title={"Share recipe with athletes"}>
                <FormControl>
                  <Switch
                    size="medium"
                    inputProps={{
                      "aria-label": "Share recipe with athletes",
                    }}
                    checked={isShared}
                    onClick={e => e.stopPropagation()}
                    onChange={e => {
                      handleEditIsShared(id, e.target.checked);
                    }}
                  />
                </FormControl>
              </Tooltip>
            </TableCell>
            {aiAugmented ? (
              <TableCell>
                <AiAugmentedRecipeName
                  name={name}
                  sx={{ fontSize: "14px" }}
                  isAiGenerated={isAiGenerated}
                  isAiTranscribed={isAiTranscribed}
                />
              </TableCell>
            ) : (
              <TableCell>{name}</TableCell>
            )}
            <TableCell>
              {createdBy.firstName} {createdBy.lastName}
            </TableCell>
            <TableCell>
              {mealTypes &&
                mealTypes
                  .map(mt => {
                    const mealTypeString = mt.toString();
                    return mealTypeString.charAt(0).toUpperCase() + mealTypeString.slice(1);
                  })
                  .sort()
                  .join(", ")}
            </TableCell>
            <TableCell>{servings.map(s => `${s.perRecipeYield} ${s.units}`).join(", ")}</TableCell>
            {scoringSystem && (
              <TableCell>
                {score && (
                  <>
                    {" "}
                    <Score
                      scoreValue={score.value}
                      scoringSystem={scoringSystem}
                      sx={{ gap: 0.5 }}
                      size="lg" />
                  </>
                )}
              </TableCell>
            )}
            <TableCell>
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  setMoreInfo({ moreAnchorElement: e.currentTarget, recipe: row });
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      <MoreInfoMenu />
    </>
  );
};
