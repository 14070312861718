import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  ProfileSyncRuleWithEntities,
  assignProfilesToSyncRules,
  getEditTeamworksProfileLinksInput,
  getInitTeamworksProfilesState,
} from "@notemeal/profile-sync";
import { DraggableSpring } from "@notemeal/shared/ui/hooks/useDraggable";
import newId from "@notemeal/shared/ui/utils/newId";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import classnames from "classnames";
import React, { useReducer, useState } from "react";
import { ReactEventHandlers } from "react-use-gesture/dist/types";
import LoadingBackdrop from "../../../../../components/universal/LoadingBackdrop";
import {
  EditOrgTeamworksSkippedProfile,
  OrgForLinkingFragment,
  TeamworksGroupFragment,
  TeamworksPositionLinkFragment,
  TeamworksProfileFragment,
  TeamworksTeamFragment,
  useEditOrgTeamworksProfilesMutation,
} from "../../../../../types";
import LinkModalHeader from "../shared/LinkModalHeader";
import ArchiveAthleteDialog from "./ArchiveAthlete/Dialog";
import ArchiveAthleteItem from "./ArchiveAthlete/Item";
import NotemealOnlyDialog from "./NotemealOnly/Dialog";
import NotemealOnlyItem from "./NotemealOnly/Item";
import OtherProfilesCard from "./OtherProfilesCard";
import SyncRuleDialog from "./SyncRule/Dialog";
import DraggableProfileSyncRuleList from "./SyncRule/DraggableProfileSyncRuleList";
import SyncRuleItem from "./SyncRule/Item";
import UnusedNotemealTeamWarning from "./UnusedNotemealTeamWarning";
import { enforceRulePriority, teamworksProfilesReducer } from "./reducer";

const useStyles = makeStyles(
  ({
    spacing,
    palette: {
      success,
      error,
      accents: { yellow },
    },
  }: Theme) =>
    createStyles({
      content: {
        display: "flex",
        flexDirection: "column",
      },
      items: {
        display: "flex",
      },
      subItem: {
        flexGrow: 1,
        margin: spacing(1, 2),
      },
      item: {
        margin: spacing(1, 2),
        flexShrink: 0,
      },
      syncStatus: {
        display: "flex",
        alignItems: "center",
        marginRight: spacing(3),
      },
      syncStatusIcon: {
        fontSize: 36,
        marginRight: spacing(),
      },
      syncStatusGreen: {
        color: success.dark,
      },
      syncStatusYellow: {
        color: yellow[400],
      },
      syncStatusRed: {
        color: error.main,
      },
      title: {
        margin: spacing(2, 0, 2, 2),
      },
    })
);

interface TeamworksProfileSyncContentProps {
  teamworksTeams: readonly TeamworksTeamFragment[];
  teamworksPositions: readonly TeamworksGroupFragment[];
  teamworksUserTypes: readonly TeamworksGroupFragment[];
  teamworksAthleteStatuses: readonly TeamworksGroupFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
  allTeamworksProfiles: readonly TeamworksProfileFragment[];
  org: OrgForLinkingFragment;
  onClose: () => void;
  onNext: (skippedProfiles: readonly EditOrgTeamworksSkippedProfile[]) => void;
}

const TeamworksProfileSyncContent = ({
  teamworksTeams,
  teamworksUserTypes,
  teamworksAthleteStatuses,
  allTeamworksProfiles,
  teamworksPositionLinks,
  teamworksPositions,
  org,
  onClose,
  onNext,
}: TeamworksProfileSyncContentProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const [state, dispatch] = useReducer(
    teamworksProfilesReducer,
    enforceRulePriority(
      getInitTeamworksProfilesState({
        org,
        allTeamworksProfiles,
        teamworksTeams,
        teamworksUserTypes,
        teamworksAthleteStatuses,
        teamworksPositions,
      })
    )
  );
  const [selectedProfileSyncRuleId, setSelectedProfileSyncRuleId] = useState<string | null>(null);

  const [notemealOnlyModalOpen, setNotemealOnlyModalOpen] = useState(false);
  const [archiveAthleteOpen, setArchiveAthleteOpen] = useState(false);

  const linkedTeamworksTeams = teamworksTeams.flatMap(teamworksTeam => (teamworksTeam.notemealTeams.length > 0 ? teamworksTeam : []));

  const syncRulesWithProfiles = assignProfilesToSyncRules(state, linkedTeamworksTeams);
  const selectedProfileSyncRule = syncRulesWithProfiles.syncRules.find(r => r.id === selectedProfileSyncRuleId);

  const handleAddRule = () => {
    const profileSyncRuleId = newId();
    dispatch({
      type: "ADD_PROFILE_SYNC_RULE",
      payload: {
        profileSyncRuleId,
      },
    });
    setSelectedProfileSyncRuleId(profileSyncRuleId);
  };

  const handleRemoveRule = (profileSyncRuleId: string) => {
    dispatch({
      type: "REMOVE_PROFILE_SYNC_RULE",
      payload: {
        profileSyncRuleId,
        linkedTeamworksTeams,
      },
    });
  };

  const [editProfiles, { loading: savingProfiles }] = useEditOrgTeamworksProfilesMutation({
    onCompleted: data => {
      setMessage("success", "Successfully created/linked/synced Profiles");
      const skippedProfiles = data.editOrgTeamworksProfiles.skippedProfiles;
      onNext(skippedProfiles);
      onClose();
    },
    onError: () => {
      setMessage("error", "Error occurred while creating/linking/syncing Profiles");
    },
  });

  const handleEditProfiles = () => {
    const input = getEditTeamworksProfileLinksInput(org, state, teamworksPositionLinks, linkedTeamworksTeams);
    if (input.type === "err") {
      setMessage("error", input.err);
    } else {
      editProfiles({
        variables: {
          input: input.ok,
        },
      });
    }
  };

  const syncStatus = (() => {
    const currentIsNotemealLinked = org.isNotemealLinked;
    const pendingIsNotemealLinked = state.unlinkedNotemealProfiles.length === 0;
    const baseText = org.isFullySynced || (org.isTeamworksLinked && pendingIsNotemealLinked) ? "Fully Synced" : "Notemeal Linked";
    if (pendingIsNotemealLinked) {
      return (
        <div className={classes.syncStatus}>
          <CheckBoxIcon className={classnames(classes.syncStatusIcon, classes.syncStatusGreen)} />
          <Typography>{baseText}</Typography>
        </div>
      );
    } else {
      if (currentIsNotemealLinked) {
        return (
          <div className={classes.syncStatus}>
            <CancelIcon className={classnames(classes.syncStatusIcon, classes.syncStatusRed)} />
            <Typography>Breaking {baseText}!</Typography>
          </div>
        );
      } else {
        return (
          <div className={classes.syncStatus}>
            <WarningIcon className={classnames(classes.syncStatusIcon, classes.syncStatusYellow)} />
            <Typography>Not {baseText}</Typography>
          </div>
        );
      }
    }
  })();

  const unlinkedNotemealAthletes = state.unlinkedNotemealProfiles.flatMap(p => (p.__typename === "Athlete" ? p : []));
  const unlinkedNotemealProfiles = state.unlinkedNotemealProfiles;

  const matchOnProfileRules = syncRulesWithProfiles.syncRules.filter(rule => rule.matchOnProfiles);
  const nonProfileRules = syncRulesWithProfiles.syncRules.filter(rule => !rule.matchOnProfiles);

  const renderRule = (rule: ProfileSyncRuleWithEntities, bindProps?: ReactEventHandlers, spring?: DraggableSpring) => (
    <SyncRuleItem
      key={`${rule.id}-${rule.priority}`}
      profileSyncRule={rule}
      onClick={() => setSelectedProfileSyncRuleId(rule.id)}
      onRemove={() => handleRemoveRule(rule.id)}
      className={classes.item}
      allLinkedTeamworkTeams={linkedTeamworksTeams}
      bindProps={bindProps}
      spring={spring}
    />
  );

  return (
    <>
      {savingProfiles && <LoadingBackdrop open onClose={() => {}} />}
      <DialogTitle>
        <LinkModalHeader
          activeStep={1}
          nextDisabled={false}
          onNextClicked={handleEditProfiles}
          onClose={onClose}
          orgName={org.name}>
          {syncStatus}
        </LinkModalHeader>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <OtherProfilesCard
          className={classes.item}
          toDeactivateLinkedProfiles={syncRulesWithProfiles.toDeactivateLinkedProfiles}
          toDeactivateOrgMemberships={syncRulesWithProfiles.toDeactivateOrgMemberships}
        />
        <UnusedNotemealTeamWarning
          className={classes.item}
          syncRulesWithProfiles={syncRulesWithProfiles.syncRules}
          linkedTeamworksTeams={linkedTeamworksTeams}
        />
        <div className={classes.items}>
          <NotemealOnlyItem
            className={classes.subItem}
            onClick={() => setNotemealOnlyModalOpen(true)}
            notemealOnlyCount={
              state.notemealOnlyState.notemealOnlyOrgMemberships.length + state.notemealOnlyState.notemealOnlyAthletes.length
            }
            unlinkedCount={unlinkedNotemealProfiles.length}
          />
          <ArchiveAthleteItem
            onClick={() => setArchiveAthleteOpen(true)}
            className={classes.subItem}
            archiveAthleteCount={state.archiveAthletes.length}
            unlinkedAthleteCount={unlinkedNotemealAthletes.length}
          />
        </div>
        {matchOnProfileRules.length > 0 && <Typography className={classes.title}>Profile Based Rules</Typography>}
        {matchOnProfileRules.map(rule => renderRule(rule))}
        {nonProfileRules.length > 0 && <Typography className={classes.title}>Team Based Rules</Typography>}
        <DraggableProfileSyncRuleList
          rules={nonProfileRules}
          key={nonProfileRules.map(({ id }) => id).join("-")}
          renderRule={renderRule}
          ruleHeight={108}
          onChangeOrder={ids => dispatch({ type: "REORDER_OTHER_PROFILE_SYNC_RULES", payload: ids })}
        />
        <Button onClick={handleAddRule} className={classes.item}>
          Add Profile Sync Rule
        </Button>
        {selectedProfileSyncRule && (
          <SyncRuleDialog
            open={!!selectedProfileSyncRule}
            onClose={() => setSelectedProfileSyncRuleId(null)}
            unlinkedNotemealProfiles={state.unlinkedNotemealProfiles}
            profileSyncRule={selectedProfileSyncRule}
            teamworksTeams={linkedTeamworksTeams}
            teamworksUserTypes={teamworksUserTypes}
            teamworksAthleteStatuses={teamworksAthleteStatuses}
            allTeamworksProfiles={allTeamworksProfiles}
            teamworksPositions={teamworksPositions}
            dispatch={dispatch}
          />
        )}
        {notemealOnlyModalOpen && (
          <NotemealOnlyDialog
            open={notemealOnlyModalOpen}
            onClose={() => setNotemealOnlyModalOpen(false)}
            unlinkedNotemealProfiles={unlinkedNotemealProfiles}
            notemealOnlyState={state.notemealOnlyState}
            dispatch={dispatch}
          />
        )}
        {archiveAthleteOpen && (
          <ArchiveAthleteDialog
            open={archiveAthleteOpen}
            onClose={() => setArchiveAthleteOpen(false)}
            unlinkedNotemealAthletes={unlinkedNotemealAthletes}
            archiveAthletes={state.archiveAthletes}
            onArchive={athleteId =>
              dispatch({
                type: "ARCHIVE_ATHLETE",
                payload: {
                  athleteId,
                },
              })
            }
            onUnarchive={athleteId =>
              dispatch({
                type: "UNARCHIVE_ATHLETE",
                payload: {
                  athleteId,
                },
              })
            }
          />
        )}
      </DialogContent>
    </>
  );
};

export default TeamworksProfileSyncContent;
