import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import DisplayCard, { DisplayCardProps } from "../../../shared/DisplayCard";

export interface ProfileForDisplay {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  missingTeamworksRequiredFields: boolean;
  additionalTeamworksData?: {
    team: string | null | undefined;
    usertype: string | null | undefined;
    athleteStatus: string | null | undefined;
  };
}

export const PROFILE_CARD_HEIGHT = 75;
export const PROFILE_CARD_ADDITIONAL_INFO_HEIGHT = 85;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: PROFILE_CARD_HEIGHT,
    },
    cardWithAdditionalInfo: {
      height: PROFILE_CARD_ADDITIONAL_INFO_HEIGHT,
    },
  })
);

interface ProfileCardProps<T> extends Omit<DisplayCardProps<T>, "primaryText" | "secondaryElement"> {
  getProfileForDisplay: (profile: T) => ProfileForDisplay;
}

const ProfileCard = <T extends any>(props: ProfileCardProps<T>) => {
  const { element, getProfileForDisplay } = props;
  const { firstName, lastName, email, phoneNumber, missingTeamworksRequiredFields, additionalTeamworksData } =
    getProfileForDisplay(element);
  const classes = useStyles();

  const primaryText = `${lastName ?? "<NO LAST NAME>"}, ${firstName ?? "<NO FIRST NAME>"}`;
  const emailText = email ?? "<NO EMAIL>";
  const phoneText = phoneNumber ?? "<NO PHONE>";
  let secondaryElement = `${emailText}, ${phoneText}`;

  if (additionalTeamworksData?.team) {
    secondaryElement = secondaryElement.concat(`, ${additionalTeamworksData.team}`);
  }
  if (additionalTeamworksData?.usertype) {
    secondaryElement = secondaryElement.concat(`, ${additionalTeamworksData.usertype}`);
  }
  if (additionalTeamworksData?.athleteStatus) {
    secondaryElement = secondaryElement.concat(`, ${additionalTeamworksData.athleteStatus}`);
  }

  return (
    <DisplayCard
      {...props}
      disabled={props.disabled || missingTeamworksRequiredFields}
      mode={missingTeamworksRequiredFields ? "Info" : props.mode}
      infoTooltip={
        missingTeamworksRequiredFields
          ? "Missing one or more required fields (first name, last name, user id) on Teamworks profile"
          : undefined
      }
      className={props.hasAdditionalProfileInfo ? classes.cardWithAdditionalInfo : classes.card}
      primaryText={primaryText}
      secondaryElement={secondaryElement}
    />
  );
};

export default ProfileCard;
