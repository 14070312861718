import { Clear as ClearIcon } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, MenuItem, Select, Typography } from "@mui/material";
import { Role } from "@notemeal/graphql/types";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { AutocompleteQuerySearchBar } from "apps/web/src/components/universal/AutocompleteQuerySearchBar/AutocompleteQuerySearchBar";
import { useStreamChat } from "apps/web/src/contexts/StreamChatContext";
import {
  MessageOptionFragment,
  UsersICanMessageCursorConnectionDocument,
  UsersICanMessageCursorConnectionQuery,
  UsersICanMessageCursorConnectionQueryVariables,
  useTeamsForFilterQuery,
} from "apps/web/src/types";
import React, { useState } from "react";

interface CreateChannelModalProps {
  open: boolean;
  onClose: () => void;
}

export const CreateChannelModal = ({ open, onClose }: CreateChannelModalProps) => {
  const { startChat } = useStreamChat();
  const [role, setRole] = useState(Role.athlete);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<MessageOptionFragment | null>(null);
  const { data } = useTeamsForFilterQuery();

  const onSelect = () => {
    if (selectedOption) {
      startChat({ withUserId: selectedOption.id, name: `${selectedOption.firstName} ${selectedOption.lastName}` });
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={"Create Message"} onClose={onClose} />
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Box sx={{ width: "100%", display: "flex", gap: 0.5, flexDirection: "column" }}>
            <Typography variant="body2">Role</Typography>
            <Select
              labelId="create-message-role"
              fullWidth
              value={role}
              label="Role"
              displayEmpty
              onChange={o => {
                const newRole = o.target.value as Role;
                if (newRole !== Role.athlete) {
                  setTeamId(null);
                }
                setRole(newRole);
              }}
            >
              <MenuItem value={Role.athlete}>Athletes</MenuItem>
              <MenuItem value={Role.dietitian}>Dietitians</MenuItem>
              <MenuItem value={Role.chef}>Chefs</MenuItem>
              <MenuItem value={Role.foodmanager}>Food Managers</MenuItem>
            </Select>
          </Box>
          {role === Role.athlete && (
            <Box sx={{ width: "100%", display: "flex", gap: 0.5, flexDirection: "column" }}>
              <Typography variant="body2">Team</Typography>
              <Select
                fullWidth
                value={teamId}
                label="Team"
                labelId="create-message-team"
                placeholder="Team"
                displayEmpty
                endAdornment={
                  teamId && (
                    <InputAdornment sx={{ marginRight: "10px" }} position="end">
                      <IconButton onClick={() => setTeamId(null)}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                onChange={o => setTeamId(o.target.value)}
              >
                {data?.teams.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {role !== Role.athlete && (
            <Box sx={{ width: "100%" }} /> // spacer
          )}
        </Box>
        <AutocompleteQuerySearchBar<
          MessageOptionFragment,
          UsersICanMessageCursorConnectionQuery,
          UsersICanMessageCursorConnectionQueryVariables
        >
          sx={{ marginTop: 0, marginBottom: 2 }}
          minInputChars={0}
          query={UsersICanMessageCursorConnectionDocument}
          placeholder="Search Users"
          getOptionLabel={a => ` ${a.firstName} ${a.lastName}`}
          groupBy={
            role !== Role.athlete || teamId ? undefined : { sort: (a, b) => (a.teamName > b.teamName ? 1 : -1), label: a => a.teamName }
          }
          getOptionsFromQueryData={data => [...data.usersICanMessageCursorConnection.edges]}
          getQueryOptions={query => ({
            variables: { query, input: { limit: 50, cursor: null }, role, teamId },
          })}
          onChange={setSelectedOption}
          getUserFriendlyQueryErrorMessage={() => "Error searching users, please try again."}
          clearOnSelect={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!selectedOption} onClick={onSelect}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
