import { useApolloClient } from "@apollo/client";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { ReactNode, useState } from "react";
import { ConfirmationDialog } from "../../../componentLibrary";
import { useOffsetPagination } from "../../../utils/pagination";
import Table from "./Table";
import { MenuAction, TogglableRowProps } from "./TableRow";
import { HeaderRowInfo, Idable, OffsetConnectionData, RenderCreateDialogProps, RenderEditDialogProps, UseFetchQueryArgs } from "./types";

interface GeneralizedStaffContentProps<T extends Idable> {
  headerRowInfo: HeaderRowInfo[];
  convertObjToRowData: (obj: T) => Record<string, ReactNode>;
  queryCacheKey?: string;
  useFetchQuery: (query: UseFetchQueryArgs) => {
    data: OffsetConnectionData<T> | undefined;
    loading: boolean;
  };
  entityName: string;
  onTableRowClick?: (obj: T) => void;
  getObjDisplay: (obj: T) => string;
  processDelete?: (obj: T) => Promise<void>;
  renderEditDialog?: (props: RenderEditDialogProps<T>) => JSX.Element;
  renderCreateDialog: (props: RenderCreateDialogProps<T>) => JSX.Element;
  showSearchQuery?: boolean;
  togglable?: TogglableRowProps<T>;
  actionMenuItems?: MenuAction<T>[];
  onCreateSuccessMessage?: (obj: T) => string;
  onEditSuccessMessage?: (obj: T) => string;
  onDeleteSuccessMessage?: (obj: T) => string;
}

const GeneralizedStaffContent = <T extends Idable>({
  headerRowInfo,
  convertObjToRowData,
  useFetchQuery,
  queryCacheKey,
  entityName,
  onTableRowClick,
  togglable,
  getObjDisplay,
  processDelete,
  renderCreateDialog,
  renderEditDialog,
  actionMenuItems,
  showSearchQuery = true,
  onCreateSuccessMessage,
  onEditSuccessMessage,
  onDeleteSuccessMessage,
}: GeneralizedStaffContentProps<T>) => {
  const paginationHooks = useOffsetPagination();
  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();
  const { limit, offset, query } = paginationHooks;
  const { data, loading } = useFetchQuery({
    variables: {
      query,
      input: { limit, offset },
    },
  });
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editInfo, setEditInfo] = useState<T | null>(null);
  const [deleteInfo, setDeleteInfo] = useState<T | null>(null);

  const resetTable = () => {
    if (queryCacheKey) {
      apolloClient.cache.evict({
        fieldName: queryCacheKey,
        broadcast: true,
      });
    }
  };

  const hasEdit = renderEditDialog !== undefined;
  const hasDelete = processDelete !== undefined;

  const onCreateSuccess = (obj: T) => {
    resetTable();
    setMessage(
      "success",
      onCreateSuccessMessage ? onCreateSuccessMessage(obj) : `Successfully created ${entityName} ${getObjDisplay(obj)}`
    );
  };
  const onEditSuccess = (obj: T) => {
    resetTable();
    setMessage("success", onEditSuccessMessage ? onEditSuccessMessage(obj) : `Successfully edited ${entityName} ${getObjDisplay(obj)}`);
  };
  const handleDelete = async (obj: T) => {
    if (processDelete) {
      await processDelete(obj);
    }
    resetTable();
    setDeleteInfo(null);
    setMessage(
      "success",
      onDeleteSuccessMessage ? onDeleteSuccessMessage(obj) : `Successfully deleted ${entityName} ${getObjDisplay(obj)}`
    );
  };

  return (
    <>
      <Table<T>
        headerRowInfo={headerRowInfo}
        convertObjToRowData={convertObjToRowData}
        offsetConnectionData={data}
        loading={loading}
        paginationHooks={paginationHooks}
        entityName={entityName}
        onCreate={() => setShowCreateDialog(true)}
        onEdit={hasEdit ? obj => setEditInfo(obj) : undefined}
        onDelete={hasDelete ? obj => setDeleteInfo(obj) : undefined}
        onRowClick={onTableRowClick ? obj => onTableRowClick(obj) : undefined}
        showSearchQuery={showSearchQuery}
        togglable={togglable}
        actionMenuItems={actionMenuItems}
      />
      {showCreateDialog &&
        renderCreateDialog({
          open: showCreateDialog,
          onClose: () => setShowCreateDialog(false),
          onCreateSuccess,
        })}
      {renderEditDialog &&
        !!editInfo &&
        renderEditDialog({
          open: !!editInfo,
          onClose: () => setEditInfo(null),
          initialEditInfo: editInfo,
          onEditSuccess,
        })}
      {!!deleteInfo && (
        <ConfirmationDialog
          open={deleteInfo !== null}
          title={`Delete ${entityName}`}
          message={`Are you sure that you would like to delete '${entityName}'?`}
          onCancel={() => setDeleteInfo(null)}
          onConfirm={() => handleDelete(deleteInfo)}
          variant="containedDestructive"
        />
      )}
    </>
  );
};

export default GeneralizedStaffContent;
