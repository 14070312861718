import { ApolloProvider } from "@apollo/client";
import "@fontsource/inter/variable-full.css";
import { StyledEngineProvider } from "@mui/material";
import { ClientTimezoneContextProvider } from "@notemeal/shared/ui/contexts/ClientTimezone";
import "@teamworksdev/react/dist/index.css";
import "stream-chat-react/dist/css/index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import App from "./App";
import client from "./client";
import { SnackbarContextProvider } from "./components/Snackbar/SnackbarContext";
import { CookieContextProvider } from "./contexts/Cookie";
import { MultiTabManagerProvider } from "./contexts/MultiTabManager";
import { ThemeModeContextProvider } from "./pages/Theme/ThemeModeContextProvider";
import { initReporting } from "./reporting/reporting";

initReporting();

ReactDOM.render(
  <CookieContextProvider>
    <StyledEngineProvider injectFirst>
      <ThemeModeContextProvider>
        <ApolloProvider client={client}>
          <ClientTimezoneContextProvider>
            <SnackbarContextProvider>
              <BrowserRouter>
                <CompatRouter>
                  <MultiTabManagerProvider>
                    <App />
                  </MultiTabManagerProvider>
                </CompatRouter>
              </BrowserRouter>
            </SnackbarContextProvider>
          </ClientTimezoneContextProvider>
        </ApolloProvider>
      </ThemeModeContextProvider>
    </StyledEngineProvider>
  </CookieContextProvider>,
  document.getElementById("root")
);
