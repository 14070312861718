import { isMealMenuAfterEnd, isMealMenuClosed } from "@notemeal/shared/ui/MealMenu/utils";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import Loading from "@notemeal/shared/ui/global/Loading";
import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { addDays } from "date-fns";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { useKioskConfigurationQuery } from "../../../../types";
import CheckInKiosk from "../../../../views/Kiosk/CheckIn";
import { KioskConfiguration } from "../../../../views/Kiosk/Configure/KioskConfiguration";
import { KioskCheckInContextProvider } from "../../../../views/Kiosk/Contexts/CheckInContext";
import OrderKiosk from "../../../../views/Kiosk/Order";
import { KioskMode, ORDER } from "../../../../views/Kiosk/utils";
import { NAV_ORG_KITCHEN_KIOSK } from "./KitchenPaths";

export const KioskPage = () => {
  const navigate = useNavigate();
  const { date: maybeDate, menuIds } = useParams();
  const today = serializeDate(new Date());
  const date = maybeDate || today;
  const dateObj = parseDate(date);
  const menuIdsObj: string[] = useMemo(() => (isNil(menuIds) ? [] : JSON.parse(menuIds)), [menuIds]);

  const [kioskMode, setKioskMode] = useState<KioskMode>(ORDER);
  const [configuredComp, setConfigured] = useState(menuIdsObj.length > 0);
  const paramsConfigured = menuIdsObj.length > 0;

  const configured = paramsConfigured && configuredComp;

  const clientTimezone = useClientTimezone();

  const after = serializeDate(dateObj);
  const before = serializeDate(addDays(dateObj, 1));

  const variables = { after, before, clientTimezone };

  const { data, loading } = useKioskConfigurationQuery({
    variables,
    fetchPolicy: "network-only",
  });

  // When Dealing with Ordering Only allow menus that are open for ordering and have an orderable item
  const openForOrderMealMenus = data?.mealMenusInDateRange.filter(mm => !isMealMenuClosed(mm) && mm.isOrderable) || [];

  // When Dealing with Check In Only allow menus that have not ended yet
  const openForCheckInMealMenus =
    data?.mealMenusInDateRange.filter(mm => !isMealMenuAfterEnd(mm) && mm.isHubCheckInEnabled && mm.hubEventUrl) || [];

  const openMenus = kioskMode === ORDER ? openForOrderMealMenus : openForCheckInMealMenus;

  const changeKioskMode = (mode: KioskMode) => {
    const newPossibleMenus = mode === ORDER ? openForOrderMealMenus : openForCheckInMealMenus;
    const newPossibleMenuIds = newPossibleMenus.map(mm => mm.id);
    const newMenuIds = menuIdsObj.filter(id => newPossibleMenuIds.includes(id));
    if (newMenuIds.length !== menuIdsObj.length) {
      navigate(`/kiosk/${date}/${JSON.stringify(newMenuIds)}`, { replace: true });
    }
    setKioskMode(mode);
  };

  const filteredMealMenus = openMenus.filter(mm => menuIdsObj.includes(mm.id));

  // error catch for improper menu ids in url
  // selected menu ids should always === filtered meal menus
  useEffect(() => {
    if (menuIdsObj.length > 0 && !loading && filteredMealMenus.length !== menuIdsObj.length) {
      navigate(`${NAV_ORG_KITCHEN_KIOSK}/${date}`, { replace: true });
    }
  }, [menuIdsObj, date, filteredMealMenus.length, loading, navigate]);

  const handleSetSelectedMealMenus = (mealMenuId: string) => {
    setConfigured(false);

    // new select mealMenu functionality only allows for one meal menu
    const newMenuIds = [mealMenuId];
    navigate(`${NAV_ORG_KITCHEN_KIOSK}/${date}/${JSON.stringify(newMenuIds)}`, { replace: true });
  };

  const handleChangeDate = () => {
    navigate(`${NAV_ORG_KITCHEN_KIOSK}/${date}`, { replace: true });
  };

  const handleOnNext = () => {
    if (kioskMode === ORDER) {
      setConfigured(true);
    } else {
      // for now set meal menu is only one menu
      const mm = filteredMealMenus[0];
      const redirectUrl = mm.hubEventUrl;
      // redirect url can be asserted due to openForCheckInMealMenus filtering above
      window.open(redirectUrl!, "_blank");
    }
  };

  return openMenus && data ? (
    <>
      <KioskConfiguration
        mealMenus={openMenus}
        onSelectMealMenu={handleSetSelectedMealMenus}
        onChangeDate={handleChangeDate}
        selectedMealMenuIds={menuIdsObj}
        kioskMode={kioskMode}
        setKioskMode={changeKioskMode}
        onNext={handleOnNext}
        date={date}
      />
      {/* TODO: Tags Performance should we only fetch athleteCount in useKioskConfigurationQuery
      and then fetch full athletes on filteredMealMenus as a dataloader step? */}
      {configured && kioskMode === "Order" ? (
        <OrderKiosk
          open={configured}
          onClose={() => setConfigured(false)}
          mealMenus={filteredMealMenus}
          date={date} />
      ) : (
        <KioskCheckInContextProvider mealMenuIds={filteredMealMenus.map(mm => mm.id)}>
          <CheckInKiosk
            open={configured}
            onClose={() => setConfigured(false)}
            mealMenus={filteredMealMenus} />
        </KioskCheckInContextProvider>
      )}
    </>
  ) : (
    <Loading />
  );
};
