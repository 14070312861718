import { MenuItem, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import React from "react";
import { Role } from "../../../../types";

interface RoleMultiSelectProps {
  roles: Role[];
  selectedRoles: Role[];
  setSelectedRoles: (roles: Role[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedTeam: {
      fontWeight: "bold",
    },
    select: {
      minWidth: 300,
    },
  })
);

const RoleMultiSelect = ({ roles, selectedRoles, setSelectedRoles }: RoleMultiSelectProps) => {
  const classes = useStyles();
  const handleChange = (roles: Role[]) => {
    setSelectedRoles(roles.filter(r => roles.includes(r)));
  };

  return (
    <TextField
      select
      className={classes.select}
      SelectProps={{
        multiple: true,
        renderValue: value => {
          const _roles = value as Role[];
          return (
            <div>
              {_roles
                .filter(role => roles.includes(role))
                .map(r => capitalize(r))
                .join(", ")}
            </div>
          );
        },
      }}
      label="Roles"
      margin="dense"
      value={selectedRoles}
      onChange={e => handleChange(e.target.value as unknown as Role[])}
    >
      {roles.map(r => (
        <MenuItem
          key={r}
          value={r}
          className={selectedRoles.some(sr => sr === r) ? classes.selectedTeam : undefined}>
          {capitalize(r)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default RoleMultiSelect;
