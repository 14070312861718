import { Box, TextField, Typography } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { ScoreSelect } from "libs/shared/ui/src/lib/Score/ScoreSelect";
import React, { Dispatch } from "react";
import { MealType, ScoringSystemFragment } from "../../../types";
import { FoodCategorySelect } from "../../FoodCategory/FoodCategorySelect";
import MealTypeMultiSelect from "../../Meal/TypeMultiSelect";
import { RecipeFormTimeInMinutesInput } from "./TimeInMinutesInput";
import { RecipeFormAction, RecipeFormState } from "./utils";

interface DetailsProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  scoringSystem?: ScoringSystemFragment | null;
  disabled?: boolean;
  forStaff?: boolean;
}

const Details = ({ dispatch, state, scoringSystem, disabled, forStaff }: DetailsProps) => {
  const hasFoodCategory = useHasFeature("foodCategory");

  const handleChangeMealTypes = (mealTypes: readonly MealType[]) => {
    dispatch({
      type: "CHANGE_MEAL_TYPES",
      value: mealTypes,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h3" color="textSecondary">
        Details
      </Typography>
      <TextField
        disabled={disabled}
        label="Recipe Name"
        fullWidth
        value={state.name}
        onChange={e =>
          dispatch({
            type: "CHANGE_NAME",
            value: e.target.value,
          })
        }
      />
      <MealTypeMultiSelect
        disabled={disabled}
        value={state.mealTypes}
        onChange={handleChangeMealTypes}
        sx={{ width: "100%" }} />
      <Box sx={{ display: "flex", justifyContent: scoringSystem ? "space-between" : "flex-start", rowGap: 2, columnGap: 2 }}>
        <RecipeFormTimeInMinutesInput
          disabled={disabled}
          sx={{ flexBasis: "33%" }}
          label="Prep Time"
          value={state.prepTimeInMinutesInput}
          onChange={value =>
            dispatch({
              type: "CHANGE_PREP_TIME",
              value,
            })
          }
        />
        <RecipeFormTimeInMinutesInput
          disabled={disabled}
          label="Cook Time"
          value={state.cookTimeInMinutesInput}
          sx={{ flexBasis: "33%" }}
          onChange={value =>
            dispatch({
              type: "CHANGE_COOK_TIME",
              value,
            })
          }
        />
        {!forStaff && scoringSystem && (
          <ScoreSelect
            disabled={disabled}
            scoreValue={state.scoreValue}
            scoringSystem={scoringSystem}
            onChange={value =>
              dispatch({
                type: "CHANGE_SCORE",
                value,
              })
            }
          />
        )}
        {!forStaff && hasFoodCategory && (
          <FoodCategorySelect
            disabled={disabled}
            category={state.foodCategory || null}
            onChange={value =>
              dispatch({
                type: "CHANGE_FOOD_CATEGORY",
                foodCategory: value,
              })
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default Details;
