import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Box, Button, Grid, Typography } from "@mui/material";
import ServingAmountViewChipList from "@notemeal/shared/ui/ServingAmount/View/ChipList";
import { newServingAmount, servingAmountsUseOldRecipe } from "@notemeal/shared/ui/ServingAmount/utils";
import { getNextPosition } from "@notemeal/shared/ui/utils/getNextPosition";
import {
  FullServingAmountFragment,
  RecipeFullFragment,
  RecipeLimitedAccessFragment,
  RecipeSearchFragment,
  useMyScoringSystemQuery,
} from "apps/web/src/types";
import { useHasIntegration } from "apps/web/src/utils/integration";
import React, { useState } from "react";
import CompassImportRecipeDialog from "../../Integration/Compass/ImportRecipeDialog";
import RecipeCreateDialog from "../../Recipe/RecipeCreateDialog";
import { ServingAmountsEditChipListWithSearchBar } from "../../ServingAmounts/Edit/ChipListWithSearchBar";
import { useServingAmountModeContext } from "../../ServingAmounts/contexts/ServingAmountMode";
import WarningIcon from "../../universal/WarningIcon";
import { MenuItemChangeScoreAction, MenuItemChangeServingAmountsAction } from "../reducer";
import { ScoreOverrideDialog } from "./ScoreOverrideDialog";
import { NewChip } from "../../universal/NewChip";

interface IngredientsProps {
  itemName: string;
  servingAmounts: readonly FullServingAmountFragment[];
  onChangeServingAmounts: (args: MenuItemChangeServingAmountsAction["payload"]) => void;
  onChangeScore: (args: MenuItemChangeScoreAction["payload"]) => void;
  onAiGenerateClicked?: null | (() => void);
  onAiTranscribeClicked?: null | (() => void);
  hasIngredients?: boolean;
}

export const Ingredients = ({
  itemName,
  servingAmounts,
  onChangeServingAmounts,
  onChangeScore,
  onAiGenerateClicked,
  onAiTranscribeClicked,
  hasIngredients,
}: IngredientsProps) => {
  const [saveAsRecipeModalOpen, setSaveAsRecipeModalOpen] = useState(false);
  const [compassDialogOpen, setCompassDialogOpen] = useState(false);
  const [scoreOverrideDialogOpen, setScoreOverrideDialogOpen] = useState(false);

  const servingAmountMode = useServingAmountModeContext();
  const forRestaurant = servingAmountMode.type === "restaurant";
  const showSaveAsRecipe = servingAmounts.length > 1 && !forRestaurant;

  const hasCompassIntegration = useHasIntegration("compass");
  const showCompassImport = hasCompassIntegration && !forRestaurant;

  const [scoredIngredientRecipe, setScoredIngredientRecipe] = useState<RecipeLimitedAccessFragment | null>(null);

  const { data: scoringSystemData, loading: loadingScoringSystem } = useMyScoringSystemQuery();
  const orgScoringSystem = scoringSystemData?.myScoringSystem;

  const handleSaveAsRecipe = (recipe: RecipeFullFragment) => {
    const serving = recipe.servings[0];
    const newServingAmounts = [
      newServingAmount(
        {
          ...serving,
          foodOrRecipe: recipe,
        },
        1
      ),
    ];
    onChangeServingAmounts({ servingAmounts: newServingAmounts });
  };
  const handleRecipeImport = (recipe: RecipeSearchFragment) => {
    if (recipe.servings.length === 0) {
      return;
    }
    const serving = recipe.servings.find(s => s.isDefault) || recipe.servings[0];
    const servingAmount = newServingAmount({ ...serving, foodOrRecipe: recipe }, getNextPosition(servingAmounts));
    onChangeServingAmounts({ servingAmounts: [...servingAmounts, servingAmount] });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ display: "inline" }}>
        <IngredientsHeader servingAmounts={servingAmounts} showWarning={true} />
      </Grid>
      <Grid item xs={12}>
        <ServingAmountsEditChipListWithSearchBar
          enableBranded
          includeRecipeIngredients
          onChange={e => {
            const scoredRecipe = getScoredRecipe(e);
            if (scoredRecipe !== null) {
              setScoredIngredientRecipe(scoredRecipe);
              setScoreOverrideDialogOpen(true);
            } else {
              setScoredIngredientRecipe(null);
            }

            onChangeServingAmounts({ servingAmounts: e });
          }}
          selected
          selectedServingAmounts={servingAmounts}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {showSaveAsRecipe && (
            <Button
              size="small"
              variant="text"
              sx={{ justifyContent: "flex-end", pt: 1 }}
              onClick={() => setSaveAsRecipeModalOpen(true)}>
              Save as Recipe
            </Button>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
          {showCompassImport && (
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setCompassDialogOpen(true)}>
              Import Compass Recipe
            </Button>
          )}

          {onAiGenerateClicked && !hasIngredients && (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => onAiGenerateClicked()}>
              <AutoAwesomeIcon sx={{ fontSize: 16, marginRight: 1 }} />
              AI Recipe Generator
            </Button>
          )}
          {onAiTranscribeClicked && !hasIngredients && (
            /*TODO - remove NewChip once feature has been released for time*/
            <Button
              variant="outlined"
              fullWidth
              onClick={() => onAiTranscribeClicked()}>
              <AutoStoriesIcon sx={{ fontSize: 16, marginRight: 1 }} />
              AI Image/URL Transcriber
              <NewChip />
            </Button>
          )}
        </Box>
      </Grid>
      {saveAsRecipeModalOpen && (
        <RecipeCreateDialog
          onCreate={handleSaveAsRecipe}
          open={saveAsRecipeModalOpen}
          onClose={() => setSaveAsRecipeModalOpen(false)}
          initialIngredients={servingAmounts}
          initialName={itemName}
        />
      )}
      {compassDialogOpen && (
        <CompassImportRecipeDialog
          open={compassDialogOpen}
          onClose={() => setCompassDialogOpen(false)}
          onImport={handleRecipeImport} />
      )}
      {!loadingScoringSystem && (
        <ScoreOverrideDialog
          open={scoreOverrideDialogOpen}
          onConfirm={() => {
            onChangeScore({ scoreValue: scoredIngredientRecipe?.score?.value || null, scoringSystem: orgScoringSystem || null });
            setScoreOverrideDialogOpen(false);
          }}
          onClose={() => {
            setScoreOverrideDialogOpen(false);
          }}
        />
      )}
    </Grid>
  );
};

const getScoredRecipe = (servingAmounts: readonly FullServingAmountFragment[]) => {
  const recipes: RecipeLimitedAccessFragment[] = servingAmounts
    .map(s => s.serving.foodOrRecipe)
    .filter((f): f is RecipeLimitedAccessFragment => f.__typename === "Recipe");
  const scoredRecipes = recipes.filter(r => r.score !== null);
  return scoredRecipes.length > 0 ? scoredRecipes[0] : null;
};

interface ReadonlyIngredientsProps {
  servingAmounts: readonly FullServingAmountFragment[];
}

export const ReadonlyIngredients = ({ servingAmounts }: ReadonlyIngredientsProps) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ display: "inline" }}>
        <IngredientsHeader servingAmounts={servingAmounts} />
      </Grid>
      <Grid item xs={12}>
        <ServingAmountViewChipList servingAmounts={servingAmounts} />
      </Grid>
    </Grid>
  );
};

interface IngredientsHeaderProps {
  servingAmounts: readonly FullServingAmountFragment[];
  showWarning?: boolean;
}

const IngredientsHeader = ({ servingAmounts, showWarning }: IngredientsHeaderProps) => {
  return (
    <Box sx={{ mb: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography variant="h3" color="textSecondary">
        Ingredients
      </Typography>
      <Box sx={{ minWidth: 5 }} />
      <Typography color="textSecondary">(per portion)</Typography>
      {showWarning && servingAmountsUseOldRecipe(servingAmounts) && (
        <WarningIcon tip="Old versions of recipes are being used" sx={{ pl: 1 }} />
      )}
    </Box>
  );
};
