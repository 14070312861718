import ClearIcon from "@mui/icons-material/Clear";
import GetAppIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TableCell, TableRow, TableSortLabel, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { addSelectionOptionsToMenuItem } from "@notemeal/shared/ui/MenuItem/utils";
import { sortByKey } from "@notemeal/utils/sort";
import HelpTipIcon from "apps/web/src/components/universal/HelpTipIcon";
import TablePage from "apps/web/src/components/universal/TablePage";
import { OrderPageMenuItemFragment, OrderPageMenuOrderItemFragment, OrderPageMenuOrderItemOptionFragment } from "apps/web/src/types";
import { getRowsForTablePage, useOffsetPagination } from "apps/web/src/utils/pagination";
import React, { useState } from "react";
import { exportToExcel, getAthleteDisplayNameForOrder } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 6),
      boxSizing: "border-box",
      height: "calc(100vh - 300px)",
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto 1fr auto",
      width: "100%",
    },
    spacer: {
      flexGrow: 1,
    },
    headerRowCell: {
      fontWeight: "bold",
    },
    tinyFont: {
      fontSize: 10,
    },
    optionName: {
      fontSize: 10,
      fontWeight: 600,
    },
  })
);

export interface OrderRow {
  orderNumber: string;
  menuItemName: string;
  pickupTime: string;
  stationName: string;
  orderedBy: string;
  specialRequests: string;
  menuItem: OrderPageMenuItemFragment;
  options: readonly OrderPageMenuOrderItemOptionFragment[];
}

export interface MenuOrdersTableProps {
  tableRows: OrderPageMenuOrderItemFragment[];
  itemIdsToDiningStationName: { [menuOrderItemId: string]: string };
}

const MenuOrdersTable = ({ tableRows, itemIdsToDiningStationName }: MenuOrdersTableProps) => {
  const classes = useStyles();
  const paginationHooks = useOffsetPagination();
  const { page, limit, queryText, onChangeQueryText } = paginationHooks;
  const [sortOrderAscending, setSortOrderAscending] = useState(true);
  const [sortColumnName, setSortColumnName] = useState<keyof OrderRow>("orderNumber");
  const orderRows: OrderRow[] = tableRows.map(tableRow => {
    const {
      id,
      order: { code: orderNumber, pickupTime: orderPickupTime, athlete, userFullName },
      menuItem,
      specialRequests: maybeSpecialRequests,
      options,
    } = tableRow;
    const { name: menuItemName } = menuItem;
    const pickupTime = new Date(orderPickupTime).toLocaleTimeString();
    const stationName = itemIdsToDiningStationName[id];
    const orderedBy = athlete ? getAthleteDisplayNameForOrder(athlete) : userFullName;
    const specialRequests = maybeSpecialRequests || "";
    return {
      orderNumber,
      menuItemName,
      pickupTime,
      stationName,
      orderedBy,
      specialRequests,
      menuItem,
      options,
    };
  });
  const getRows = () => sortByKey<OrderRow>(orderRows, sortColumnName, { reverse: !sortOrderAscending }).filter(filterRow) || [];
  const filterRow = (row: OrderRow) => {
    const { orderNumber, menuItemName, pickupTime, stationName, orderedBy, specialRequests } = row;
    const rowText = `${orderNumber} ${menuItemName} ${pickupTime} ${stationName} ${orderedBy} ${specialRequests}`;
    return rowText.toLowerCase().indexOf(queryText.toLowerCase()) !== -1;
  };
  const getSortOrder = () => (sortOrderAscending ? "asc" : "desc");
  const toggleSortOrder = (fieldName: keyof OrderRow) => {
    setSortColumnName(fieldName);
    setSortOrderAscending(!sortOrderAscending);
  };
  const rows = getRows();
  const pageRows = getRowsForTablePage({ rows, limit, page });

  const exportData = () => {
    exportToExcel({
      exportFilename: "orders.xlsx",
      itemIdsToDiningStationName,
      // Respect filter/search/sort
      orderItems: tableRows.flatMap((d: OrderPageMenuOrderItemFragment) => tableRows.find(tr => tr.id === d.id) || []),
    });
  };

  const header = (
    <>
      <Typography variant="h3">
        Orders
        <HelpTipIcon tip="This table shows all current orders off of the selected menu" />
      </Typography>
      <div className={classes.spacer} />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Clear">
                <IconButton onClick={() => onChangeQueryText("")} size="large">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        placeholder="Search"
        value={queryText}
        onChange={e => onChangeQueryText(e.target.value)}
      />
      <Tooltip title="Export to Excel">
        <IconButton onClick={exportData} size="large">
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const getTableHeaderRowCell = (name: string, fieldName: keyof OrderRow | null) => (
    <>
      <TableCell>
        {fieldName && (
          <TableSortLabel direction={getSortOrder()} onClick={() => toggleSortOrder(fieldName)}>
            <Typography className={classes.headerRowCell} variant="body2">
              {name}
            </Typography>
          </TableSortLabel>
        )}
        {!fieldName && (
          <Typography className={classes.headerRowCell} variant="body2">
            {name}
          </Typography>
        )}
      </TableCell>
    </>
  );

  type HeaderColumn = [string, keyof OrderRow | null];
  const headerColumns: Array<HeaderColumn> = [
    ["Order #", "orderNumber"],
    ["Item", "menuItemName"],
    ["Pickup Time", "pickupTime"],
    ["Station Name", "stationName"],
    ["Ordered By", "orderedBy"],
    ["Options", null],
    ["Special Requests", "specialRequests"],
  ];

  const getTableHeaderRow = () => (
    <TableRow>{headerColumns.map(([name, fieldName]: [string, keyof OrderRow | null]) => getTableHeaderRowCell(name, fieldName))}</TableRow>
  );

  const getOptionsCell = (menuItem: OrderPageMenuItemFragment, options: readonly OrderPageMenuOrderItemOptionFragment[]) => {
    const { choices } = addSelectionOptionsToMenuItem(menuItem, options);
    const choiceComponent = choices.map(
      c =>
        c.options.length > 0 && (
          <Typography className={classes.tinyFont}>
            <span className={classes.optionName}>{c.name}: </span>
            {c.options.flatMap(o => (o.menuSelectionItemOption ? [`${o.name} (${o.menuSelectionItemOption.amount}x)`] : [])).join(", ")}
            <br />
          </Typography>
        )
    );
    return choiceComponent;
  };

  const getTableBodyRows = () =>
    pageRows.flatMap(pageRow => {
      const { orderNumber, menuItemName, pickupTime, stationName, orderedBy, specialRequests, menuItem, options } = pageRow;
      return (
        <TableRow key={`${orderNumber}-${menuItemName}`}>
          <TableCell>{orderNumber}</TableCell>
          <TableCell>{menuItemName}</TableCell>
          <TableCell>{pickupTime}</TableCell>
          <TableCell>{stationName}</TableCell>
          <TableCell>{orderedBy}</TableCell>
          <TableCell>{getOptionsCell(menuItem, options)}</TableCell>
          <TableCell>{specialRequests}</TableCell>
        </TableRow>
      );
    });

  return (
    <TablePage
      header={header}
      tableHeaderRow={getTableHeaderRow()}
      tableBodyRows={getTableBodyRows()}
      loading={false}
      paginationHooks={paginationHooks}
      total={tableRows.length}
    />
  );
};

export default MenuOrdersTable;
