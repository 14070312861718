import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenuRounded";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { BrandsPage } from "./BrandsPage";
import { FoodGroupsPage } from "./FoodGroupsPage";
import { FoodsPage } from "./FoodsPage";
import { RecipesPage } from "./RecipesPage";

export const NAV_STAFF_FOOD_MANAGEMENT = "/staff/food-management";

export const staffFoodManagementLink = {
  to: NAV_STAFF_FOOD_MANAGEMENT,
  name: "Food Management",
  icon: <RestaurantMenuIcon />,
};

const secondaryNavLayoutProps: SecondaryNavLayoutProps = {
  current: staffFoodManagementLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Recipes",
      to: "recipes",
    },
    {
      name: "Foods",
      to: "foods",
    },
    {
      name: "Food Groups",
      to: "food-groups",
    },
    {
      name: "Brands",
      to: "brands",
    },
  ],
};

export const FoodManagementRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route index element={<RecipesPage />} />
      <Route path="recipes" element={<RecipesPage />} />
      <Route path="foods" element={<FoodsPage />} />
      <Route path="food-groups" element={<FoodGroupsPage />} />
      <Route path="brands" element={<BrandsPage />} />
    </Route>
  </Routes>
);
