import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenuRounded";
import React from "react";

// full page path
export const NAV_ORG_FOOD_MANAGEMENT = "/org/food-management";

// local child paths
export const NAV_FOOD_MANAGEMENT_RECIPES = "recipes";
export const NAV_FOOD_MANAGEMENT_FOODS = "foods";
export const NAV_FOOD_MANAGEMENT_FOOD_GROUPS = "food-groups";

// full child paths
export const NAV_ORG_FOOD_MANAGEMENT_RECIPES = `${NAV_ORG_FOOD_MANAGEMENT}/${NAV_FOOD_MANAGEMENT_RECIPES}`;

export const orgFoodManagementLink = {
  to: NAV_ORG_FOOD_MANAGEMENT,
  name: "Food Management",
  icon: <RestaurantMenuIcon />,
};
