import { useHasFeature } from "@notemeal/shared/ui/Feature";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import { StreamChatProvider } from "../../contexts/StreamChatContext";
import { useMeForStreamChatQuery } from "../../types";
import { OrgRouter } from "./Org/OrgRouter";
import { OrgAndStaffRouterProps } from "./PrimaryNavUtils";

export const OrgApp = ({ orgs, onOrgChange }: OrgAndStaffRouterProps) => {
  const hasMessaging = useHasFeature("messaging");
  const { data } = useMeForStreamChatQuery({ skip: !hasMessaging });

  if (hasMessaging) {
    if (!data) {
      return <Loading />;
    }

    return (
      <StreamChatProvider meForStreamChatResult={data}>
        <OrgRouter orgs={orgs} onOrgChange={onOrgChange} />
      </StreamChatProvider>
    );
  }

  return <OrgRouter orgs={orgs} onOrgChange={onOrgChange} />;
};
