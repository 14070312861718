import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { getServingAmountMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import { initMacros, scaleMacros, sumMacros } from "@notemeal/shared/utils/macro-protocol";
import React, { Dispatch } from "react";
import { ExchangeType } from "../../../types";
import ExchangeTypeMultiSelect from "../../Exchange/TypeMultiSelect";
import ServingUnitsInput from "../../Serving/Units/Input";
import { ServingUnitsState, getServingUnits } from "../../Serving/utils";
import { RecipeFormAction, RecipeFormState } from "./utils";

interface RecipeFormServingProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  disabled?: boolean;
}

const RecipeFormServing = ({ state, dispatch, disabled }: RecipeFormServingProps) => {
  const { cho: totalCho, pro: totalPro, fat: totalFat, kcal: totalKcal } = sumMacros(state.ingredients.map(getServingAmountMacros));

  const handleServingChangeUnits = (units: ServingUnitsState) => {
    dispatch({ type: "CHANGE_SERVINGS", value: { ...state.serving, units } });
  };

  const handleServingChangePerRecipeYield = (perRecipeYieldInput: string) => {
    dispatch({
      type: "CHANGE_SERVINGS",
      value: {
        ...state.serving,
        perRecipeYieldInput,
        perRecipeYield: inputToNumber(perRecipeYieldInput) || state.serving.perRecipeYield,
      },
    });
  };

  const handleServingChangeDefaultAmount = (defaultAmountInput: string) => {
    dispatch({
      type: "CHANGE_SERVINGS",
      value: {
        ...state.serving,
        defaultAmountInput,
        defaultAmount: inputToNumber(defaultAmountInput) || state.serving.defaultAmount,
      },
    });
  };

  const handleToggleManualMacros = () => {
    dispatch({
      type: "CHANGE_MANUAL_MACROS",
      value: !state.manualMacros,
    });
  };

  const handleChangeExchangeTypes = (exchangeTypes: ExchangeType[]) => {
    dispatch({
      type: "CHANGE_EXCHANGE_TYPES",
      value: exchangeTypes,
    });
  };

  const macros = state.manualMacros
    ? // Will eventually add kcal to this form. Until then we'll get kcal from macros.
      initMacros(inputToNumber(state.choInput) || 0, inputToNumber(state.proInput) || 0, inputToNumber(state.fatInput) || 0)
    : scaleMacros(
        {
          cho: totalCho,
          pro: totalPro,
          fat: totalFat,
          kcal: totalKcal,
        },
        state.serving.defaultAmount / state.serving.perRecipeYield
      );

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" color="textSecondary">
          Serving Size
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              sx={{ py: 0, alignSelf: "center" }}
              size="small"
              checked={state.manualMacros}
              onChange={handleToggleManualMacros}
            />
          }
          label="Manually set macros?"
          labelPlacement="end"
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          disabled={disabled}
          label="Yields"
          fullWidth
          onChange={e => handleServingChangePerRecipeYield(e.target.value)}
          value={state.serving.perRecipeYieldInput}
          error={inputToNumber(state.serving.perRecipeYieldInput) === null}
        />
      </Grid>

      <Grid item xs={8}>
        <ServingUnitsInput
          disabled={disabled}
          value={state.serving.units}
          fullWidth
          onChange={handleServingChangeUnits} />
      </Grid>

      <Grid item xs={4}>
        <TextField
          disabled={disabled}
          label="Default Amount"
          fullWidth
          onChange={e => handleServingChangeDefaultAmount(e.target.value)}
          value={state.serving.defaultAmountInput}
          error={inputToNumber(state.serving.defaultAmountInput) === null}
        />
      </Grid>
      <Grid item xs={8}>
        <ExchangeTypeMultiSelect
          disabled={disabled}
          label={state.exchangeTypes === null ? "Exchange Types are auto-calculated" : "Choose Exchange Types"}
          margin="none"
          handleChangeExchangeTypes={handleChangeExchangeTypes}
          selectedExchangeTypes={state.exchangeTypes}
          fullWidth
        />
      </Grid>

      <Grid item xs={4}>
        <Typography variant="subtitle1">
          Macros per "{state.serving.defaultAmount} {getServingUnits(state.serving.units)}":
        </Typography>
      </Grid>

      <Grid
        item
        xs={8}
        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <Box sx={{ display: "flex", ml: 0.5, alignItems: "center" }}>
          {state.manualMacros ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="subtitle1">{macros.kcal}kcal </Typography>
              <MacrosInput
                disabled={disabled}
                value={state.choInput}
                placeholder="CHO"
                onChange={e =>
                  dispatch({
                    type: "CHANGE_CHO_INPUT",
                    value: e.target.value,
                  })
                }
              />
              <MacrosInput
                disabled={disabled}
                value={state.proInput}
                placeholder="PRO"
                onChange={e =>
                  dispatch({
                    type: "CHANGE_PRO_INPUT",
                    value: e.target.value,
                  })
                }
              />
              <MacrosInput
                disabled={disabled}
                value={state.fatInput}
                placeholder="FAT"
                onChange={e =>
                  dispatch({
                    type: "CHANGE_FAT_INPUT",
                    value: e.target.value,
                  })
                }
              />
            </Box>
          ) : (
            <MacrosSummaryLabel
              macros={macros}
              kcalPrefix
              variant="sm" />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

interface MacrosInputProps {
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const MacrosInput = ({ value, placeholder, onChange, disabled }: MacrosInputProps) => {
  return (
    <TextField
      disabled={disabled}
      sx={{ width: 70, m: 0 }}
      value={value}
      error={inputToNumber(value) === null}
      placeholder={placeholder}
      onChange={onChange}
      margin="dense"
      inputProps={{
        style: { padding: "4px 10px", paddingLeft: 4 },
      }}
      InputProps={{
        sx: { fontSize: 12, pr: 1, pl: 0 },
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant="subtitle1">g</Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RecipeFormServing;
