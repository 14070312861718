import { ApolloError } from "@apollo/client";
import BarChartIcon from "@mui/icons-material/BarChart";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SmsIcon from "@mui/icons-material/Sms";
import { Box, Button, FormControlLabel, ListItemIcon, ListItemText, Menu, MenuItem, Switch, useTheme } from "@mui/material";
import { getExchangeAmountMacros } from "@notemeal/shared/ui/ExchangeAmount/utils";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { maybeMacrosToMacros, sumMacros } from "@notemeal/shared/utils/macro-protocol";
import { TWIconButton } from "apps/web/src/componentLibrary/TWIconButton/TWIconButton";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import GenerateMealOptionsDialog, { GeneratedMealOptions } from "apps/web/src/components/MealPlan/GenerateMealOptions/ConfigurationDialog";
import { MealOptionInput } from "apps/web/src/components/MealPlan/GenerateMealOptions/useGenerateMealOptions";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import {
  EditFullMealPlanFragment,
  ExchangeMealPlanDisplaySettingsFragment,
  MacroMealPlanDisplaySettingsFragment,
  Macros,
  useCalculateExchangeTotalsMutation,
  useDeselectNegativeFoodsMutation,
  useDistributeExchangeTargetsMutation,
  useDistributeMacroTargetsMutation,
  useEditMealPlanIsSharedMutation,
  useSetMealOptionsForMealTemplatesMutation,
} from "apps/web/src/types";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import MealPlanMobileExportModal from "../../../../components/MealPlan/Export/Modal/SingleMobile";
import MealPlanPdfExportModal from "../../../../components/MealPlan/Export/Modal/SinglePdf";
import { MealPlanTab } from "./utils";

interface MealPlanHeaderProps {
  mealPlan: EditFullMealPlanFragment;
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
  tab: MealPlanTab;
  onChangeTab: (value: MealPlanTab) => void;
  onEditCalendar: () => void;
}

export const MealPlanHeader = ({
  mealPlan,
  macroDisplaySettings,
  exchangeDisplaySettings,
  tab,
  onChangeTab,
  onEditCalendar,
}: MealPlanHeaderProps) => {
  const {
    palette: {
      accents: { indigo, orangeDark },
    },
  } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [generateMealOptionsModalOpen, setGenerateMealOptionsModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [mobileSettingsModalOpen, setMobileSettingsModalOpen] = useState(false);
  const [savingIsShared, setSavingIsShared] = useState(false);
  const { setMessage } = useSnackbar();
  const { locale } = useLocaleContext();
  const isEULocale = locale === "en-GB";

  const localIsShared = useRef(mealPlan.isShared);

  const handleChangeSharedSettings = (e: ChangeEvent<HTMLInputElement>) => {
    const isShared = e.target.checked;
    localIsShared.current = isShared;
    if (savingIsShared) {
      return;
    }
    editMealPlanIsShared({
      variables: {
        input: {
          mealPlanId: mealPlan.id,
          isShared,
        },
      },
    });
    setSavingIsShared(true);
  };

  const [editMealPlanIsShared] = useEditMealPlanIsSharedMutation({
    update: (cache, { data }) => {
      if (data) {
        const isSharedChanged = data.editMealPlanIsShared.mealPlan.isShared !== localIsShared.current;
        if (isSharedChanged) {
          editMealPlanIsShared({
            variables: {
              input: {
                mealPlanId: mealPlan.id,
                isShared: localIsShared.current,
              },
            },
          });
        } else {
          setSavingIsShared(false);
          if (data.editMealPlanIsShared.mealPlan.isShared) {
            setMessage("success", "Success! Shared meal plan with athlete.");
          } else {
            setMessage("warning", "Athlete will no longer have access to view meal plan.");
          }
        }
      }
    },
  });

  const mutationOptions = { onError: (e: ApolloError) => setMessage("error", e.message) };
  const [distributeMacroTargets] = useDistributeMacroTargetsMutation(mutationOptions);
  const [distributeExchangeTarget] = useDistributeExchangeTargetsMutation(mutationOptions);
  const [calculateExchangeTotals] = useCalculateExchangeTotalsMutation(mutationOptions);
  const [deselectNegativeFoods] = useDeselectNegativeFoodsMutation(mutationOptions);

  const handleDistribute = () => {
    if (mealPlan.type === "macro") {
      distributeMacroTargets({ variables: { mealPlanId: mealPlan.id } });
    } else {
      distributeExchangeTarget({ variables: { mealPlanId: mealPlan.id } });
    }
  };

  const handleDetermineTotalExchanges = useCallback(() => {
    calculateExchangeTotals({ variables: { mealPlanId: mealPlan.id } });
  }, [mealPlan.id, calculateExchangeTotals]);

  const handleDeselectNegativeFoods = (): void => {
    deselectNegativeFoods({ variables: { mealPlanId: mealPlan.id } });
  };

  const [setMealOptionsForMealTemplates] = useSetMealOptionsForMealTemplatesMutation();

  const mealOptionInputs: MealOptionInput[] = mealPlan.mealTemplates.map(mealTemplate => {
    const {
      __typename,
      id: mealTemplateId,
      meal: { type: mealType },
    } = mealTemplate;
    let macros: Macros;
    if (__typename === "MacroMealTemplate") {
      macros = maybeMacrosToMacros(mealTemplate);
    } else {
      macros = sumMacros(mealTemplate.exchangeTargets.map(getExchangeAmountMacros));
    }

    return { mealTemplateId, mealType, macros };
  });

  const handleGenerateMealOptions = () => {
    const hasUnsetMacros = mealOptionInputs.some(
      ({ macros: { cho, pro, fat } }) => cho === null || pro === null || fat === null || (!cho && !pro && !fat)
    );
    if (hasUnsetMacros) {
      const variables = { mealPlanId: mealPlan.id };
      if (mealPlan.type === "macro") {
        distributeMacroTargets({ variables });
      } else {
        distributeExchangeTarget({ variables });
      }
    } else {
      setGenerateMealOptionsModalOpen(true);
    }
  };

  const onGenerateMealOptionsClose = () => setGenerateMealOptionsModalOpen(false);
  const onGenerateMealOptionsComplete = (generatedMealOptions: GeneratedMealOptions) => {
    const { removeExistingMealOptions, mealOptionsForMealTemplates } = generatedMealOptions;
    if (mealOptionsForMealTemplates.length === 0) {
      setMessage("error", "Meal options fitting this meal plan were not found.");
    } else {
      const mealTemplateMap = new Map(mealPlan.mealTemplates.map(mealTemplate => [mealTemplate.id, mealTemplate]));
      const updatedMealOptionsForMealTemplates = mealOptionsForMealTemplates.map(mealOptionsForMealTemplate => {
        const { mealTemplateId, mealOptions } = mealOptionsForMealTemplate;
        const existingMealOptionsCount = mealTemplateMap.get(mealTemplateId)?.mealOptions.length || 0;
        const mealOptionsWithUpdatedPositions = mealOptions.map(mealOption => ({
          ...mealOption,
          position: mealOption.position + existingMealOptionsCount,
        }));
        return { ...mealOptionsForMealTemplate, mealOptions: mealOptionsWithUpdatedPositions };
      });
      setMealOptionsForMealTemplates({
        variables: {
          input: {
            removeExistingMealOptions,
            mealOptionsForMealTemplates: updatedMealOptionsForMealTemplates,
          },
        },
      });
    }
    onGenerateMealOptionsClose();
  };

  const TARGETS = `${mealPlan.type === "exchange" ? "Exchange" : "Macro"} Targets`;
  const OPTIONS = "Meal Options";
  const CALENDAR = "Calendar";
  const tabs = [TARGETS, OPTIONS, CALENDAR];

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <TWTabGroup
        tabs={tabs}
        onSelected={selected => onChangeTab(selected === TARGETS ? "targets" : selected === OPTIONS ? "options" : "calendar")}
        outsideSelected={tab === "targets" ? TARGETS : tab === "options" ? OPTIONS : CALENDAR}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <FormControlLabel
          sx={{ mr: 0.5 }}
          control={<Switch
            sx={{ mr: 1 }}
            checked={mealPlan.isShared}
            onChange={handleChangeSharedSettings} />}
          label="Share with athlete"
        />
        {tab === "targets" && <Button onClick={handleDistribute}>Distribute {mealPlan.type === "macro" ? "Macros" : "Exchanges"}</Button>}
        {tab === "options" && !isEULocale && <Button onClick={handleGenerateMealOptions}>Generate Meal Options</Button>}
        <TWIconButton
          icon={<MoreVertIcon />}
          variant="outlined"
          onClick={e => setAnchorEl(e.currentTarget)} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}>
        {tab === "targets" && mealPlan.type === "exchange" && (
          <Box>
            <MenuItem
              onClick={() => {
                handleDetermineTotalExchanges();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <BarChartIcon sx={{ color: indigo[600] }} />
              </ListItemIcon>
              <ListItemText>Calculate Exchange Targets</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeselectNegativeFoods();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <IndeterminateCheckBoxIcon sx={{ color: orangeDark[600] }} />
              </ListItemIcon>
              <ListItemText>Deselect Disliked/Avoided Foods</ListItemText>
            </MenuItem>
          </Box>
        )}
        {mealPlan.isShared && (
          <MenuItem
            onClick={() => {
              setMobileSettingsModalOpen(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SmsIcon />
            </ListItemIcon>
            <ListItemText>Send Meal Plan via Text Message</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setExportModalOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText>Export Meal Plan</ListItemText>
        </MenuItem>
      </Menu>
      {generateMealOptionsModalOpen && (
        <GenerateMealOptionsDialog
          open={generateMealOptionsModalOpen}
          onClose={onGenerateMealOptionsClose}
          mealOptionInputs={mealOptionInputs}
          onCompleted={onGenerateMealOptionsComplete}
        />
      )}
      {exportModalOpen && <MealPlanPdfExportModal
        mealPlan={mealPlan}
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)} />}
      {mobileSettingsModalOpen && (
        <MealPlanMobileExportModal
          mealPlan={mealPlan}
          macroDisplaySettings={macroDisplaySettings}
          exchangeDisplaySettings={exchangeDisplaySettings}
          open={mobileSettingsModalOpen}
          onClose={() => setMobileSettingsModalOpen(false)}
        />
      )}
    </Box>
  );
};
