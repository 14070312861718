import {
  AthleteForLinking,
  LinkedProfile,
  OrgForLinking,
  OrgMembershipForLinking,
  ProfileSyncRuleWithEntities,
  TeamLinkPreview,
  TeamworksGroup,
  TeamworksPositionLink,
  TeamworksProfile,
  TeamworksProfilesState,
  TeamworksProfileSyncRuleState,
  TeamworksTeam,
} from "./types";
// These imports may be different in the two versions of this file
import { sortByFn, sortByKey } from "@notemeal/utils/sort";
import { EditOrgTeamworksProfilesInput, SexType, TeamworksProfileLinkInput, TeamworksProfileSyncRuleInput } from "../types";

// Interface used so that 'assignProfilesToSyncRules' can push to otherwise readonly arrays while processing
// partitionProfiles still returns 'ProfileSyncRuleWithEntities' though, which has readonly arrays
interface ProfileSyncRuleInProgress extends Omit<ProfileSyncRuleWithEntities, "linkedProfiles" | "unlinkedProfiles"> {
  linkedProfiles: Array<ProfileSyncRuleWithEntities["linkedProfiles"][0]>;
  unlinkedProfiles: Array<ProfileSyncRuleWithEntities["unlinkedProfiles"][0]>;
}

interface assignProfilesToSyncRulesPayload {
  syncRules: readonly ProfileSyncRuleWithEntities[];
  toDeactivateLinkedProfiles: readonly LinkedProfile[];
  toDeactivateOrgMemberships: readonly OrgMembershipForLinking[];
  inactiveProfiles: readonly LinkedProfile[];
}

/**
 * Gets derived data for UI by assigning linked teamworks + notemeal (optional) profiles and unlinked teamworks profiles to matching profile sync rules
 *
 * Each profile can be assigned to at most one rule. A rule's priority is used to break ties, with matching on profiles direclty
 * taking precedent over matching on team/user type/athele status
 *
 * Linked, non-pending profiles that do not match a rule will also be returned via toDeactivateProfiles or inactiveProfiles
 * Depending on if the corresponding notmeal org membership is active or not
 *
 * @param state source of data for rules, linked profiles, and unlinked profiles
 * @param linkedTeamworksTeamIds teamworks team ids that have been assigned to a notemeal team. This is used in the rule matching process
 */
export const assignProfilesToSyncRules = (
  { syncRules, linkedProfiles, unlinkedTeamworksProfiles, linkNotFoundOrgMemberships }: TeamworksProfilesState,
  allLinkedTeamworkTeams: readonly TeamworksTeam[]
): assignProfilesToSyncRulesPayload => {
  const toDeactivateLinkedProfiles: LinkedProfile[] = [];
  const toDeactivateOrgMemberships: OrgMembershipForLinking[] = [];
  const inactiveProfiles: LinkedProfile[] = [];
  const linkedTeamworksTeamIds = allLinkedTeamworkTeams.map(t => t.id);

  const sortedSyncRules: ProfileSyncRuleInProgress[] = sortByKey(syncRules, "priority").map(rule => {
    return {
      ...rule,
      unlinkedProfiles: [],
      linkedProfiles: [],
    };
  });
  const validSyncRules = sortedSyncRules.filter(rule => getIsValidSyncRule(rule, allLinkedTeamworkTeams).isValidSyncRule);
  linkedProfiles.forEach(linkedProfile => {
    const matchingSyncRule = getMatchingSyncRuleForProfile(validSyncRules, linkedProfile.teamworks, linkedTeamworksTeamIds);
    if (matchingSyncRule) {
      matchingSyncRule.matchingRule.linkedProfiles.push(linkedProfile);
    } else if (linkedProfile.notemeal && !linkedProfile.isPending) {
      if (isNotemealProfileActive(linkedProfile.notemeal)) {
        toDeactivateLinkedProfiles.push(linkedProfile);
      } else {
        inactiveProfiles.push(linkedProfile);
      }
    }
  });

  unlinkedTeamworksProfiles.forEach(profile => {
    const matchingSyncRule = getMatchingSyncRuleForProfile(validSyncRules, profile, linkedTeamworksTeamIds);
    if (matchingSyncRule) {
      matchingSyncRule.matchingRule.unlinkedProfiles.push(profile);
    }
  });

  linkNotFoundOrgMemberships.filter(isNotemealProfileActive).forEach(profile => {
    toDeactivateOrgMemberships.push(profile);
  });

  return {
    syncRules: sortedSyncRules,
    toDeactivateLinkedProfiles,
    toDeactivateOrgMemberships,
    inactiveProfiles,
  };
};

const isNotemealProfileActive = (profile: AthleteForLinking | OrgMembershipForLinking): boolean => {
  if (profile.__typename === "Athlete") {
    return !profile.isArchived;
  } else {
    return profile.isActive || !(profile.athlete ? profile.athlete.isArchived : true);
  }
};

interface getMatchingSyncRuleForProfilePayload {
  matchingRule: ProfileSyncRuleInProgress;
}

const getMatchingSyncRuleForProfile = (
  sortedSyncRules: ProfileSyncRuleInProgress[],
  profile: TeamworksProfile,
  linkedTeamworksTeamIds: readonly number[]
): getMatchingSyncRuleForProfilePayload | undefined => {
  // Matching on profileIds takes priority over teamIds/userTypeIds/athleteStatusIds
  // So loop through rules checking for that first
  let matchingRule: ProfileSyncRuleInProgress | undefined = undefined;
  for (const rule of sortedSyncRules) {
    if (rule.matchOnProfiles) {
      const matchesProfile = rule.profiles.map(p => p.id).includes(profile.id);
      const hasMembership = profileHasMembershipForRule(rule, profile, linkedTeamworksTeamIds);
      if (matchesProfile && hasMembership) {
        matchingRule = rule;
        break;
      }
    }
  }
  if (matchingRule) {
    return { matchingRule };
  }

  // Check for matches on teamIds/userTypeIds/athleteStatusIds
  for (const rule of sortedSyncRules) {
    if (!rule.matchOnProfiles) {
      const hasMembership = profileHasMembershipForRule(rule, profile, linkedTeamworksTeamIds);

      const ruleTeamIds = rule.teams.map(t => t.id);
      const ruleUserTypeIds = rule.userTypes.map(t => t.id);
      const ruleAthleteStatusIds = rule.athleteStatuses.map(t => t.id);
      const rulePositionIds = rule.positions.map(t => t.id);
      const ruleGenders = rule.genders;

      // If no gender reported return true TODO: is this expected
      const matchesGender = ruleGenders.length > 0 && profile.gender ? ruleGenders.includes(profile.gender) : true;

      const matchingMembership = profile.memberships?.find(m => {
        const membershipUserTypeIds = m.userTypes?.map(u => u.userTypeId) ?? [];
        const membershipPositionIds = m.positions?.map(({ id }) => id) ?? [];

        const matchesTeams = ruleTeamIds.length > 0 ? ruleTeamIds.includes(m.teamId) : true;
        const matchesUserTypes = ruleUserTypeIds.length > 0 ? ruleUserTypeIds.some(id => membershipUserTypeIds.includes(id)) : true;
        const matchesAthleteStatuses = ruleAthleteStatusIds.length > 0 ? ruleAthleteStatusIds.some(id => id === m.athleteStatus?.id) : true;
        const matchesPositions = rulePositionIds.length > 0 ? rulePositionIds.some(id => membershipPositionIds.includes(id)) : true;

        return hasMembership && matchesGender && matchesTeams && matchesUserTypes && matchesAthleteStatuses && matchesPositions;
      });

      if (matchingMembership) {
        matchingRule = rule;
        break;
      }
    }
  }
  return matchingRule && { matchingRule };
};

const profileHasMembershipForRule = (
  syncRule: ProfileSyncRuleInProgress,
  profile: TeamworksProfile,
  linkedTeamworksTeamIds: readonly number[]
): boolean => {
  return syncRule.notemealAccountType !== "athlete" || !!profile.memberships?.some(m => linkedTeamworksTeamIds.includes(m.teamId));
};

interface IsValidSyncRulePayload {
  isValidSyncRule: boolean;
  hasValidSelectionCriteria: boolean;
  hasValidNotemealTeams: boolean;
}

export const getIsValidSyncRule = (
  profileSyncRule: TeamworksProfileSyncRuleState,
  allLinkedTeamworkTeams: readonly TeamworksTeam[]
): IsValidSyncRulePayload => {
  if (profileSyncRule.matchOnProfiles) {
    return {
      isValidSyncRule: true,
      hasValidSelectionCriteria: true,
      hasValidNotemealTeams: true,
    };
  } else {
    const teamsToCheck = profileSyncRule.teams.length > 0 ? profileSyncRule.teams : allLinkedTeamworkTeams;

    const hasValidTeams = teamsToCheck.every(team => {
      if (team.notemealTeams.length <= 1) {
        return true;
      }

      return team.notemealTeams.some(notemealTeam => profileSyncRule.matchNotemealTeamIds.includes(notemealTeam.id));
    });
    const isForAthlete = profileSyncRule.notemealAccountType === "athlete";
    const hasValidNotemealTeams = !isForAthlete || hasValidTeams;
    const hasValidSelectionCriteria =
      profileSyncRule.teams.length +
        profileSyncRule.athleteStatuses.length +
        profileSyncRule.userTypes.length +
        profileSyncRule.genders.length +
        profileSyncRule.positions.length >
      0;
    const isValidSyncRule = hasValidNotemealTeams && hasValidSelectionCriteria;
    return {
      hasValidNotemealTeams,
      hasValidSelectionCriteria,
      isValidSyncRule,
    };
  }
};

type TeamworksProfileWithRequiredFields = Omit<TeamworksProfile, "firstName" | "lastName" | "userId"> & {
  firstName: string;
  lastName: string;
  userId: number;
};

export const teamworksProfileHasRequiredFields = (profile: TeamworksProfile): profile is TeamworksProfileWithRequiredFields => {
  const { firstName, lastName, userId } = profile;
  return firstName !== null && lastName !== null && userId !== null;
};

const ALL_TEAMS = "All Teams";

/**
 * This function transforms the data from Teamworks API to remove the team called "All Teams"
 * This team is used to give a profile a membership to every team, but this causes issues
 * For the rest of our logic, so we remove the "All Teams" team, and transform memberships on
 * the "All Teams" team into a membership on every other team.
 *
 * @param teams
 * @param profiles
 */
export const removeAllTeamsAndTransformProfiles = (
  teams: readonly TeamworksTeam[],
  profiles: readonly TeamworksProfile[],
  _disabledProfileIds: readonly number[] | null
): {
  teamworksTeams: readonly TeamworksTeam[];
  allTeamworksProfiles: readonly TeamworksProfile[];
} => {
  const teamworksTeams = teams.filter(t => t.name !== ALL_TEAMS);
  const _allTeamworksProfiles = profiles.map(p => ({
    ...p,
    memberships:
      p.memberships?.flatMap(({ teamId, teamName, ...rest }) => {
        if (teamName === ALL_TEAMS) {
          return teamworksTeams.map(({ id, name }) => ({
            teamId: id,
            teamName: name,
            ...rest,
          }));
        } else {
          return [
            {
              teamId,
              teamName,
              ...rest,
            },
          ];
        }
      }) ?? [],
  }));

  const disabledProfileIds = new Set(_disabledProfileIds);
  const allTeamworksProfiles =
    _disabledProfileIds === null ? _allTeamworksProfiles : _allTeamworksProfiles.filter(profile => !disabledProfileIds.has(profile.id));

  return {
    teamworksTeams,
    allTeamworksProfiles,
  };
};

interface getInitTeamworksProfilesStateArgs {
  org: OrgForLinking;
  teamworksTeams: readonly TeamworksTeam[];
  teamworksUserTypes: readonly TeamworksGroup[];
  teamworksAthleteStatuses: readonly TeamworksGroup[];
  allTeamworksProfiles: readonly TeamworksProfile[];
  teamworksPositions: readonly TeamworksGroup[];
}

export const getInitTeamworksProfilesState = ({
  org,
  teamworksTeams,
  teamworksPositions,
  teamworksAthleteStatuses,
  teamworksUserTypes,
  allTeamworksProfiles,
}: getInitTeamworksProfilesStateArgs): TeamworksProfilesState => {
  const linkedProfiles: LinkedProfile[] = [];
  const unlinkedTeamworksProfiles: TeamworksProfile[] = [];

  for (const teamworksProfile of allTeamworksProfiles) {
    const matchingNotemealOrgMembership = org.memberships.find(om => om.teamworksId === teamworksProfile.id);
    if (matchingNotemealOrgMembership) {
      linkedProfiles.push({
        isPending: false,
        teamworks: teamworksProfile,
        notemeal: matchingNotemealOrgMembership,
      });
    } else {
      unlinkedTeamworksProfiles.push(teamworksProfile);
    }
  }

  const allTeamworksProfileIds = allTeamworksProfiles.map(p => p.id);
  const allAthleteWithOrgMembershipIds = org.memberships.flatMap(o => o.athlete?.id ?? []);

  const unlinkedOrgMemberships = org.memberships.filter(m => !m.teamworksId && !m.isNotemealOnly);
  const notemealOnlyOrgMemberships = org.memberships.filter(m => m.isNotemealOnly);
  const notemealOnlyAthletes = org.athletes.filter(a => a.isNotemealOnly);
  const unlinkedAthletes = org.athletes.filter(a => !allAthleteWithOrgMembershipIds.includes(a.id) && !a.isNotemealOnly);
  const linkNotFoundOrgMemberships = org.memberships.filter(m => m.teamworksId !== null && !allTeamworksProfileIds.includes(m.teamworksId));

  const notemealOnlyState = {
    notemealOnlyOrgMemberships: notemealOnlyOrgMemberships.map(m => ({ ...m, isPending: false })),
    removeNotemealOnlyOrgMemberships: [],
    notemealOnlyAthletes: notemealOnlyAthletes.map(a => ({ ...a, isPending: false })),
    removeNotemealOnlyAthletes: [],
  };

  return {
    syncRules: org.teamworksProfileSyncRules.map(rule => {
      const teams = teamworksTeams.filter(team => rule.teamIds?.includes(team.id));
      const userTypes = teamworksUserTypes.filter(userType => userType.type === "user_type" && rule.userTypeIds?.includes(userType.id));
      const athleteStatuses = teamworksAthleteStatuses.filter(
        status => status.type === "athlete_status" && rule.athleteStatusIds?.includes(status.id)
      );
      const profiles = allTeamworksProfiles.filter(profile => rule.profileIds?.includes(profile.id));

      const positions = teamworksPositions.filter(position => position.type === "position" && rule.positionIds?.includes(position.id));

      const genders = rule.genders ?? [];

      const matchNotemealTeamIds = rule.matchNotemealTeamIds ?? [];

      return {
        ...rule,
        teams,
        userTypes,
        athleteStatuses,
        profiles,
        positions,
        genders,
        matchNotemealTeamIds,
        matchOnProfiles: !!rule.profileIds,
        onlyNotemealProfilesOnSelectedTeams: false,
      };
    }),
    linkedProfiles,
    unlinkedNotemealProfiles: [...unlinkedOrgMemberships, ...unlinkedAthletes],
    unlinkedTeamworksProfiles,
    linkNotFoundOrgMemberships,
    notemealOnlyState,
    archiveAthletes: [],
  };
};

// TODO: Standardize Result interface
type Result<T, E> =
  | {
      type: "ok";
      ok: T;
    }
  | {
      type: "err";
      err: E;
    };

export const getEditTeamworksProfileLinksInput = (
  org: OrgForLinking,
  state: TeamworksProfilesState,
  teamworksPositionLinks: readonly TeamworksPositionLink[],
  allLinkedTeamworkTeams: readonly TeamworksTeam[]
): Result<EditOrgTeamworksProfilesInput, string> => {
  const { syncRules, toDeactivateLinkedProfiles, toDeactivateOrgMemberships } = assignProfilesToSyncRules(state, allLinkedTeamworkTeams);
  if (!syncRules.every(rule => getIsValidSyncRule(rule, allLinkedTeamworkTeams).isValidSyncRule)) {
    return {
      type: "err",
      err: "Some profile sync rules are invalid, fix them to advance.",
    };
  }

  const profileLinks = syncRules.flatMap(rule =>
    // ignore profiles that contain invalid data by flat mapping here
    rule.linkedProfiles.flatMap(profile => getTeamworksProfileLinkInput(profile, rule, org.teams, teamworksPositionLinks) ?? [])
  );

  return {
    type: "ok",
    ok: {
      orgId: org.id,
      profileLinks,
      profileSyncRules: syncRules.map(getTeamworksProfileSyncRuleInput),
      deactivateTeamworksProfileIds: toDeactivateLinkedProfiles.map(p => p.teamworks.id),
      deactivateOrgMembershipIds: toDeactivateOrgMemberships.map(om => om.id),
      notemealOnlyOrgMembershipIds: state.notemealOnlyState.notemealOnlyOrgMemberships.filter(a => a.isPending).map(({ id }) => id),
      removeNotemealOnlyOrgMembershipIds: state.notemealOnlyState.removeNotemealOnlyOrgMemberships.map(({ id }) => id),
      notemealOnlyAthleteIds: state.notemealOnlyState.notemealOnlyAthletes.filter(a => a.isPending).map(({ id }) => id),
      removeNotemealOnlyAthleteIds: state.notemealOnlyState.removeNotemealOnlyAthletes.map(({ id }) => id),
      archiveAthleteIds: state.archiveAthletes.map(a => a.id),
    },
  };
};

const getTeamworksProfileSyncRuleInput = ({
  athleteStatuses,
  matchOnProfiles,
  teams,
  profiles,
  notemealAccountType,
  userTypes,
  priority,
  genders,
  matchNotemealTeamIds,
  positions,
}: ProfileSyncRuleWithEntities): TeamworksProfileSyncRuleInput => {
  if (matchOnProfiles) {
    return {
      athleteStatusIds: null,
      teamIds: null,
      userTypeIds: null,
      profileIds: profiles.map(p => p.id),
      priority,
      notemealAccountType,
      genders: null,
      matchNotemealTeamIds: null,
      positionIds: null,
    };
  } else {
    return {
      athleteStatusIds: athleteStatuses.length > 0 ? athleteStatuses.map(a => a.id) : null,
      teamIds: teams.length > 0 ? teams.map(t => t.id) : null,
      userTypeIds: userTypes.length > 0 ? userTypes.map(u => u.id) : null,
      profileIds: null,
      priority,
      notemealAccountType,
      genders: genders.length > 0 ? genders : null,
      matchNotemealTeamIds: matchNotemealTeamIds.length > 0 ? matchNotemealTeamIds : null,
      positionIds: positions.length > 0 ? positions.map(p => p.id) : null,
    };
  }
};

const getTeamworksProfileLinkInput = (
  { teamworks, notemeal }: LinkedProfile,
  profileSyncRule: ProfileSyncRuleWithEntities,
  notemealTeams: readonly TeamLinkPreview[],
  teamworksPositionLinks: readonly TeamworksPositionLink[]
): TeamworksProfileLinkInput | null => {
  const { notemealAccountType, matchNotemealTeamIds } = profileSyncRule;
  if (!teamworksProfileHasRequiredFields(teamworks)) {
    return null;
  }
  if (notemeal?.__typename === "Athlete" && notemealAccountType !== "athlete") {
    return null;
  }

  let teamId: string | null = null;
  let positionId: string | null = null;
  let sex: SexType | null = null;
  if (notemealAccountType === "athlete") {
    // Give priority to team memberships that match one on profileSyncRule
    const profileSyncRuleTeamIds = profileSyncRule.matchOnProfiles ? [] : profileSyncRule.teams.map(t => t.id);
    const orderedTeamworksMemberships = sortByFn(teamworks.memberships ?? [], m => {
      const membershipTeamOnRule = profileSyncRuleTeamIds.includes(m.teamId);
      return `${membershipTeamOnRule ? 0 : 1}:${m.teamId}`;
    });
    const matchingMembership = orderedTeamworksMemberships
      .flatMap(membership => {
        const potentialNotemealTeams = notemealTeams.filter(t => t.teamworksId === membership.teamId);
        let notemealTeam: TeamLinkPreview | null;
        if (potentialNotemealTeams.length === 0) {
          notemealTeam = null;
        } else if (potentialNotemealTeams.length === 1) {
          notemealTeam = potentialNotemealTeams[0];
        } else {
          notemealTeam = potentialNotemealTeams.find(({ id }) => matchNotemealTeamIds.includes(id)) ?? null; // TODO: Is this the proper behaviour if a team is not found
        }
        if (!notemealTeam) {
          return [];
        } else {
          return {
            membership,
            notemealTeam,
          };
        }
      })
      .find(() => true);
    if (!matchingMembership) {
      return null;
    }

    const teamworksPositionIds = (matchingMembership.membership.positions?.map(p => p.id) ?? []).sort();

    teamId = matchingMembership.notemealTeam.id;
    positionId =
      teamworksPositionLinks.find(
        l => l.teamworksTeamId === matchingMembership.membership.teamId && teamworksPositionIds.includes(l.teamworksId)
      )?.position.id ?? null;

    if (teamworks.gender === "M" || matchingMembership.notemealTeam.gender === "Men's") {
      sex = "male";
    } else if (teamworks.gender === "F" || matchingMembership.notemealTeam.gender === "Women's") {
      sex = "female";
    } else if (notemeal && notemeal.__typename === "OrgMembership" && notemeal.athlete) {
      sex = notemeal.athlete.sex;
    } else if (notemeal && notemeal.__typename === "Athlete") {
      sex = notemeal.sex;
    } else {
      sex = "female";
    }
  }

  return {
    teamworksProfileId: teamworks.id,
    teamworksUserId: teamworks.userId,
    active: teamworks.active,
    hasLoginAccess: teamworks.hasLoginAccess,
    birthDate: teamworks.birthDate,
    cellPhone: teamworks.cellPhone,
    email: teamworks.email,
    firstName: teamworks.firstName,
    lastName: teamworks.lastName,
    notemealAccountType,
    orgMembershipId: notemeal?.__typename === "OrgMembership" ? notemeal.id : null,
    athleteId: notemeal?.__typename === "Athlete" ? notemeal.id : null,
    teamId,
    sex,
    positionId,
  };
};

export const getTeamworksUserIdCounts = (allTeamworksProfiles: readonly TeamworksProfile[]): Map<number, number> => {
  const teamworksUserIdCounts = new Map<number, number>();
  for (const { userId } of allTeamworksProfiles) {
    if (userId) {
      const currentCount = teamworksUserIdCounts.get(userId) ?? 0;
      teamworksUserIdCounts.set(userId, currentCount + 1);
    }
  }
  return teamworksUserIdCounts;
};

export const canLinkTeamworksProfile = (profile: TeamworksProfile, teamworksUserIdCounts: Map<number, number>): boolean => {
  const hasRequiredFields = teamworksProfileHasRequiredFields(profile);
  const userIdCount = (profile.userId && teamworksUserIdCounts.get(profile.userId)) ?? 0;
  const userIdCollision = userIdCount > 1;
  return hasRequiredFields && !userIdCollision;
};
