import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, Grid, IconButton, InputAdornment, SxProps, TextField, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { useMyScoringSystemQuery } from "apps/web/src/types";
import React, { Dispatch, useState } from "react";
import NutrientAmountModal from "../../NutrientAmount/Modal";
import Details from "./Details";
import Images from "./Images/Images";
import { Ingredients } from "./Ingredients";
import { Instructions } from "./Instructions";
import RecipeFormServing from "./Serving";
import { RecipeFormAction, RecipeFormState } from "./utils";

interface RecipeFormProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  sx?: SxProps;
  disabled?: boolean;
  forStaff?: boolean;
  onAiGenerateClicked: null | (() => void);
  onAiTranscribeClicked?: () => void;
}

const RecipeForm = ({ state, dispatch, sx, disabled, forStaff, onAiGenerateClicked, onAiTranscribeClicked }: RecipeFormProps) => {
  const [nutrientsOpen, setNutrientsOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(true);

  const handleClose = () => {
    setShowWarning(false);
  };

  const { data: scoringSystemData, loading: loadingScoringSystem } = useMyScoringSystemQuery();
  const scoringSystem = scoringSystemData?.myScoringSystem;

  const unmatchedName = state.unmatchedIngredients.unmatchedName.map(i => `${i.foodName} (${i.amount} ${i.servingName})`);
  const unmatchedServings = state.unmatchedIngredients.unmatchedServings.map(i => `${i.foodName} (${i.amount} ${i.servingName})`);

  return (
    <>
      {(unmatchedName.length > 0 || unmatchedServings.length > 0) && showWarning && (
        <Box
          sx={theme => ({
            backgroundColor: theme.palette.warning.lighter,
            borderRadius: 1,
            p: 2,
            position: "relative",
          })}
        >
          <Grid container spacing={1}>
            <Grid item>
              <WarningRoundedIcon color="warning" />
            </Grid>
            <Grid item>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}>
                <Typography variant="body1Semibold" lineHeight="18px">
                  Warning
                </Typography>
                {unmatchedName.length > 0 && (
                  <Typography variant="body2">
                    Certain <u>ingredient(s)</u> could not be found. Please add the following ingredient(s) manually:{" "}
                    <strong>{unmatchedName.join(", ")}</strong>
                  </Typography>
                )}
                {unmatchedServings.length > 0 && (
                  <Typography variant="body2">
                    Certain <u>ingredient unit(s)</u> for the following ingredient(s) could not be found. Please add the following
                    ingredient(s) manually: <strong>{unmatchedServings.join(", ")}</strong>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose} sx={{ position: "absolute", top: 4, right: 4 }}>
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid
        container
        direction="row"
        spacing={4}
        sx={{
          display: "flex",
          alignItems: "stretch",
          textOverflow: "auto",
          ...sx,
        }}
      >
        {/** Left panel */}
        <Grid item xs={8}>
          <Grid
            container
            direction={"row"}
            spacing={3}>
            {/** Details and Servings */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {loadingScoringSystem ? (
                    <Loading />
                  ) : (
                    <Details
                      forStaff={forStaff}
                      disabled={disabled}
                      state={state}
                      dispatch={dispatch}
                      scoringSystem={scoringSystem} />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RecipeFormServing
                    disabled={disabled}
                    state={state}
                    dispatch={dispatch} />
                </Grid>
              </Grid>
            </Grid>
            {/** Ingredients and Directions/Notes */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Ingredients
                    disabled={disabled}
                    state={state}
                    dispatch={dispatch}
                    setNutrientsOpen={setNutrientsOpen}
                    onAiGenerateClicked={onAiGenerateClicked}
                    onAiTranscribeClicked={onAiTranscribeClicked}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Instructions
                    disabled={disabled}
                    steps={state.steps}
                    dispatch={dispatch} />

                  <Typography
                    variant="h3"
                    color="textSecondary"
                    sx={{ pt: 4 }}>
                    Notes
                  </Typography>
                  <TextField
                    disabled={disabled}
                    margin="dense"
                    fullWidth
                    value={state.note}
                    onChange={e =>
                      dispatch({
                        type: "CHANGE_NOTE",
                        value: e.target.value,
                      })
                    }
                    placeholder="Add a note to the recipe"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          disablePointerEvents
                          sx={{ opacity: disabled ? 0.33 : 1 }}>
                          <strong>*</strong>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Images
            disabled={disabled}
            dispatch={dispatch}
            state={state}
            sx={{}} />
        </Grid>
      </Grid>
      {nutrientsOpen && (
        <NutrientAmountModal
          open={nutrientsOpen}
          onClose={() => setNutrientsOpen(false)}
          servingAmounts={state.ingredients.map(sa => ({
            ...sa,
            amount: sa.amount / state.serving.perRecipeYield,
          }))}
        />
      )}
    </>
  );
};

export default RecipeForm;
