import { Box, InputAdornment, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { athleteAgeToApproxBirthDate, maybeAthleteBirthDateToAge } from "@notemeal/shared/utils/macro-protocol";
import { sortByKey } from "@notemeal/utils/sort";
import NamedTagSelector from "apps/web/src/views/Tags/Selectors/NamedTagSelector";
import React, { useMemo } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { PositionFragment, SexType, TeamWithSportFragment } from "../../../types";
import PhoneNumberInput from "../../global/PhoneNumberInput";
import LabeledSelect from "../../universal/LabeledSelect";
import { AthleteFormType } from "./AthleteFormSchema";
import SexTypeSelect from "./SexTypeSelect";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(1),
    },
    field: {
      flex: "1 1 0",
      width: 0,
      maxWidth: "300px",
    },
    namedTagField: {
      flex: "1 1",
      width: 0,
      display: "flex",
      alignItems: "center",
      paddingTop: spacing(0.5),
    },
    fieldSmall: {
      flex: "0.5 1 0",
      width: 0,
      maxWidth: "300px",
    },
  });
});

interface AthleteFormProps {
  isForSyncedAthlete: boolean;
  displayUidFields?: boolean;
  username?: string;
  teams?: readonly TeamWithSportFragment[];
  form: UseFormReturn<AthleteFormType>;
}

const AthleteForm = ({ teams, displayUidFields, isForSyncedAthlete, username, form }: AthleteFormProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = form;
  const classes = useStyles();

  const [watchedBirthDate, watchedTeamId, watchedPosition] = watch(["birthDate", "teamId", "position"]);

  const initBirthDate = control._defaultValues.birthDate;
  const initPosition = control._defaultValues.position;

  const team = teams?.find(t => t.id === watchedTeamId);

  const teamOptions = useMemo(() => {
    if (isForSyncedAthlete) {
      return team ? [team] : undefined;
    }
    return teams ? [...sortByKey(teams, "name")] : undefined;
  }, [teams, isForSyncedAthlete, team]);

  const getTeamPositions = (t: TeamWithSportFragment | undefined) => (t?.sport?.positions ? [...sortByKey(t.sport.positions, "name")] : []);

  const positionOptions = isForSyncedAthlete && !!watchedPosition ? [watchedPosition] : getTeamPositions(team);

  return (
    <div className={classes.root}>
      <Typography variant="body1Medium" sx={{ mt: 1 }}>
        Basic Information
      </Typography>
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
        <Controller
          name={"firstName"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message ?? " "}
              required
              autoFocus={!field.value}
              className={classes.field}
              label="First Name"
              disabled={isForSyncedAthlete}
            />
          )}
        />
        <Controller
          name={"lastName"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message ?? " "}
              required
              className={classes.field}
              label="Last Name"
              disabled={isForSyncedAthlete}
            />
          )}
        />
        <Controller
          name={"sex"}
          control={control}
          render={({ field }) => (
            <SexTypeSelect
              required
              value={field.value as SexType | null}
              onChange={field.onChange}
              error={Boolean(errors.sex)}
              helperText={errors.sex?.message ?? " "}
            />
          )}
        />
        <Controller
          name={"birthDate"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <DesktopDatePicker
              sx={{ mt: 1 }}
              {...field}
              className={classes.field}
              label="Birth Date"
              disabled={isForSyncedAthlete && !!initBirthDate}
              slotProps={{
                textField: {
                  error: Boolean(errors.birthDate),
                  helperText: errors.birthDate?.message ?? " ",
                },
              }}
            />
          )}
        />
        <Controller
          name={"birthDate"}
          control={control}
          render={({ field }) => (
            <TextField
              className={classes.fieldSmall}
              type="number"
              label="Age"
              //empty helper text gives us consistent spacing
              helperText={" "}
              disabled={isForSyncedAthlete || !!watchedBirthDate}
              onBlur={e => !!e.target.value && field.onChange(athleteAgeToApproxBirthDate(parseInt(e.target.value)))}
              value={field.value && field.value.getDate() ? maybeAthleteBirthDateToAge(field.value.toISOString()) : undefined}
            />
          )}
        />
      </Box>
      {displayUidFields && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <Controller
            name={"secaUid"}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                className={classes.field}
                label="SECA ID"
                error={Boolean(errors.secaUid)}
                helperText={errors.secaUid?.message}
              />
            )}
          />
          <Controller
            name={"inbodyUid"}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                className={classes.field}
                label="InBody ID"
                error={Boolean(errors.inbodyUid)}
                helperText={errors.inbodyUid?.message}
              />
            )}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", gap: 2 }}>
        {Boolean(username) && <TextField
          className={classes.field}
          variant="outlined"
          label="Username"
          value={username}
          disabled />}
        <Controller
          name={"namedTags"}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={classes.namedTagField}>
              <NamedTagSelector
                sx={{ width: "100%" }}
                selectedNamedTags={value}
                onChange={newNamedTags => {
                  const existingSmartTags = value.filter(({ tag: { __typename } }) => __typename !== "AthleteTag");
                  const newNamedAthleteTags = newNamedTags.filter(({ tag: { __typename } }) => __typename === "AthleteTag");
                  onChange([...existingSmartTags, ...newNamedAthleteTags]);
                }}
                filterNamedTags={namedTags => namedTags.filter(({ tag: { __typename } }) => __typename === "AthleteTag")}
                preventSmartTagRemoval
              />
            </div>
          )}
        />
      </Box>

      <Typography variant="body1Medium" sx={{ mt: 1 }}>
        Team Information
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        {teamOptions && (
          <Controller
            name={"teamId"}
            control={control}
            render={({ field: { value, onChange } }) => (
              <LabeledSelect
                placeholder="Team"
                options={teamOptions}
                onChange={isForSyncedAthlete ? () => {} : team => onChange(team.id)}
                selectedOption={teams?.find(t => t.id === value) ?? null}
                textFieldProps={{
                  margin: "dense",
                  className: classes.field,
                  fullWidth: false,
                  disabled: isForSyncedAthlete,
                }}
                optionToName={t => t.name}
              />
            )}
          />
        )}
        <Controller
          name={"position"}
          control={control}
          render={({ field: { value, onChange } }) => (
            <LabeledSelect<PositionFragment>
              placeholder="Position"
              options={positionOptions}
              selectedOption={value}
              onChange={onChange}
              className={classes.field}
              textFieldProps={{
                margin: "dense",
                fullWidth: false,
                disabled: isForSyncedAthlete && !!initPosition,
              }}
              optionToName={pos => pos.name}
            />
          )}
        />
        <Controller
          name={"jerseyNumber"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              className={classes.field}
              label="Jersey"
              InputProps={{
                startAdornment: <InputAdornment position="start">#</InputAdornment>,
              }}
              error={Boolean(errors.jerseyNumber)}
              helperText={errors.jerseyNumber?.message}
            />
          )}
        />
      </Box>
      <Typography variant="body1Medium" sx={{ mt: 1 }}>
        Contact Information
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Controller
          name={"email"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Tooltip title="Email will only be used to send athlete meal plans">
              <TextField
                {...field}
                className={classes.field}
                label="Email"
                disabled={isForSyncedAthlete}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Tooltip>
          )}
        />
        <Controller
          name={"phoneNumber"}
          control={control}
          render={({ field: { value, onChange } }) => (
            <PhoneNumberInput
              className={classes.field}
              setInput={isForSyncedAthlete ? () => {} : input => onChange(input)}
              input={value}
              disabled={isForSyncedAthlete}
              disablePadding
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber?.message}
            />
          )}
        />
      </Box>
    </div>
  );
};

export default AthleteForm;
