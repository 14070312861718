import { Box, MenuItem, SxProps, TextField } from "@mui/material";
import { ORDERED_EXCHANGE_TYPES } from "@notemeal/shared/ui/Exchange/utils";
import React from "react";
import { ExchangeType } from "../../types";

interface ExchangeTypeMultiSelectProps {
  handleChangeExchangeTypes: (exchangeTypes: ExchangeType[]) => void;
  selectedExchangeTypes: readonly ExchangeType[] | null;
  label: string;
  className?: string;
  sx?: SxProps;
  fullWidth?: boolean;
  margin?: "none" | "dense" | "normal";
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const ExchangeTypeMultiSelect = (props: ExchangeTypeMultiSelectProps) => {
  return (
    <Box className={props.className} sx={props.sx}>
      <TextField
        disabled={props.disabled || props.selectedExchangeTypes === null}
        select
        error={props.error}
        helperText={props.helperText}
        margin={props.margin || "normal"}
        label={props.label}
        value={props.selectedExchangeTypes || []}
        SelectProps={{
          multiple: true,
        }}
        fullWidth={props.fullWidth}
        onChange={e => props.handleChangeExchangeTypes(e.target.value as unknown as ExchangeType[])}
      >
        {ORDERED_EXCHANGE_TYPES.map(et => {
          const label = String(et).slice(0, 1).toUpperCase() + String(et).slice(1);
          const isSelected = (props.selectedExchangeTypes || []).includes(et);
          return (
            <MenuItem
              key={et}
              value={et}
              sx={isSelected ? { fontWeight: "bold" } : {}}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};
export default ExchangeTypeMultiSelect;
