import React from "react";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton, ListItem, ListItemText, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    focusedItem: {
      backgroundColor: theme.palette.info.light,
    },
    clickable: {
      cursor: "pointer",
    },
  })
);

export type CardMode = "Add" | "Link" | "Edit" | "Cancel" | "NoAction" | "Info";

export interface DisplayCardProps<T> {
  primaryText: string;
  secondaryElement?: React.ReactNode;
  element: T;
  disabled?: boolean;
  focused?: boolean;
  mode?: CardMode;
  onAdd?: (element: T) => void;
  onLink?: (element: T) => void;
  onEdit?: (element: T) => void;
  onCancel?: (element: T) => void;
  infoTooltip?: string;
  className?: string;
  middleElement?: React.ReactNode;
  onClick?: (element: T) => void;
  hasAdditionalProfileInfo?: boolean;
}

const noOp = () => {};

const DisplayCard = <T,>({
  primaryText,
  secondaryElement,
  element,
  disabled = false,
  focused: _focused = false,
  mode = "NoAction",
  onAdd = noOp,
  onLink = noOp,
  onEdit = noOp,
  onCancel = noOp,
  infoTooltip,
  className,
  middleElement = null,
  onClick,
}: DisplayCardProps<T>) => {
  const classes = useStyles();

  const addAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onAdd(element);
      }}
      disabled={disabled}
      size="large"
    >
      <AddIcon />
    </IconButton>
  );

  const linkAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onLink(element);
      }}
      disabled={disabled}
      size="large"
    >
      <LinkIcon />
    </IconButton>
  );

  const editAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onEdit(element);
      }}
      disabled={disabled}
      size="large"
    >
      <EditIcon />
    </IconButton>
  );

  const cancelAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onCancel(element);
      }}
      disabled={disabled}
      size="large"
    >
      <CancelIcon />
    </IconButton>
  );

  const info = (
    <Tooltip title={infoTooltip ?? ""}>
      <IconButton size="large">
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );

  const actionPicker: Record<CardMode, JSX.Element> = {
    Add: addAction,
    Link: linkAction,
    Edit: editAction,
    Cancel: cancelAction,
    Info: info,
    NoAction: <></>,
  };

  const focused = _focused && !disabled;
  return (
    <ListItem
      divider
      disabled={disabled}
      className={classNames(className, {
        [classes.focusedItem]: focused,
        [classes.clickable]: !!onClick,
      })}
      onClick={onClick ? () => onClick(element) : undefined}
    >
      <ListItemText primary={primaryText} secondary={secondaryElement} />
      {middleElement}
      {actionPicker[mode]}
    </ListItem>
  );
};

export default DisplayCard;
