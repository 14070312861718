import { TableCell, TableRow } from "@mui/material";
import React from "react";

export const OrgHeaderRow = ({ hasScoringSystem }: { hasScoringSystem: boolean }) => (
  <TableRow>
    <TableCell sx={{ width: 150 }}>Shared</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Created By</TableCell>
    <TableCell>Meal Types</TableCell>
    <TableCell>Yields</TableCell>
    {hasScoringSystem && <TableCell>Score</TableCell>}
    <TableCell padding="checkbox">Actions</TableCell>
  </TableRow>
);

export const OrgGroupHeaderRow = ({ hasScoringSystem }: { hasScoringSystem: boolean }) => (
  <TableRow>
    <TableCell sx={{ width: 150 }}>Shared</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Org Group</TableCell>
    <TableCell>Managed By</TableCell>
    <TableCell>Meal Types</TableCell>
    <TableCell>Yields</TableCell>
    {hasScoringSystem && <TableCell>Score</TableCell>}
    <TableCell># of Duplications</TableCell>
    <TableCell padding="checkbox">Actions</TableCell>
  </TableRow>
);

export const StaffHeaderRow = () => (
  <TableRow>
    <TableCell sx={{ width: 150 }}>Shared</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Created By</TableCell>
    <TableCell>Meal Types</TableCell>
    <TableCell>Yields</TableCell>
    <TableCell>Date Added</TableCell>
    <TableCell padding="checkbox">Actions</TableCell>
  </TableRow>
);
