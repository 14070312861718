import { datadogRum } from "@datadog/browser-rum";
import { zodResolver } from "@hookform/resolvers/zod";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button, Dialog, DialogContent, DialogTitle, List, ListItem, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import SomethingWentWrongDialog from "apps/web/src/componentLibrary/ConfirmationDialog/SomethingWentWrongDialog";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { getNavOrgKitchenMenuScheduleImport } from "apps/web/src/pages/Auth/Org/Kitchen/KitchenPaths";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { PlannedMenuMealInput, useSavePlannedMealsMutation } from "apps/web/src/types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom-v5-compat";
import { MenuBuilderSchema, MenuBuilderType } from "./Meal/MenuBuilderMealSchema";
import { MenuBuilderExportButton } from "./MenuBuilderExportButton";
import { useMenuBuilderContext } from "./MenuBuilderProvider";
import { MenuBuilderWeek } from "./MenuBuilderWeek";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog";

export const MenuBuilderGrid = () => {
  const navigate = useNavigate();
  const { formatYearlessDateWithLocale } = useDateFormatting();

  const {
    menuId,
    menuName,
    weeks,
    loading,
    selectedWeekIndex,
    setSelectedWeekIndex,
    mealsToDelete,
    setMealsToDelete,
    rowsToDelete,
    setRowsToDelete,
    rowItemsToDelete,
    setRowItemsToDelete,
    disabledDays,
    isEditable,
  } = useMenuBuilderContext();

  const [errorWhileSavingDialog, setErrorWhileSavingDialog] = useState(false);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogueOpen] = useState(false);
  const [weeksWithErrors, setWeeksWithErrors] = useState<number[]>([]);
  const { setMessage } = useSnackbar();
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();

  const [savePlannedMeals, { loading: loadingSavePlannedMeals }] = useSavePlannedMealsMutation({
    refetchQueries: ["PlannedMenuGrid"],
    onError: e => {
      setErrorWhileSavingDialog(true);
      console.error(e);
    },
    onCompleted: () => {
      datadogRum.addAction("menu_builder.saved_menu", { menuId });
      setMessage("success", `${menuName} has been saved`);
    },
  });

  const form = useForm<MenuBuilderType>({
    resolver: zodResolver(MenuBuilderSchema),
    defaultValues: { weeks: [] },
  });

  const { isDirty, touchedFields } = form.formState;
  const data = form.getValues();

  // using forEach to avoid needing a type assertion with map
  const dirtiedFields: `weeks.${number}.meals.${number}`[] = [];
  data.weeks.forEach((week, weekIndex) => {
    week.meals.forEach((meal, mealIndex) => {
      if (form.getFieldState(`weeks.${weekIndex}.meals.${mealIndex}`).isDirty) {
        dirtiedFields.push(`weeks.${weekIndex}.meals.${mealIndex}`);
      } else {
        const hasDirtyRows = meal.rows.some((_, index) => {
          if (form.getFieldState(`weeks.${weekIndex}.meals.${mealIndex}.rows.${index}`).isDirty) {
            return true;
          }
          const dirtyRowItems = form.getValues(`weeks.${weekIndex}.meals.${mealIndex}.rows.${index}.items`).some(item => item.id === "");
          return dirtyRowItems;
        });
        if (hasDirtyRows) {
          dirtiedFields.push(`weeks.${weekIndex}.meals.${mealIndex}`);
        }
      }
    });
  });

  const isFormEdited =
    (isDirty && Object.keys(touchedFields).length > 0) ||
    mealsToDelete.length > 0 ||
    rowsToDelete.length > 0 ||
    rowItemsToDelete.length > 0 ||
    dirtiedFields.length > 0;

  useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(isFormEdited);
  }, [isFormEdited, setBrowserBackAndRefreshWarningEnabled]);

  if (loading) {
    return (
      <Box sx={{ height: "100%" }}>
        <Loading progressSize="lg" />
      </Box>
    );
  }
  const weekLabels = weeks.map(
    (week, index) => `Week ${index + 1} (${formatYearlessDateWithLocale(week.startDate)} - ${formatYearlessDateWithLocale(week.endDate)})`
  );

  // manually handle validation and submission because we want to skip validation on empty meals
  const handleSave = async () => {
    trackEvent("menu_builder.clicked_save", { menuId });

    if (await form.trigger(dirtiedFields)) {
      const mealsToUpsert: PlannedMenuMealInput[] = dirtiedFields.map(formId => {
        const meal = form.getValues(formId);
        return {
          id: meal.id,
          name: meal.mealName,
          type: meal.mealType,
          startTime: meal.startTime,
          endTime: meal.endTime,
          plannedMenuWeekId: meal.weekId,
          themes: meal.themes,
          plannedMenuMealRows: meal.rows.map(row => ({
            id: row.id,
            position: row.position,
            diningStationName: row.diningStationName,
            foodType: row.foodType,
            plannedMenuMealRowItems: row.items.map(item => ({
              id: item.id,
              dayOfWeek: item.dayOfWeek,
              position: item.position,
              menuItemId: item.menuItem.id,
            })),
          })),
        };
      });
      if (mealsToUpsert.length > 0 || mealsToDelete.length > 0 || rowsToDelete.length > 0 || rowItemsToDelete.length > 0) {
        await savePlannedMeals({
          variables: {
            input: {
              plannedMenuId: menuId,
              mealsToDelete,
              rowsToDelete,
              rowItemsToDelete,
              mealsToUpsert,
              disabledDays,
            },
          },
        });
        // set form to not dirty (while keeping edits), but don't unmount everything
        form.reset(form.getValues(), { keepValues: true });
        setMealsToDelete([]);
        setRowsToDelete([]);
        setRowItemsToDelete([]);
      }
    } else {
      // failed validation, check if there are errors in other week tabs
      const errors = form.formState.errors.weeks;
      if (Array.isArray(errors)) {
        const errorIndices = errors.reduce((indices, hasError, index) => (hasError ? [...indices, index] : null), []);
        datadogRum.addAction("menu_builder.save_failed_validation", { menuId, errors });
        if (errorIndices.length > 1 || errorIndices[0] !== selectedWeekIndex) {
          setWeeksWithErrors(errorIndices);
        }
      }
    }
  };

  const handlePublishClick = () => {
    datadogRum.addAction("menu_builder.table.clicked_publish_menu", { menuId });

    if (isFormEdited) {
      setUnsavedChangesDialogueOpen(true);
    } else {
      navigate(getNavOrgKitchenMenuScheduleImport(serializeDate(weeks[0].startDate), menuId));
    }
  };

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "auto 1fr",
        overflow: "auto",
      }}
    >
      <Box sx={{ pb: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <TWTabGroup
          sx={{ maxWidth: 1000 }}
          tabs={weekLabels}
          onSelected={selected => setSelectedWeekIndex(weekLabels.indexOf(selected))} />
        {isEditable && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <MenuBuilderExportButton form={form} weekLabels={weekLabels} />
            <Button
              variant="outlined"
              onClick={handlePublishClick}
              startIcon={<PublishOutlinedIcon />}>
              Publish
            </Button>
            <LoadingButton
              onClick={handleSave}
              buttonText={"Save"}
              loading={loadingSavePlannedMeals}
              icon={<SaveOutlinedIcon />} />
          </Box>
        )}
      </Box>
      <Box sx={{ overflow: "auto" }}>
        {weeks.map((week, index) => {
          return <MenuBuilderWeek
            key={week.id}
            weekFormIndex={index}
            weekId={week.id}
            form={form} />;
        })}
      </Box>

      <ConfirmationDialog
        open={weeksWithErrors.length > 0}
        title="Unable to Save Menu"
        message={
          <Box>
            <Typography>
              The menu cannot be saved due to unresolved issues. Please check and fix the errors on the following weeks in order to proceed:
            </Typography>
            <List sx={{ listStyleType: "disc", pl: 3, pb: 0 }}>
              {weeksWithErrors.map(weekIndex => (
                <ListItem sx={{ display: "list-item", p: 0 }}>{weekLabels[weekIndex]}</ListItem>
              ))}
            </List>
          </Box>
        }
        onCancel={() => setWeeksWithErrors([])}
        onConfirm={() => setWeeksWithErrors([])}
      />

      <UnsavedChangesDialog
        open={unsavedChangesDialogOpen}
        title="Unsaved Changes"
        message={`The menu has unsaved changes. Please make sure to save the menu in order to publish it.`}
        onCancel={() => {
          setUnsavedChangesDialogueOpen(false);
        }}
        onConfirm={() => {
          setUnsavedChangesDialogueOpen(false);
        }}
        messageSx={{ color: theme => theme.palette.common.black }}
      />

      <SomethingWentWrongDialog
        onClose={() => setErrorWhileSavingDialog(false)}
        open={errorWhileSavingDialog}
        loading={loading}
        content={"We were unable to save the menu due to internal server errors. Please try again by clicking on “Save”."}
      />

      <Dialog open={loadingSavePlannedMeals}>
        <DialogTitle>Save Menu</DialogTitle>
        <DialogContent>
          <Loading />
          <Typography sx={{ display: "flex", alignSelf: "center" }} variant="h4">
            Saving in progress...
          </Typography>
          <Typography variant="body2" color="mediumEmphasisText">
            Please sit back and wait momentarily as we finish saving the menu.
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
