import { datadogRum } from "@datadog/browser-rum";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { DayOfWeek } from "@notemeal/graphql/types";
import { grayBackground } from "@notemeal/palette";
import { sortByKey } from "@notemeal/utils/sort";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { MenuItemAppearanceForMenuBuilderFragment } from "apps/web/src/types";
import React, { useState } from "react";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import { useMenuBuilderContext } from "../MenuBuilderProvider";
import { MenuBuilderDiningStationTemplateSearch } from "./MenuBuilderDiningStationTemplateSearch";
import { MenuBuilderMealGridRowDay } from "./MenuBuilderMealGridRowDay";
import {
  MenuBuilderMealRowItemIndexedType,
  MenuBuilderMealRowItemType,
  MenuBuilderMenuItemType,
  MenuBuilderType,
} from "./MenuBuilderMealSchema";
import { MenuBuilderToolbar } from "./MenuBuilderToolbar";
import { DAY_CELL_HEIGHT, DAY_CELL_WIDTH, LEFT_PANEL_CELL_WIDTH } from "./constants";
import { getUniqueDiningStationNames } from "./utils";

interface MenuBuilderMealGridRowProps {
  form: UseFormReturn<MenuBuilderType>;
  weekFormIndex: number;
  mealFormIndex: number;
  rowFormIndex: number;
  deleteRow: (rowFormIndex: number) => void;
  currentWeeksDisabledDays: DayOfWeek[];
}

export const MenuBuilderMealGridRow = ({
  form,
  weekFormIndex,
  mealFormIndex,
  rowFormIndex,
  deleteRow,
  currentWeeksDisabledDays,
}: MenuBuilderMealGridRowProps) => {
  const { days, rowItemsToDelete, setRowItemsToDelete, menuId, clipboard, setClipboard, selectedDay, isEditable } = useMenuBuilderContext();
  const {
    control,
    formState: { errors },
  } = form;

  const formPath = `weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}` as const;

  const rowItemsFieldArray = useFieldArray<MenuBuilderType>({
    control: form.control,
    name: `${formPath}.items`,
  });

  const [templateItemAppearances, setTemplateItemAppearances] = useState<readonly MenuItemAppearanceForMenuBuilderFragment[] | null>(null);
  const { setMessage } = useSnackbar();
  const [toolbarPosition, setToolbarPosition] = useState<{ top: number; left: number } | undefined>(undefined);
  const [isSelected, setIsSelected] = useState(false);
  const [replaceDialogOpen, setReplaceDialogOpen] = useState(false);

  const {
    palette: { info, background, greyscale },
  } = useTheme();

  const allRowItems = form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}.items`);

  const addRowItem = (day: DayOfWeek, nextPosition: number, menuItem: MenuBuilderMenuItemType) => {
    datadogRum.addAction("menu_builder.added_menu_item", { menuId });
    rowItemsFieldArray.append({ dayOfWeek: day, menuItem, position: nextPosition, id: "" });
  };

  const editRowItem = (oldMenuItemId: string, menuItem: MenuBuilderMenuItemType) => {
    datadogRum.addAction("menu_builder.edited_menu_item", { menuId });
    // update all impacted menuItems to their revised ID.
    form.getValues("weeks").forEach((week, weekIndex) => {
      week.meals.forEach((meal, mealIndex) => {
        meal.rows.forEach((row, rowIndex) => {
          row.items.forEach((item, rowItemIndex) => {
            if (item.menuItem.id === oldMenuItemId) {
              form.setValue(`weeks.${weekIndex}.meals.${mealIndex}.rows.${rowIndex}.items.${rowItemIndex}`, {
                ...item,
                menuItem,
              });
            }
          });
        });
      });
    });
  };

  const removeRowItem = (index: number) => {
    datadogRum.addAction("menu_builder.removed_menu_item", { menuId });
    const rowItemId = form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}.items.${index}`).id;
    if (rowItemId) {
      setRowItemsToDelete([...rowItemsToDelete, rowItemId]);
    }
    rowItemsFieldArray.remove(index);
  };

  const replaceRowItems = (day: DayOfWeek, newItems: MenuBuilderMealRowItemType[]) => {
    // mark old items for deletion
    const itemsToDelete = allRowItems.filter(item => item.dayOfWeek === day && item.id).flatMap(item => (item.id ? item.id : []));
    setRowItemsToDelete([...rowItemsToDelete, ...itemsToDelete]);

    // remove old items on the day and add the new ones
    rowItemsFieldArray.replace([
      ...allRowItems.filter(item => item.dayOfWeek !== day),
      ...newItems.map((item, index) => ({ ...item, id: "", position: index, dayOfWeek: day })),
    ]);
  };

  const selectDiningStationTemplate = (appearances: readonly MenuItemAppearanceForMenuBuilderFragment[]) => {
    form.setValue(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}.includeInAutoComplete`, false);

    if (allRowItems.length > 0) {
      setTemplateItemAppearances(appearances);
    } else {
      replaceWithSelectedTemplate(appearances);
    }
  };

  const replaceWithSelectedTemplate = (appearances: readonly MenuItemAppearanceForMenuBuilderFragment[]) => {
    datadogRum.addAction("menu_builder.selected_dining_station_template", { menuId });
    // mark old items for deletion
    const itemsToDelete = allRowItems.flatMap(item => (item.id ? item.id : []));
    setRowItemsToDelete([...rowItemsToDelete, ...itemsToDelete]);

    // replace every day with the template's items
    const items = appearances.map(appearance => {
      return {
        id: null,
        position: appearance.position,
        menuItem: {
          id: appearance.menuItem.id,
          name: appearance.menuItem.name,
          isMissingIngredients: appearance.menuItem.isMissingIngredients,
        },
      };
    });
    const itemsOnEachDay = days.flatMap(dayOfWeek => items.map(item => ({ ...item, dayOfWeek: dayOfWeek })));
    rowItemsFieldArray.replace(itemsOnEachDay);
    setTemplateItemAppearances(null);
    const stationName = form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}.diningStationName`);
    setMessage("success", `Bulk added menu items from ${stationName}`);
  };

  const getRowBorder = () => {
    if (clipboard?.formPath === formPath) {
      return {
        borderWidth: "1px",
        borderStyle: "dashed",
        borderColor: info.main,
      };
    } else if (isSelected) {
      return {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: info.main,
      };
    }
  };

  const copy = () => {
    datadogRum.addAction("menu_builder.copied_row", { menuId });
    setClipboard({
      type: "row",
      formPath,
      items: allRowItems,
      diningStationName: form.getValues(`${formPath}.diningStationName`),
      foodType: form.getValues(`${formPath}.foodType`),
    });
  };

  const paste = () => {
    if (clipboard?.type === "row") {
      datadogRum.addAction("menu_builder.pasted_row", { menuId });
      // mark old items for deletion
      const itemsToDelete = allRowItems.flatMap(item => (item.id ? item.id : []));
      setRowItemsToDelete([...rowItemsToDelete, ...itemsToDelete]);

      const itemsWithoutId = clipboard.items.map(item => ({ ...item, id: null }));
      rowItemsFieldArray.replace(itemsWithoutId);
      form.setValue(`${formPath}.diningStationName`, clipboard.diningStationName);
      form.setValue(`${formPath}.foodType`, clipboard.foodType);
      setReplaceDialogOpen(false);
    }
  };

  const maybePaste = () => {
    if (allRowItems.length === 0) {
      paste();
    } else {
      setReplaceDialogOpen(true);
    }
  };

  const currentRowErrors = errors?.weeks?.[weekFormIndex]?.meals?.[mealFormIndex]?.rows?.[rowFormIndex];
  return (
    <Box
      sx={{ display: "grid", ...getRowBorder() }}
      // toolbar for copy/paste etc
      onClick={e => {
        if (toolbarPosition) {
          setToolbarPosition(undefined);
          setIsSelected(false);
        }
        // only open the toolbar if they click on the leftmost cell
        if (isEditable && e.target instanceof Element && e.target.id === `row-${rowFormIndex}`) {
          datadogRum.addAction("menu_builder.opened_row_toolbar", { menuId });
          setToolbarPosition({ top: e.clientY, left: e.clientX });
          setIsSelected(true);
        }
      }}
    >
      <Box
        id={`row-${rowFormIndex}`}
        sx={{
          backgroundColor: isSelected ? info.lighter : grayBackground,
          width: LEFT_PANEL_CELL_WIDTH,
          minHeight: "200px",
          position: "relative", // for the delete row button
          "&:hover": { cursor: isEditable ? "pointer" : "" },
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: greyscale[300],
        }}
      >
        {isEditable && (
          <IconButton
            sx={{ position: "absolute", right: "0px", top: "4px" }}
            size="small"
            onClick={() => deleteRow(rowFormIndex)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        <Box
          mt={2}
          ml={2}
          width={"216px"}>
          <Controller
            name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}.diningStationName`}
            control={control}
            render={({ field: { ref, ...field } }) => {
              if (isEditable) {
                return (
                  <MenuBuilderDiningStationTemplateSearch
                    onChange={value => field.onChange(value)}
                    onClose={() => {
                      form.setValue(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}.includeInAutoComplete`, true);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...field}
                        label="Dining Station Name"
                        placeholder="e.g. Fueling Station"
                        error={Boolean(currentRowErrors?.diningStationName)}
                        // non-empty helperText to keep input from moving when it has an error
                        helperText={currentRowErrors?.diningStationName ? currentRowErrors?.diningStationName.message : " "}
                      ></TextField>
                    )}
                    onSelect={selectDiningStationTemplate}
                    value={field.value}
                    freeTextValues={getUniqueDiningStationNames(form)}
                  ></MenuBuilderDiningStationTemplateSearch>
                );
              }

              return (
                <TextField
                  {...field}
                  label="Dining Station Name"
                  placeholder="e.g. Fueling Station"
                  // non-empty helperText to keep input from moving when it has an error
                  helperText={currentRowErrors?.diningStationName ? currentRowErrors?.diningStationName.message : " "}
                  InputProps={{ disabled: true }}
                />
              );
            }}
          />
          <Controller
            name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}.foodType`}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                fullWidth
                {...field}
                label="Food Type (optional)"
                placeholder="e.g. Protein"
                disabled={!isEditable} />
            )}
          />
        </Box>
      </Box>
      {days.map((day, index) => {
        const isDayEnabled = !currentWeeksDisabledDays.includes(day);
        const sortedItemsForDay: readonly MenuBuilderMealRowItemIndexedType[] = sortByKey(
          allRowItems.map((item, index) => ({ ...item, index })).filter(item => item.dayOfWeek === day),
          "position"
        );

        if (!isDayEnabled && sortedItemsForDay.length > 0) {
          replaceRowItems(day, []);
        }

        let backgroundColor = background.default;
        if (!isDayEnabled) {
          backgroundColor = greyscale[200];
        } else if (isSelected || selectedDay === day) {
          backgroundColor = info.lighter;
        }

        return (
          <Box
            key={day}
            sx={{
              backgroundColor,
              gridColumn: `${index + 2}`,
              width: DAY_CELL_WIDTH,
              minHeight: DAY_CELL_HEIGHT,
              justifyContent: "center",
              display: "grid",
            }}
          >
            <MenuBuilderMealGridRowDay
              addRowItem={menuItem => addRowItem(day, sortedItemsForDay.length, menuItem)}
              removeRowItem={removeRowItem}
              editRowItem={editRowItem}
              replaceRowItems={newItems => replaceRowItems(day, newItems)}
              rowItemsForDay={sortedItemsForDay}
              formPath={`${formPath}.${index}`}
              isDayEnabled={isDayEnabled}
            />
          </Box>
        );
      })}
      <ConfirmationDialog
        open={templateItemAppearances !== null}
        title="Replace Menu Items"
        message={<Typography>Are you sure you want to replace the menu items on this row with the selected template?</Typography>}
        confirmLabel="Yes"
        variant="containedDestructive"
        onCancel={() => {
          datadogRum.addAction("menu_builder.cancelled_select_dining_station_template", { menuId });
          setTemplateItemAppearances(null);
        }}
        onConfirm={() => replaceWithSelectedTemplate(templateItemAppearances ?? [])}
      />
      <MenuBuilderToolbar
        position={toolbarPosition}
        onClickCopy={copy}
        onClickPaste={maybePaste}
        // prevent pasting into the same meal to avoid duplicate menu items in the same meal menu
        disablePaste={clipboard?.type !== "row" || clipboard.formPath.startsWith(`weeks.${weekFormIndex}.meals.${mealFormIndex}`)}
      />
      <ConfirmationDialog
        open={replaceDialogOpen}
        title="Replace Menu Items"
        message={`Are you sure you want to paste the row? The menu items on the row will be replaced with the new menu items.`}
        onCancel={() => {
          datadogRum.addAction("menu_builder.cancelled_replace_row", { menuId });
          setReplaceDialogOpen(false);
        }}
        onConfirm={paste}
        confirmLabel="Yes"
        variant="containedDestructive"
      />
    </Box>
  );
};
