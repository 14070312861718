import ForumIcon from "@mui/icons-material/Forum";
import { Box, useTheme } from "@mui/material";
import { PrimaryNavLink } from "@teamworksdev/react";
import { NewChip } from "apps/web/src/components/universal/NewChip";
import { useStreamChat } from "apps/web/src/contexts/StreamChatContext";
import React from "react";

// full page path
export const NAV_ORG_MESSAGING = "/org/messaging";

const MessageIcon = () => {
  const { totalUnreadCount } = useStreamChat();
  const {
    palette: { error, common },
  } = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      {totalUnreadCount > 0 && (
        <Box
          sx={{
            height: "9px",
            width: "9px",
            borderRadius: "50%",
            backgroundColor: error.main,
            border: `1.2px solid ${common.white}`,
            position: "absolute",
            top: "-2px",
            right: "-2px",
          }}
        />
      )}
      <ForumIcon />
    </Box>
  );
};

export const messagingLink: PrimaryNavLink = {
  to: NAV_ORG_MESSAGING,
  name: (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
      Messaging
      <NewChip variant="nav" />
    </Box>
  ) as unknown as string,
  // tooltip: "Messaging",
  icon: <MessageIcon />,
};
