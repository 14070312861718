import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { GptRecipeFragment, useGetPossibleGptRecipeByNameMutation } from "../../../../types";
import { GenerateWithGPTContent } from "./GenerateWithGPTContent";
import { GenerateWithGPTFormSchema, GenerateWithGPTFormType, generateWithGPTFormDefaultValues } from "./GenerateWithGPTForm";
import { GPTLoadingContent } from "../GPTLoadingContent";
import { GptFeature } from "apps/server/src/app/services/integrations/openai/gpt/promptBuilder";

interface GenerateWithGPTDialogProps {
  open: boolean;
  onClose: () => void;
  onGenerateComplete: (gptRecipe: GptRecipeFragment, closeModal: () => void) => void;
  onError: (error: any, type: GptFeature) => void;
  initialName?: string;
  initialServingYield?: number;
}

const getCanSaveTooltips = ({ formState: { errors } }: UseFormReturn<GenerateWithGPTFormType>): string[] => {
  const tooltips: string[] = [];
  const nameError = errors.name?.message;
  if (nameError) {
    tooltips.push(nameError);
  }

  const servingError = errors.servingYield?.message;
  if (servingError) {
    tooltips.push(servingError);
  }

  return tooltips;
};

export const GenerateWithGPTDialog = ({
  open,
  onClose,
  onGenerateComplete,
  onError,
  initialName,
  initialServingYield,
}: GenerateWithGPTDialogProps) => {
  const form = useForm<GenerateWithGPTFormType>({
    defaultValues: generateWithGPTFormDefaultValues(initialName, initialServingYield),
    resolver: zodResolver(GenerateWithGPTFormSchema),
    mode: "all",
  });

  const { formState } = form;
  const { isDirty, touchedFields } = formState;

  const [generateRecipeByName, { loading: generatingRecipe }] = useGetPossibleGptRecipeByNameMutation({
    onCompleted: data => {
      onGenerateComplete(data.getPossibleGPTRecipeByName, onClose);
    },
    onError: e => onError(e, "recipeGenerator"),
  });

  const handleClose = () => {
    if (generatingRecipe) {
      return;
    }
    onClose();
  };

  const canSaveTooltips = getCanSaveTooltips(form);
  const isFormTouched = isDirty || Object.keys(touchedFields).length > 0;
  const isGenerateDisabled = canSaveTooltips.length !== 0 || !isFormTouched || generatingRecipe;

  const handleGenerate = form.handleSubmit(({ name, servingYield }) => {
    generateRecipeByName({ variables: { input: { name, servingYield } } });
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title="Generate Recipe" onClose={handleClose} />
      <DialogContent sx={{ pb: 12 }}>
        {generatingRecipe ? <GPTLoadingContent actionText="Generating" /> : <GenerateWithGPTContent form={form} />}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <TWItemizedTooltip title="Fix the following before Generating:" items={canSaveTooltips}>
            <span>
              <Button onClick={handleGenerate} disabled={isGenerateDisabled}>
                Generate
              </Button>
            </span>
          </TWItemizedTooltip>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
