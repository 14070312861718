import { AnthropometryEntry } from "@notemeal/db/entities";
import { AnthropometrySnapshot, SexType } from "./types/AnthropometryTypes";

// These ratios are slightly altered above true
// To ensure there and back again conversion from an imperial front end and a metric database
// You break it, you buy it - Chris
export const KG_PER_LB = 0.4535937;
export const LB_PER_KG = 2.20465;

export const CM_PER_INCH = 2.54;
export const INCH_PER_CM = 0.393701;

export const applyWeightTarget = (anthropometrySnapshot: AnthropometrySnapshot, weightTarget?: number | null): AnthropometrySnapshot => {
  if (weightTarget) {
    if (anthropometrySnapshot.leanBodyMass) {
      return {
        ...anthropometrySnapshot,
        weight: weightTarget,
        leanBodyMass: (anthropometrySnapshot.leanBodyMass / anthropometrySnapshot.weight) * weightTarget,
      };
    } else {
      return { ...anthropometrySnapshot, weight: weightTarget };
    }
  } else {
    return anthropometrySnapshot;
  }
};

// same function as above utilizing metric properties
export const applyMetricWeightTarget = (
  anthropometrySnapshot: AnthropometrySnapshot,
  weightTargetInKg?: number | null
): AnthropometrySnapshot => {
  if (weightTargetInKg) {
    // weightTarget can be asserted due to above check
    const imperialWeightTarget = measurementConversionToImperialNonNull(true, weightTargetInKg, "weight");
    const imperialWeight = measurementConversionToImperialNonNull(true, anthropometrySnapshot.weightInKg, "weight");
    if (anthropometrySnapshot.leanBodyMassInKg) {
      // leanBody mass can be asserted due to above check
      const imperialLeanBodyMass = measurementConversionToImperialNonNull(true, anthropometrySnapshot.leanBodyMassInKg, "weight");
      return {
        ...anthropometrySnapshot,
        weight: imperialWeightTarget,
        weightInKg: weightTargetInKg,
        leanBodyMass: (imperialLeanBodyMass / imperialWeight) * imperialWeightTarget,
        leanBodyMassInKg: (anthropometrySnapshot.leanBodyMassInKg / anthropometrySnapshot.weightInKg) * weightTargetInKg,
      };
    } else {
      return { ...anthropometrySnapshot, weight: weightTargetInKg };
    }
  } else {
    return anthropometrySnapshot;
  }
};

export const weightToKg = (weight: number) => weight / LB_PER_KG;
export const heightToCm = (height: number) => height * CM_PER_INCH;

export const getLeanBodyMass = (weight: number, percentBodyFat: number | null) =>
  percentBodyFat ? weight - weight * (percentBodyFat / 100) : null;

export const getPercentBodyFat = (weight: number, leanBodyMass: number | null) =>
  leanBodyMass ? ((weight - leanBodyMass) / weight) * 100 : null;

export const sexToText = (sex: SexType): string => {
  switch (sex) {
    case "male":
      return "Male";
    case "female":
      return "Female";
    default:
      return "Other";
  }
};

type MeasurementConversionParam = "weight" | "length";

export const measurementConversionToMetric = (
  isMetric: boolean,
  value: number | null | undefined,
  prop: MeasurementConversionParam
): number | null => {
  if (value === null || value === undefined) {
    return null;
  }
  if (isMetric) {
    return value;
  }
  if (prop === "length") {
    return value * CM_PER_INCH;
  } else {
    return value * KG_PER_LB;
  }
};

export const measurementConversionToMetricNonNull = (isMetric: boolean, value: number, prop: MeasurementConversionParam): number => {
  if (isMetric) {
    return value;
  }
  if (prop === "length") {
    return value * CM_PER_INCH;
  } else {
    return value * KG_PER_LB;
  }
};

// should this have a non-nullable version?
export const measurementConversionToImperial = (
  isMetric: boolean,
  value: number | null,
  prop: MeasurementConversionParam
): number | null => {
  if (!isMetric || value === null) {
    return value;
  }
  if (prop === "length") {
    return value * INCH_PER_CM;
  } else {
    return value * LB_PER_KG;
  }
};

export const measurementConversionToImperialNonNull = (isMetric: boolean, value: number, prop: MeasurementConversionParam): number => {
  if (!isMetric) {
    return value;
  }
  if (prop === "length") {
    return value * INCH_PER_CM;
  } else {
    return value * LB_PER_KG;
  }
};

export const getWeightUnitForLocale = (isMetricLocale: boolean) => (isMetricLocale ? "kg" : "lb");
export const getHeightUnitForLocale = (isMetricLocale: boolean) => (isMetricLocale ? "cm" : "in");

export const formatImperialHeight = (height: number) => {
  const roundedHeight = Math.floor(height);

  const feet = Math.floor(roundedHeight / 12);
  const inches = roundedHeight - 12 * feet;
  return `${feet}'${inches}"`;
};

export const formatImperialWeight = (weight: number) => roundToHundredthsFloor(weight) + " lb";
export const formatMetricWeight = (weight: number) => roundToHundredthsFloor(weight) + " kg";
export const formatMetricHeight = (height: number) => roundToHundredthsFloor(height) + " cm";

// creates whole number if x.00
export const roundToHundredthsFloor = (input: number) => Math.floor(input * 100) / 100;

// creates whole number if x.00
export const maybeRoundToHundredthsFloor = (input: number | null): number | null => (input ? Math.floor(input * 100) / 100 : null);

export const convertAnthropometryEntryToSnapshot = (entry: AnthropometryEntry): AnthropometrySnapshot => {
  const { weight, height, leanBodyMass, percentBodyFat, sex, isMetric } = entry;

  return {
    weight: measurementConversionToImperialNonNull(isMetric, weight, "weight"),
    height: measurementConversionToImperial(isMetric, height, "length"),
    leanBodyMass: measurementConversionToImperial(isMetric, leanBodyMass, "weight"),
    weightInKg: measurementConversionToMetricNonNull(isMetric, weight, "weight"),
    heightInCm: measurementConversionToMetric(isMetric, height, "length"),
    leanBodyMassInKg: measurementConversionToMetric(isMetric, leanBodyMass, "weight"),
    percentBodyFat,
    sex,
    age: null,
  };
};
