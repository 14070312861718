import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import AthleteSearchBar from "apps/web/src/components/Athlete/SearchBar";
import { useUser } from "apps/web/src/utils/tokens";
import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom-v5-compat";
import { FeatureFlagsFragment, Role, useMyFeatureFlagsQuery } from "../../../types";
import { getLink } from "../../NavUtils";
import { getOrgUserDetails } from "../OrgUtils";
import { BreadCrumbs } from "./BreadCrumbs";

export interface NavLink {
  to: string;
  name: string;
  pageHeader?: string;
  icon?: JSX.Element;
  allowedRoles?: Role[];
  allowedFeatureFlag?: keyof FeatureFlagsFragment;
  requiresAdmin?: boolean;
  requiresSuperuser?: boolean;
}
export interface SecondaryNavLayoutProps {
  name?: string;
  current: NavLink;
  breadCrumbs?: NavLink[];
  tabs: NavLink[];
  enableAthleteSearch: boolean;
}

const emptyTab = getLink("", "");

const getCurrentTab = (pathname: string, tabs: NavLink[]) => {
  // TODO - write unit tests to ensure paths that have guids/dates at end like athlete/meal-plans/* or kitchen/menu-schedule/* can always select the correct tab
  // reversing so staff can navigate to "Organizations Sync" (tab 2) without getting stuck on "Organizations" (tab 1)
  let initialTab = tabs
    .slice()
    .reverse()
    .find(tab => pathname.includes(tab.to));

  if (!initialTab) {
    initialTab = tabs.length > 0 ? tabs[0] : emptyTab;
  }

  return initialTab;
};

export const SecondaryNavLayout = ({ name, current, breadCrumbs, tabs, enableAthleteSearch }: SecondaryNavLayoutProps) => {
  const user = useUser();
  const { data: featureFlagsData } = useMyFeatureFlagsQuery();
  const { hasOrgMembership, roles: userRoles, isAdmin, isSuperuser } = getOrgUserDetails(user);
  const { name: currentName, to: currentTo, pageHeader } = current;
  const roleFilteredTabs = tabs.filter(({ allowedRoles, allowedFeatureFlag, requiresAdmin, requiresSuperuser }) => {
    const passesRoleCheck = !allowedRoles || (userRoles && allowedRoles.some(role => userRoles.includes(role))); // Subtle, but passing role includes no roles to check for. This allows for combos of both roles and admin.
    const passesFeatureFlagCheck = !allowedFeatureFlag || !!featureFlagsData?.myFeatureFlags[allowedFeatureFlag];
    const passesAdminCheck = !requiresAdmin || !!isAdmin;
    const passesSuperuserCheck = !requiresSuperuser || !!isSuperuser;
    return passesRoleCheck && passesFeatureFlagCheck && passesAdminCheck && passesSuperuserCheck;
  });
  const { pathname } = useLocation();
  const currentTab = getCurrentTab(pathname, roleFilteredTabs);
  const hasTabs = roleFilteredTabs.length > 0;
  const showTabs = roleFilteredTabs.length > 1; // Don't show tabs unless there are at least 2, i.e. user has a choice.
  const showAthleteSearch = hasOrgMembership && userRoles?.includes("dietitian") && enableAthleteSearch;

  // Staff orgs and restaurants don't send in any tabs but we need to show their dynamic name.
  // Otherwise, if only one tab we should show tab name rather than parent name.
  const secondaryNavTitle = pageHeader ? pageHeader : !hasTabs || showTabs ? currentName : currentTab.name;

  useEffect(() => {
    // 'name' is for the static name of an area when the current NavLink contains dynamic strings, like athlete names or team names
    const title = (name ? `${name} | ` : "").concat(currentName).concat(currentTab.name ? ` | ${currentTab.name}` : "");
    document.title = title;
  }, [name, currentName, currentTab]);

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2, height: "100%", overflowY: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          {breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs.concat(current)} />}
          <Typography variant={breadCrumbs ? "h2" : "h1"}>{secondaryNavTitle}</Typography>
        </Box>
        {showAthleteSearch && (
          <Box sx={{ mt: -3 }}>
            <AthleteSearchBar />
          </Box>
        )}
      </Box>
      {showTabs ? (
        <Tabs value={currentTab} onChange={() => {}}>
          {roleFilteredTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.name}
              value={tab}
              to={`${currentTo}/${tab.to}`}
              component={Link} />
          ))}
        </Tabs>
      ) : (
        <Divider />
      )}

      <Box sx={{ mt: showTabs ? 2 : 0, flex: 1, display: "flex", flexDirection: "column", gap: 2, overflowY: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
};
