import { default as pino } from "pino";

const pinoLogger = pino({
  level: process.env["NODE_ENV"] !== "test" ? "info" : "error",
  prettyPrint: process.env["NODE_ENV"] === "development" || process.env["NODE_ENV"] === "test",
});

type _LogArgs =
  | LogArgs_GraphQL
  | LogArgs_Auth
  | LogArgs_REST
  | LogArgs_Audit
  | LogArgs_ApiRequest
  | LogArgs_Misc
  | LogArgs_CBORD_LOG
  | LogArgs_Job
  | LogArgs_HookRequest
  | LogArgs_SQL
  | LogArgs_URL
  | LogArgs_ApplicationAPIRequest
  | LogArgs_NotificationError
  | LogArgs_Notification
  | LogArgs_NotificationSent
  | LogArgs_Trace;

type LogArgs = _LogArgs & {
  level?: never;
};

type LogArgs_Base = {
  user?: {
    id?: string;
    username?: string;
    // TODO: What else here?
  };
  requestId: string;
  searchContext?: string;
};

interface LogArgs_Misc {
  type: "misc";
  message: string;
}

interface LogArgs_CBORD_LOG {
  type: "CBORD_LOG";
  error?: string;
  body?: Record<string, unknown>;
  cbordFetchType?: "RecipeItem" | "PurchasedItem" | "ServiceMenu" | "FoodTradeItem";
  oid?: number;
  rawResponse?: unknown;
}

interface LogArgs_Trace {
  type: "trace";
  data: Record<string, unknown>;
}

interface LogArgs_Job {
  type: "job";
  jobStatus:
    | "adding"
    | "processing"
    | "completed"
    | "failed"
    | "stalled"
    | "error"
    | "debug"
    | "waiting"
    | "active"
    | "progress"
    | "removed"
    | "paused"
    | "resumed"
    | "drained"
    | "cleaned"
    | "setup"
    | "trace";
}

type LogArgs_SQL = {
  type: "sql";
} & (
  | {
      query: string;
      parameters?: any[];
      error?: string;
    }
  | { message: string }
);

interface LogArgs_GraphQL extends LogArgs_Base {
  type: "graphql";
  query: string | null | undefined;
  errors?: readonly any[]; // TODO: Is this correct?
}

interface LogArgs_Auth extends LogArgs_Base {
  type: "auth";
  url: string;
}

interface LogArgs_REST extends LogArgs_Base {
  type: "rest";
  url: string;
  message: string;
}

interface LogArgs_URL extends LogArgs_Base {
  type: "url";
  url: string;
  message: string;
  originalUrl: string;
  baseUrl: string;
  method: string;
}

// TODO: Should this remain separate or fall into one of the above categories?
export interface LogArgs_Audit extends LogArgs_Base {
  type: "audit";
  url: string;
  message: string;
  statusCode: number;
  action: string;
  requestId: string;
}

// TODO: Should this remain separate or fall into one of the above categories?
export interface LogArgs_ApiRequest extends LogArgs_Base {
  type: "apiRequest";
  url: string;
  statusCode: number;
  event: string;
  clientId?: string;
  requestId: string;
}

export interface LogArgs_HookRequest {
  type: "hookRequest";
  requestId: string;
  message: string;
  body: Record<string, unknown>;
  url?: string;
}

export interface LogArgs_ApplicationAPIRequest {
  type: "applicationAPI";
  error: string;
  teamworksOrgId: number;
  body?: Record<string, unknown>;
}

interface LogArgs_NotificationError {
  type: "notificationError";
  error: string;
  user?: {
    id?: string;
    username?: string;
  };
}
interface LogArgs_NotificationSent {
  type: "notificationSent";
  success: string;
  user?: {
    id?: string;
    username?: string;
  };
}

// TODO: What else should be added here?
interface LogArgs_Notification {
  type: "notification";
  notificationCount: number;
  user?: {
    id?: string;
    username?: string;
  };
}

type LogFn = <T extends LogArgs>(args: T) => void;

export type Logger = {
  debug: LogFn;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
};

type LogLevel = "debug" | "info" | "warn" | "error";

const makeLogFn =
  (level: LogLevel): LogFn =>
  args => {
    pinoLogger[level]({ ...args, level });
  };

export const logger: Logger = {
  debug: makeLogFn("debug"),
  info: makeLogFn("info"),
  warn: makeLogFn("warn"),
  error: makeLogFn("error"),
};
