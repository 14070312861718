import BarChartIcon from "@mui/icons-material/BarChartRounded";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { AdminRoute, RoleRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { ClientCredentialsPage } from "./ClientCredentialsPage";
import { ImportAnthropometryPage } from "./ImportAnthropometryPage";
import { ImportAthletePage } from "./ImportAthletePage";
import { ImportFoodPage } from "./ImportFoodPage";
import { SelfServiceDietitianPage } from "./SelfServiceDietitianPage";

// full page path
export const NAV_ORG_DATA = "/org/data";

// local child paths
export const NAV_DATA_ATHLETES = "athletes";
export const NAV_DATA_ANTHROPOMETRY = "anthropometry";
export const NAV_DATA_FOODS = "foods";
export const NAV_DATA_CLIENT_CREDENTIALS = "client-credentials";
export const NAV_DATA_SELF_SERVICE_ONBOARDING = "self-service-onboarding";

// full child paths
export const NAV_ORG_DATA_ATHLETES = `${NAV_ORG_DATA}/${NAV_DATA_ATHLETES}`;

export const dataLink = {
  to: NAV_ORG_DATA,
  name: "Data",
  icon: <BarChartIcon />,
};

const getSecondaryNavLayoutProps = (): SecondaryNavLayoutProps => ({
  current: dataLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Athletes",
      to: NAV_DATA_ATHLETES,
    },
    {
      name: "Anthropometry",
      to: NAV_DATA_ANTHROPOMETRY,
    },
    {
      name: "Foods",
      to: NAV_DATA_FOODS,
    },
    {
      name: "Client Credentials",
      to: NAV_DATA_CLIENT_CREDENTIALS,
      allowedFeatureFlag: "clientCredentials",
      requiresAdmin: true,
    },
    {
      name: "Onboarding",
      to: NAV_DATA_SELF_SERVICE_ONBOARDING,
      allowedFeatureFlag: "selfServiceOnboarding",
      requiresAdmin: true,
    },
  ],
});

export const DataRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...getSecondaryNavLayoutProps()} />}>
      <Route element={<RoleRoute roles={["dietitian"]} />}>
        <Route index element={<ImportAthletePage />} />
        <Route path={NAV_DATA_ATHLETES} element={<ImportAthletePage />} />
        <Route path={NAV_DATA_ANTHROPOMETRY} element={<ImportAnthropometryPage />} />
        <Route path={NAV_DATA_FOODS} element={<ImportFoodPage />} />
        <Route
          path={NAV_DATA_CLIENT_CREDENTIALS}
          element={
            <AdminRoute>
              <ClientCredentialsPage />
            </AdminRoute>
          }
        />
        <Route path={NAV_DATA_SELF_SERVICE_ONBOARDING} element={<SelfServiceDietitianPage />} />
      </Route>
    </Route>
  </Routes>
);
