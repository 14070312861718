import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, SxProps } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { ChangeEvent, ReactNode, createRef, useState } from "react";
import { useSnackbar } from "../../Snackbar/SnackbarContext";

const dropZoneStyle = {
  backgroundColor: "info.light",
  borderColor: "info.main",
};

const inputHoverStyle = {
  "&:hover": {
    cursor: "pointer",
    ...dropZoneStyle,
  },
};

interface ImageFileDragAndDropProps {
  onUpload: (url: string) => void;
  children?: ReactNode;
  sx?: SxProps;
  loading?: boolean;
  hasError?: boolean;
  errorMessage?: ReactNode;
  disabled?: boolean;
}

const ImageFileDragAndDrop = ({ onUpload, children, sx, loading, hasError, errorMessage, disabled }: ImageFileDragAndDropProps) => {
  const { setMessage } = useSnackbar();

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setInDropZone(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
    setInDropZone(true);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    await convertFileToUrl(validateFile(files));
    setInDropZone(false);
  };

  const handleDragExit = (event: React.DragEvent<HTMLDivElement>) => {
    setInDropZone(false);
  };

  const [inDropZone, setInDropZone] = useState(false);
  const inputRef = createRef<HTMLInputElement>();
  const openFileFinder = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const convertFileToUrl = async (file: File | null): Promise<void> => {
    if (file) {
      const url = await getUrlPromiseFromFile(file);
      onUpload(url);
    }
  };

  const getUrlPromiseFromFile = (file: File) => {
    return new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result.toString());
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const validateFile = (files: FileList | null): File | null => {
    if (files) {
      if (files.length !== 1) {
        setMessage("error", `Please import one image at a time.`);
        return null;
      }
      const validatedFile = Array.from(files).filter(file => {
        if (file.type.startsWith("image")) {
          return true;
        } else {
          setMessage("error", `'${file.name}' is not a image file and will not be uploaded.`);
          return false;
        }
      });
      return validatedFile[0];
    }
    return null;
  };

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragExit={handleDragExit}
      onDragLeave={handleDragExit}
      onClick={openFileFinder}
      sx={{
        ...sx,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid lightgray",
        borderRadius: 0.5,
        flexDirection: "column",
        mb: 0.5,
        ...(!disabled && inputHoverStyle),
        ...(inDropZone && !disabled && dropZoneStyle),
      }}
    >
      {loading ? (
        <Loading />
      ) : hasError ? (
        <>
          <InfoOutlined color="error" />
          {errorMessage}
        </>
      ) : (
        <>
          {children}
          <CloudUploadIcon sx={disabled ? { opacity: 0.5 } : {}} fontSize="large" />
        </>
      )}
      <input
        disabled={disabled}
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        accept="image/*"
        onChange={async (event: ChangeEvent<HTMLInputElement>) => await convertFileToUrl(validateFile(event.target.files))}
      />
    </Box>
  );
};

export default ImageFileDragAndDrop;
