import MoreVertIcon from "@mui/icons-material/MoreVert";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import { serializeDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import { AiAugmentedMenuItemName } from "apps/web/src/components/MenuItem/Dialog/AiAugmentedMenuItemName";
import { onCreate, onEdit } from "apps/web/src/components/MenuItem/Dialog/utils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { Score } from "libs/shared/ui/src/lib/Score/Score";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../../../componentLibrary";
import MenuItemCreateDialog from "../../../../components/MenuItem/Dialog/MenuItemDialog";
import { MenuItemState } from "../../../../components/MenuItem/reducer";
import TablePage from "../../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../../components/universal/TablePage/DefaultHeader";
import {
  MenuItemTableFragment,
  useCreateReusableMenuItemMutation,
  useDeleteReusableMenuItemMutation,
  useEditReusableMenuItemMutation,
  useEditReusableMenuItemPartialMutation,
  useMenuItemsTableQuery,
  useMyScoringSystemQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import MenuItemEditDialog, { OnSaveMenuItemArgs } from "../../../../views/MenuItems/Dialog/Edit/MenuItemEditDialog";
import { MenuItemDuplicateDialog } from "../../../../views/MenuItems/Dialog/MenuItemDuplicateDialog";
import MenuItemRatingsDialog from "../../../../views/MenuItems/Dialog/MenuItemRatings/MenuItemRatingsDialog";
import { getAverageRating, getNumberOfRatings } from "../../../../views/MenuItems/Dialog/MenuItemRatings/utils";

interface MoreInfo {
  moreAnchorElement: HTMLElement;
  menuItem: MenuItemTableFragment;
}

export const MenuItemsPage = () => {
  const [moreInfo, setMoreInfo] = useState<MoreInfo | null>(null);

  const paginationHooks = useOffsetPagination();
  const { query, limit, offset, queryText, onChangeQueryText } = paginationHooks;

  const {
    data,
    loading: loadingMenuItems,
    refetch,
  } = useMenuItemsTableQuery({
    variables: { query, input: { limit, offset } },
    fetchPolicy: "network-only",
  });
  const rows = data?.menuItemOffsetConnection.edges ?? [];

  const { data: scoringSystemData, loading: loadingScoringSystem } = useMyScoringSystemQuery();
  const scoringSystem = scoringSystemData?.myScoringSystem;
  const hasScoring = scoringSystem !== null;

  const { setMessage } = useSnackbar();

  const [duplicateMenuItemId, setDuplicateMenuItemId] = useState<string | null>(null);
  const [displayCreateMenuItemDialog, setDisplayCreateMenuItemDialog] = useState(false);
  const [createReusableMenuItem, { loading: savingCreate }] = useCreateReusableMenuItemMutation({
    onError: e => setMessage("error", e.message),
    onCompleted: () => {
      refetch();
      setMessage("success", `Created menu item`);
      setDisplayCreateMenuItemDialog(false);
      setDuplicateMenuItemId(null);
    },
  });
  const handleCreate = (state: MenuItemState) => {
    onCreate({ state, createReusableMenuItem });
  };

  const [editMenuItemId, setEditMenuItemId] = useState<string | null>(null);
  const [editReusableMenuItem, { loading: savingEdit }] = useEditReusableMenuItemMutation({
    onError: e => {
      if (e.message.includes("duplicate key value violates unique constraint")) {
        setMessage(
          "error",
          "We cannot save the new revision of this menu item at this time since it is outdated. Please close the edit dialog and try again."
        );
      } else {
        setMessage("error", "Failed to save, please try again.");
      }
    },
    onCompleted: () => {
      refetch();
      setEditMenuItemId(null);
      setMessage("success", `Edited menu item`);
    },
  });
  const [editReusableMenuItemPartial, { loading: savingEditPartial }] = useEditReusableMenuItemPartialMutation({
    onError: e => setMessage("error", e.message),
    onCompleted: () => {
      refetch();
      setEditMenuItemId(null);
      setMessage("success", `Edited menu item`);
    },
  });

  const handleEdit = (state: OnSaveMenuItemArgs) => {
    onEdit({ editReusableMenuItem, editReusableMenuItemPartial, state });
  };

  const [deleteableMenuItem, setDeleteableMenuItem] = useState<MenuItemTableFragment | null>(null);
  const [deleteReusableMenuItem] = useDeleteReusableMenuItemMutation({
    onCompleted: () => {
      refetch();
      setDeleteableMenuItem(null);
      setMessage("success", `Deleted menu item`);
    },
  });
  const handleDelete = (menuItem: MenuItemTableFragment) => {
    deleteReusableMenuItem({
      variables: {
        input: {
          after: serializeDateTime(new Date()),
          menuItemId: menuItem.id,
        },
      },
    });
  };

  const [menuItemForRatings, setMenuItemForRatings] = useState<MenuItemTableFragment | null>(null);

  return (
    <>
      <TablePage
        header={
          <TablePageDefaultHeader
            title="Menu Item"
            queryText={queryText}
            onChangeQueryText={onChangeQueryText}
            onCreate={() => setDisplayCreateMenuItemDialog(true)}
          />
        }
        tableHeaderRow={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Portion Name</TableCell>
            <TableCell>Average Rating</TableCell>
            <TableCell>Number of Add-ons</TableCell>
            {hasScoring && <TableCell>Score</TableCell>}
            <TableCell padding="checkbox">Actions</TableCell>
          </TableRow>
        }
        tableBodyRows={
          <>
            {rows.map(row => {
              const {
                id,
                isMissingIngredients,
                name,
                servingName,
                history: { ratingCounts },
                choices,
                score,
                aiAugmented,
              } = row;
              const { isAiGenerated, isAiTranscribed } = aiAugmented;
              return (
                <TableRow
                  id={id}
                  key={id}
                  hover
                  onClick={e => {
                    setEditMenuItemId(id);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell scope="row">
                    {isMissingIngredients ? (
                      <Box sx={{ display: "flex" }}>
                        <TWTooltip title="" subcopy="Menu item is missing ingredients! This will affect food log data from menu orders.">
                          <WarningIcon fontSize="small" color="warning" />
                        </TWTooltip>
                        <Box sx={{ display: "flex", alignItems: "center", pl: 0.5 }}>{name}</Box>
                      </Box>
                    ) : aiAugmented ? (
                      <AiAugmentedMenuItemName
                        name={name}
                        sx={{ fontSize: "14px" }}
                        isAiGenerated={isAiGenerated}
                        isAiTranscribed={isAiTranscribed}
                      />
                    ) : (
                      name
                    )}
                  </TableCell>
                  <TableCell>{servingName}</TableCell>
                  <TableCell>{getNumberOfRatings(ratingCounts) !== 0 && `${getAverageRating(ratingCounts)} / 5`}</TableCell>
                  <TableCell>{choices.length}</TableCell>
                  {hasScoring && (
                    <TableCell>
                      {score && <Score
                        scoreValue={score.value}
                        scoringSystem={scoringSystem}
                        size="lg"
                        sx={{ gap: 0.5 }} />}
                    </TableCell>
                  )}
                  <TableCell sx={{ display: "flex" }}>
                    <IconButton
                      size="small"
                      onClick={e => {
                        e.stopPropagation();
                        setMoreInfo({ moreAnchorElement: e.currentTarget, menuItem: row });
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            <Menu
              anchorEl={moreInfo?.moreAnchorElement}
              open={!!moreInfo}
              onClose={() => {
                setMoreInfo(null);
              }}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {moreInfo && (
                <>
                  <MenuItem
                    dense
                    disabled={getNumberOfRatings(moreInfo.menuItem.history.ratingCounts) === 0}
                    onClick={() => {
                      setMenuItemForRatings(moreInfo.menuItem);
                      setMoreInfo(null);
                    }}
                  >
                    View Ratings
                  </MenuItem>
                  <MenuItem
                    dense
                    onClick={() => {
                      setDuplicateMenuItemId(moreInfo.menuItem.id);
                      setMoreInfo(null);
                    }}
                  >
                    Duplicate
                  </MenuItem>
                  <MenuItem
                    dense
                    onClick={() => {
                      setDeleteableMenuItem(moreInfo.menuItem);
                      setMoreInfo(null);
                    }}
                  >
                    Delete
                  </MenuItem>
                </>
              )}
            </Menu>
          </>
        }
        paginationHooks={paginationHooks}
        loading={loadingMenuItems || loadingScoringSystem}
        total={data?.menuItemOffsetConnection.pageInfo.total || 0}
      />
      {displayCreateMenuItemDialog && (
        <MenuItemCreateDialog
          open={displayCreateMenuItemDialog}
          title="Create Reusable Menu Item"
          variant="MenuItem"
          onClose={() => setDisplayCreateMenuItemDialog(false)}
          saving={savingCreate}
          onSave={handleCreate}
        />
      )}
      {editMenuItemId && (
        <MenuItemEditDialog
          menuItemId={editMenuItemId}
          open={!!editMenuItemId}
          onClose={() => setEditMenuItemId(null)}
          saving={savingEdit || savingEditPartial}
          onSave={handleEdit}
          onDuplicate={() => setDuplicateMenuItemId(editMenuItemId)}
        />
      )}
      {duplicateMenuItemId && (
        <MenuItemDuplicateDialog
          originalMenuItemId={duplicateMenuItemId}
          open={!!duplicateMenuItemId}
          onClose={() => setDuplicateMenuItemId(null)}
          saving={savingCreate}
          onSave={handleCreate}
        />
      )}
      {menuItemForRatings && (
        <MenuItemRatingsDialog
          menuItem={menuItemForRatings}
          open={!!menuItemForRatings}
          onClose={() => setMenuItemForRatings(null)} />
      )}
      {deleteableMenuItem && (
        <ConfirmationDialog
          open={!!deleteableMenuItem}
          title="Delete Menu Item"
          message="Are you sure that you would like to delete the menu item?"
          onCancel={() => setDeleteableMenuItem(null)}
          onConfirm={() => handleDelete(deleteableMenuItem)}
          variant="containedDestructive"
        />
      )}
    </>
  );
};
