import { RecipeFullFragment } from "../../types";
import { servingUnitsToState } from "../Serving/utils";
import { RecipeFormState } from "./Form/utils";

export const recipeToFormState = ({
  ingredients,
  images,
  servings,
  steps,
  note,
  cho,
  pro,
  fat,
  cookTimeInMinutes,
  prepTimeInMinutes,
  mealTypes,
  score,
  foodCategory,
  isAiGenerated,
  isAiTranscribed,
  ...recipe
}: RecipeFullFragment): RecipeFormState => {
  const serving = servings[0];
  const perRecipeYield = serving.perRecipeYield ? serving.perRecipeYield : 1;
  const defaultAmount = serving.defaultAmount;
  const hasManualMacros = cho !== null && pro !== null && fat !== null;
  const macrosScaleFactor = defaultAmount / perRecipeYield;

  return {
    ...recipe,
    steps: steps && steps.length ? steps : [""],
    note: note || "",
    ingredients: ingredients.map(i => ({ ...i, expanded: false })),
    choInput: cho !== null ? String(cho * macrosScaleFactor) : "",
    proInput: pro !== null ? String(pro * macrosScaleFactor) : "",
    fatInput: fat !== null ? String(fat * macrosScaleFactor) : "",
    prepTimeInMinutesInput: prepTimeInMinutes !== null ? String(prepTimeInMinutes) : "",
    cookTimeInMinutesInput: cookTimeInMinutes !== null ? String(cookTimeInMinutes) : "",
    manualMacros: hasManualMacros,
    exchangeTypes: hasManualMacros ? recipe.exchangeTypes : null,
    serving: {
      ...serving,
      units: servingUnitsToState(serving),
      defaultAmountInput: String(serving.defaultAmount),
      perRecipeYield: perRecipeYield,
      perRecipeYieldInput: String(servings[0].perRecipeYield),
    },
    images: images.map(i => i),
    mealTypes: mealTypes || [],
    edited: false,
    scoreValue: score ? score.value : null,
    foodCategory: foodCategory ? foodCategory.category : null,
    unmatchedIngredients: {
      unmatchedName: [],
      unmatchedServings: [],
    },
    isAiGenerated,
    isAiTranscribed,
  };
};
