import { Checkbox, FormControlLabel, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch } from "react";
import { Role } from "../../../../types";
import RoleMultiSelect from "./RoleMultiSelect";
import { EditOrgMembershipFormAction, OrgMembershipFormState } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkBoxDiv: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: theme.spacing(2),
    },
    email: { marginRight: theme.spacing(2), width: `calc(50% - ${theme.spacing(1)})` },
    phoneNumber: { width: `calc(50% - ${theme.spacing(1)})` },
    error: {
      color: theme.palette.error.main,
    },
  })
);

interface EditOrgMembershipFormProps {
  state: OrgMembershipFormState;
  dispatch: Dispatch<EditOrgMembershipFormAction>;
  isTeamworksUser: boolean;
}

const EditOrgMembershipForm = ({ state, dispatch, isTeamworksUser }: EditOrgMembershipFormProps) => {
  const classes = useStyles();
  const hasAthleteRoleWithOtherRoles = state.roles.includes("athlete") && state.roles.length > 1;

  const rolesToShow: Role[] =
    state.roles.length === 0
      ? ["dietitian", "chef", "foodmanager", "kiosk", "athlete"]
      : state.roles.includes("athlete")
      ? state.roles
      : ["dietitian", "chef", "foodmanager", "kiosk"];

  return (
    <>
      <TextField
        className={classes.email}
        margin="dense"
        label="Email"
        onChange={e => dispatch({ type: "CHANGE_EMAIL", payload: e.target.value })}
        value={state.email}
        disabled={isTeamworksUser}
      />
      <TextField
        className={classes.phoneNumber}
        margin="dense"
        label="Phone #"
        onChange={e => dispatch({ type: "CHANGE_PHONE_NUMBER", payload: e.target.value })}
        value={state.phoneNumber}
        disabled={isTeamworksUser}
      />
      {isTeamworksUser && (
        <Typography variant="body1Medium">
          This user is linked to a Teamworks user. Please edit the phone number and/or email in Teamworks and re-run profile-sync in order
          to make these changes.
        </Typography>
      )}
      <RoleMultiSelect
        roles={rolesToShow}
        selectedRoles={state.roles}
        setSelectedRoles={roles => dispatch({ type: "CHANGE_ROLES", payload: roles })}
      />
      {hasAthleteRoleWithOtherRoles && (
        <Typography variant="body1Medium" className={classes.error}>
          Warning a user with the athlete role and any other role can put the app into a bad state. If you are trying to make an athlete do
          it in the app on the team screen
        </Typography>
      )}
      <div className={classes.checkBoxDiv}>
        <FormControlLabel
          value="admin"
          control={
            <Checkbox
              checked={state.isAdmin}
              onChange={e =>
                dispatch({
                  type: "CHANGE_IS_ADMIN",
                  payload: !state.isAdmin,
                })
              }
            />
          }
          label="Is Admin"
          labelPlacement="top"
        />
        <FormControlLabel
          value="active"
          control={
            <Checkbox
              checked={state.isActive}
              disabled={!state.accountActivated}
              onChange={e =>
                dispatch({
                  type: "CHANGE_IS_ACTIVE",
                  payload: !state.isActive,
                })
              }
            />
          }
          label="Is Active"
          labelPlacement="top"
        />
      </div>
    </>
  );
};

export default EditOrgMembershipForm;
