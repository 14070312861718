import CloseIcon from "@mui/icons-material/Close";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import SaveIcon from "@mui/icons-material/Save";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { newServingAmount } from "@notemeal/shared/ui/ServingAmount/utils";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import Loading from "@notemeal/shared/ui/global/Loading";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import { sortByKey } from "@notemeal/utils/sort";
import classNames from "classnames";
import React, { ReactNode, createElement, useCallback, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  EditFullMealOptionFragment,
  EditFullMealOptionPendingFragment,
  ExchangeAmountFragment,
  FullExchangeSetFragment,
  FullServingAmountFragment,
  MealType,
  RecipeFullFragment,
  useEditMealOptionLocalMutation,
  useEditMealOptionPendingLocalMutation,
  useRemoveMealOptionLocalMutation,
  useRemoveMealOptionPendingLocalMutation,
  useSelectMealOptionMutation,
  useShareMealOptionPendingLocalMutation,
} from "../../../types";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { SearchMode } from "./SearchMode";
import { SuggestionMode } from "./SuggestionMode";
import { MealOptionMode, MealOptionModeComponentProps } from "./mode";
import {
  getMealOptionInput,
  getMealOptionPendingInput,
  getRemoveMealOptionPendingVariables,
  getRemoveMealOptionVariables,
  shouldShowMealOptionNoteBadge,
} from "./utils";

const MODES = [SearchMode, SuggestionMode];

export const MEAL_OPTION_SLIDE_WIDTH = "350px";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardDiv: {
      height: "100%",
      gridRowStart: 1,
      gridColumnStart: 1,
    },
    loading: {
      gridColumnStart: 1,
      gridRowStart: 1,
      gridRowEnd: 3,
      gridColumnEnd: 3,
      // height: 637, // If you update this you need to update AddNew.tsx and ServingAmounts/ChipList.tsx#height
    },
    cardActionRoot: {
      padding: 0,
      display: "block",
      gridColumnStart: 2,
      gridRowStart: 1,
    },
    totals: {
      gridColumnStart: 1,
      gridRowStart: 1,
      height: "fit-content",
    },
    chipListFront: {
      gridColumn: "1 / 3",
      marginTop: 0,
    },
    mealOptionFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    bottomNavActionButton: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
      transition: "0.3s",
    },
    backCardDiv: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

const styles = {
  backCardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: (theme: Theme) => theme.spacing(2),
  },
  bottomNavigation: {
    backgroundColor: "transparent",
  },
};

const AnimatedCard = animated(Card);

export type MealOptionPreview = EditFullMealOptionFragment | EditFullMealOptionPendingFragment;

interface MealOptionEditProps {
  renderTotals: (servingAmounts: readonly FullServingAmountFragment[]) => ReactNode;
  mealTemplateId: string;
  mealTemplateTypename: "MacroMealTemplate" | "ExchangeMealTemplate";
  mealType: MealType;
  mealOption: MealOptionPreview;
  selected: boolean;
  isNew: boolean;
  targetMacros: Macros;
  targetExchangeAmounts?: readonly ExchangeAmountFragment[] | null;
  exchangeSet?: FullExchangeSetFragment | null;
  additionalModes?: readonly MealOptionMode[];
}

export const EditMealOption = ({
  renderTotals,
  selected,
  isNew,
  mealType,
  mealOption,
  mealTemplateId,
  mealTemplateTypename,
  targetMacros,
  additionalModes,
  targetExchangeAmounts,
  exchangeSet,
}: MealOptionEditProps) => {
  const {
    palette: { warning, common },
  } = useTheme();
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const { isMetricLocale } = useLocaleContext();

  let modes = additionalModes ? [...MODES, ...additionalModes] : MODES;
  const showUKMPOSuggestions = useHasFeature("ukMealPlanOptionSuggestions");
  // If the locale is metric and the UK MPO suggestions feature is disabled, remove the Suggestions mode
  if (isMetricLocale && !showUKMPOSuggestions) {
    modes = modes.filter(mode => mode.name !== "Suggestions");
  }

  const [currentMode, setCurrentMode] = useState<MealOptionMode>(SearchMode);

  useEffect(() => {
    setCurrentMode(SearchMode);
  }, [selected]);

  const [recipeDialogOpen, setRecipeDialogOpen] = useState(false);

  // internal state so user changes show up immediately, but we debounce mutations to avoid spamming the server on every keystroke
  const [mealOptionNote, setMealOptionNote] = useState(mealOption.note || "");
  const [mealOptionName, setMealOptionName] = useState(mealOption.name || "");
  const [servingAmounts, setServingAmounts] = useState(mealOption.servingAmounts || []);
  const debouncedMealOptionNote = useDebounce(mealOptionNote, 500);
  const debouncedMealOptionName = useDebounce(mealOptionName, 500);
  const debouncedServingAmounts = useDebounce(servingAmounts, 500);

  const [flipped, setFlipped] = useState(false);
  const { opacity } = useSpring({
    opacity: Number(flipped),
  });
  const inverseOpacity = opacity.to(o => 1 - o);
  const transform = opacity.to(o => `rotateY(${180 * o}deg)`);

  // we save as changes are made to avoid losing data if the user navigates away
  const [selectMealOption] = useSelectMealOptionMutation();
  const [removeMealOption] = useRemoveMealOptionLocalMutation();
  const [removeMealOptionPending] = useRemoveMealOptionPendingLocalMutation();
  const [editMealOption] = useEditMealOptionLocalMutation();
  const [editMealOptionPending] = useEditMealOptionPendingLocalMutation();
  const [shareMealOptionPending] = useShareMealOptionPendingLocalMutation();

  const isPendingMealOption = mealOption.__typename === "MealOptionPending";

  const handleSelectOption = () =>
    selectMealOption({
      variables: {
        mealTemplateId,
        type: mealTemplateTypename,
        mealOptionId: mealOption.id,
      },
    });

  const handleShare = () =>
    shareMealOptionPending({
      variables: { mealTemplateId, mealOptionPendingId: mealOption.id },
      update: () => setMessage("success", "Shared meal option with athlete"),
    });

  const handleRemoveOption = useCallback(() => {
    const { __typename, id } = mealOption;
    if (__typename === "MealOption") {
      removeMealOption(getRemoveMealOptionVariables(mealTemplateId, mealTemplateTypename, id));
    } else {
      removeMealOptionPending(getRemoveMealOptionPendingVariables(mealTemplateId, mealTemplateTypename, id));
    }
  }, [mealTemplateId, mealTemplateTypename, mealOption, removeMealOption, removeMealOptionPending]);

  const handleServingAmountChange = (editedServingAmounts: readonly FullServingAmountFragment[]) => {
    const servingAmounts = sortByKey(editedServingAmounts, "position").map((servingAmount, i) => ({ ...servingAmount, position: i + 1 }));

    if (mealOption.__typename === "MealOption") {
      editMealOption(getMealOptionInput({ ...mealOption, servingAmounts }));
    } else {
      editMealOptionPending(getMealOptionPendingInput({ ...mealOption, servingAmounts }));
    }
  };

  const handleChangeMealOptionName = (name: string) => {
    setMealOptionName(name);
  };

  const handleNoteChange = (note: string) => {
    setMealOptionNote(note);
  };

  const handleDebouncedNoteChange = (note: string) => {
    if (mealOption.__typename === "MealOption") {
      editMealOption(getMealOptionInput({ ...mealOption, note }));
    } else {
      editMealOptionPending(getMealOptionPendingInput({ ...mealOption, note }));
    }
  };

  const handleDebouncedMealOptionNameChange = (name: string) => {
    if (mealOption.__typename === "MealOption") {
      editMealOption(getMealOptionInput({ ...mealOption, name }));
    } else {
      editMealOptionPending(getMealOptionPendingInput({ ...mealOption, name }));
    }
  };

  useEffect(() => {
    if (!selected && flipped) {
      setFlipped(false);
    }
  }, [selected, flipped, setFlipped]);

  useEffect(() => {
    if (debouncedMealOptionNote !== (mealOption.note || "")) {
      handleDebouncedNoteChange(debouncedMealOptionNote);
    }
    // intentionally incomplete dependency list because we only want to mutate when our internal state updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMealOptionNote]);

  useEffect(() => {
    if (debouncedMealOptionName !== (mealOption.name || "")) {
      handleDebouncedMealOptionNameChange(debouncedMealOptionName);
    }
    // intentionally incomplete dependency list because we only want to mutate when our internal state updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMealOptionName]);

  useEffect(() => {
    if (debouncedServingAmounts !== (mealOption.servingAmounts || [])) {
      handleServingAmountChange(debouncedServingAmounts);
    }
    // intentionally incomplete dependency list because we only want to mutate when our internal state updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedServingAmounts]);

  const handleCreateRecipe = (recipe: RecipeFullFragment) => {
    const serving = recipe.servings[0];
    setServingAmounts([
      newServingAmount(
        {
          ...serving,
          foodOrRecipe: recipe,
        },
        1
      ),
    ]);
    setMessage("success", `Success! Created recipe "${recipe.name}"`);
  };

  const isNewContent = (
    <>
      <CardContent
        sx={{
          display: "grid",
          rowGap: 2,
          gridTemplateColumns: "260px 40px",
          gridTemplateRows: "100px 393px",
          paddingBottom: 0,
        }}
      >
        <div className={classes.loading}>
          <Loading progressSize="md" />
        </div>
      </CardContent>
      <div className={classes.mealOptionFooter} />
    </>
  );

  const mealOptionFront = (
    <CardContent
      sx={{
        display: "grid",
        rowGap: 2,
        gridTemplateColumns: "260px 40px",
        gridTemplateRows: `100px ${isPendingMealOption ? "330px" : "393px"}`,
        paddingBottom: 0,
      }}
      onClick={() => isNew || handleSelectOption()}
    >
      <CardActions classes={{ root: classes.cardActionRoot }}>
        <IconButton
          sx={{ color: "common.black" }}
          onClick={e => {
            if (mealOption.isAutosaving) {
              return;
            }
            e.stopPropagation();
            handleRemoveOption();
          }}
        >
          {mealOption.isAutosaving ? <Loading progressSize="xs" /> : <CloseIcon />}
        </IconButton>
      </CardActions>
      {createElement<MealOptionModeComponentProps>(currentMode.component, {
        renderTotals,
        totalsClassName: classes.totals,
        contentClassName: classes.chipListFront,
        mealTemplateTypename,
        mealType,
        mealOption: { ...mealOption, servingAmounts },
        selected: true,
        targetMacros,
        targetExchangeAmounts,
        exchangeSet,
        recipeDialogOpen,
        onCloseRecipeDialog: () => setRecipeDialogOpen(false),
        onCreateRecipe: handleCreateRecipe,
        onEditServingAmounts: sa => setServingAmounts(sa),
        toSearchMode: () => setCurrentMode(SearchMode),
        onChangeMealOptionName: handleChangeMealOptionName,
      })}
    </CardContent>
  );

  const mealOptionBack = (
    <CardContent onClick={() => isNew || handleSelectOption()} sx={styles.backCardContent}>
      <TextField
        label="Name"
        placeholder="Meal Option Name"
        value={mealOptionName}
        onChange={e => handleChangeMealOptionName(e.currentTarget.value)}
      />
      <TextField
        label="Notes"
        placeholder="Let the athlete know any additional info about this meal option"
        value={mealOptionNote}
        onChange={e => handleNoteChange(e.target.value)}
        fullWidth
        multiline
        rows={5}
      />
    </CardContent>
  );

  const getMealOptionFooter = (isFoodsSide: boolean) => (
    <BottomNavigation
      value={null}
      showLabels
      className={classes.mealOptionFooter}
      sx={styles.bottomNavigation}>
      <BottomNavigationAction
        classes={{ root: classes.bottomNavActionButton }}
        label={isFoodsSide ? "Name & Notes" : "To Foods"}
        icon={
          <Badge variant="dot" invisible={!shouldShowMealOptionNoteBadge(mealOptionNote, flipped)}>
            <FlipToFrontIcon />
          </Badge>
        }
        disabled={isFoodsSide === flipped}
        onClick={() => setFlipped(!flipped)}
      />
      <BottomNavigationAction
        classes={{ root: classes.bottomNavActionButton }}
        label="As Recipe"
        icon={<SaveIcon />}
        disabled={isFoodsSide === flipped}
        onClick={() => setRecipeDialogOpen(true)}
      />
      {modes
        .filter(mode => mode.name !== currentMode?.name)
        .map(mode => (
          <BottomNavigationAction
            key={mode.name}
            classes={{ root: classes.bottomNavActionButton }}
            label={mode.name}
            icon={mode.icon}
            onClick={() => setCurrentMode(mode)}
          />
        ))}
    </BottomNavigation>
  );

  const pendingMealOptionHeader = (
    <Box
      sx={({ spacing }: Theme) => ({
        p: spacing(2),
        backgroundColor: warning.lighter,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      })}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Tooltip
          title={`This meal option is not currently shared with the athlete because food items could not scale within 20% accuracy of the athlete's macro targets. Please click "Share" if you would like to share with the athlete.`}
        >
          <WarningIcon sx={{ color: "#FFCE00" }} fontSize="small" />
        </Tooltip>
        <Typography>Not shared with athlete</Typography>
      </Box>
      <Button
        variant="outlined"
        size="small"
        sx={{ backgroundColor: "common.white" }}
        onClick={handleShare}>
        Share
      </Button>
    </Box>
  );

  return (
    <AnimatedCard
      sx={{
        border: `2px solid ${selected ? common.black : common.white}`,
        my: 2,
        mx: 1,
        flexGrow: 0,
        flexShrink: 0,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        "&:hover": {
          cursor: selected ? "default" : "pointer",
          backgroundColor: selected ? "common.white" : "greyscale.50",
        },
      }}
      style={{ transform }}
    >
      <animated.div style={{ opacity: inverseOpacity, zIndex: inverseOpacity }} className={classes.cardDiv}>
        {isNew ? (
          isNewContent
        ) : (
          <>
            {isPendingMealOption && pendingMealOptionHeader}
            {mealOptionFront}
            {getMealOptionFooter(true)}
          </>
        )}
      </animated.div>
      <animated.div style={{ opacity, zIndex: opacity, transform }} className={classNames(classes.cardDiv, classes.backCardDiv)}>
        {isNew ? (
          isNewContent
        ) : (
          <>
            {mealOptionBack}
            {getMealOptionFooter(false)}
          </>
        )}
      </animated.div>
    </AnimatedCard>
  );
};
