/*
 ************************************************************************************
 * Copyright (C) 2019 Openbravo S.L.U.
 * Licensed under the Apache Software License version 2.0
 * You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to  in writing,  software  distributed
 * under the License is distributed  on  an  "AS IS"  BASIS,  WITHOUT  WARRANTIES  OR
 * CONDITIONS OF ANY KIND, either  express  or  implied.  See  the  License  for  the
 * specific language governing permissions and limitations under the License.
 ************************************************************************************
 */

import { USB } from "./usb";
import { PrinterType } from "./PrinterType";

const EPSONTML90: PrinterType = {
  name: "EPSON TM L90",
  createWebDevice: () =>
    new USB({
      vendorId: 0x04b8,
      productId: 0x0202,
    }),
};

const EPSONTML100: PrinterType = {
  name: "EPSON TM L100",
  createWebDevice: () =>
    new USB({
      vendorId: 0x04b8,
      productId: 0x0202,
    }),
};

const BrotherQL820NWBAirprint: PrinterType = {
  name: "Brother QL-820NWB (airprint)",
  createWebDevice: () => {
    throw new Error("Try selecting non-airprint option");
  },
};

const BROTHERQL820NWB: PrinterType = {
  name: "Brother QL-820NWB",
  createWebDevice: () =>
    new USB({
      vendorId: 0x04f9,
      productId: 0x209d,
    }),
};

const EPSONTMT88VI: PrinterType = {
  name: "EPSON TM T88VI",
  createWebDevice: () =>
    new USB({
      vendorId: 0x04b8,
      productId: 0x0202,
    }),
};

const STARSML300: PrinterType = {
  name: "Star SM-L300",
  createWebDevice: () =>
    new USB({
      vendorId: 0x0519,
      productId: 0x0045,
    }),
};

const STARSP700: PrinterType = {
  name: "Star SP700",
  createWebDevice: () =>
    new USB({
      vendorId: 0x0519,
      productId: 0x0001,
    }),
};

export { EPSONTML90, EPSONTML100, BrotherQL820NWBAirprint, BROTHERQL820NWB, EPSONTMT88VI, STARSML300, STARSP700 };
