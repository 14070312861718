import { createStyles, makeStyles } from "@mui/styles";
import { ASPECT_16_9 } from "./utils";

interface ImageWithLockedAspectRatioProps {
  imageUrl: string;
  borderRadius?: string;
  onClick?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      //this styling doesn't handle an aspect ratio > 16:9 nicely yet
      position: "absolute",
      width: "100%",
      top: " 50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    aspectRatioBox169: {
      paddingTop: `${(1 / ASPECT_16_9) * 100}%`,
      position: "relative",
      height: "0px",
      overflow: "hidden",
    },
  })
);

const ImageWithLockedAspectRatio = ({ imageUrl, onClick, borderRadius }: ImageWithLockedAspectRatioProps) => {
  const classes = useStyles();

  return (
    <div className={classes.aspectRatioBox169} style={{ borderRadius: borderRadius }} onClick={onClick}>
      <img className={classes.image} src={imageUrl} alt="image" />
    </div>
  );
};

export default ImageWithLockedAspectRatio;
