import { sortByKey } from "@notemeal/utils/sort";
import { Image } from "../utils";

export const IMAGE_WIDTH = 200;

export const incrementPosition = (position: number, displayImages: Image[]): Image[] => {
  const image1ToSwap = displayImages.find(image => image.position === position);
  const image2ToSwap = displayImages.find(image => image.position === position + 1);
  if (image1ToSwap && image2ToSwap) {
    const image1: Image = {
      url: image1ToSwap.url,
      position: image2ToSwap.position,
      id: image1ToSwap.id,
    };

    const image2: Image = {
      url: image2ToSwap.url,
      position: image1ToSwap.position,
      id: image2ToSwap.id,
    };

    const filteredImages = displayImages.filter(image => image.position !== position && image.position !== position + 1);
    const images = [image1, image2, ...filteredImages];
    return [...sortByKey(images, "position")];
  } else {
    return displayImages;
  }
};

export const decrementPosition = (position: number, displayImages: Image[]): Image[] => {
  const image1ToSwap = displayImages.find(image => image.position === position);
  const image2ToSwap = displayImages.find(image => image.position === position - 1);
  if (image1ToSwap && image2ToSwap) {
    const image1: Image = {
      url: image1ToSwap.url,
      position: image2ToSwap.position,
      id: image1ToSwap.id,
    };

    const image2: Image = {
      url: image2ToSwap.url,
      position: image1ToSwap.position,
      id: image2ToSwap.id,
    };

    const filteredImages = displayImages.filter(image => image.position !== position && image.position !== position - 1);
    const images = [image1, image2, ...filteredImages];
    return [...sortByKey(images, "position")];
  } else {
    return displayImages;
  }
};
