import { SimplePaletteColorOptions, ThemeOptions } from "@mui/material";
import { greyscaleLight } from "../Colors/Greyscale";
import { muiAutocomplete } from "./Autocomplete";
import { muiButton } from "./Button";
import { muiCard } from "./Card";
import { muiFab } from "./Fab";
import { muiInputBase } from "./InputBase";
import { muiOutlinedInput } from "./OutlinedInput";
import { muiSelect } from "./Select";
import { muiSwitch } from "./Switch";
import { muiTextField } from "./TextField";
import { muiTooltip } from "./Tooltip";

export const componentsBasedOnTheme = (themeOptions: ThemeOptions) => {
  const mode = themeOptions.palette?.mode || "light";
  const greyscale = themeOptions.palette?.greyscale || greyscaleLight;
  const textPrimary = themeOptions.palette?.text?.primary || "";
  const textSecondary = themeOptions.palette?.text?.secondary || "";
  const info = themeOptions.palette?.info as SimplePaletteColorOptions;

  return {
    MuiButton: muiButton,
    MuiCard: muiCard,
    MuiCheckbox: {
      defaultProps: {
        color: "info",
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: info.main,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          borderColor: greyscale[200],
          borderTop: "none",
        },
      },
    },
    MuiFab: muiFab,
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: mode === "light" ? greyscale[500] : greyscale[25],
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: greyscale[200],
            "&:hover": {
              backgroundColor: greyscale[300],
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: greyscale[200],
            "&:hover": {
              backgroundColor: greyscale[300],
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: greyscale[200],
            "&:hover": {
              backgroundColor: greyscale[300],
            },
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "info",
      },
    },
    MuiSlider: {
      defaultProps: {
        color: "info",
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: info.main,
          },
          "&.Mui-completed": {
            color: info.main,
          },
        },
      },
    },
    MuiSwitch: muiSwitch,
    MuiTab: {
      styleOverrides: {
        root: {
          ...(themeOptions.components?.MuiTab?.styleOverrides?.root as {}),
          "&.Mui-selected": {
            color: textPrimary,
          },
          "&:hover": {
            color: textPrimary,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ...(themeOptions.components?.MuiTableHead?.styleOverrides?.root as {}),
          //color: mode === "light" ? greyscale[500] : greyscale[25],
          backgroundColor: greyscale[100],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          ...(themeOptions.components?.MuiTabs?.styleOverrides?.root as {}),
          borderBottomColor: greyscale[300],
        },
        indicator: {
          backgroundColor: textSecondary,
        },
      },
    },
    MuiTextField: muiTextField,
    MuiTooltip: muiTooltip,
    MuiInputBase: muiInputBase,
    MuiOutlinedInput: muiOutlinedInput,
    MuiSelect: muiSelect,
    MuiAutocomplete: muiAutocomplete,
    MuiTypography: {
      styleOverrides: {
        root: {
          color: textPrimary,
        },
      },
    },
  };
};
