import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { formatKcalOffset } from "@notemeal/shared/ui/MacroProtocol/utils";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import useInfiniteCursorConnectionScroll from "apps/web/src/components/universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import {
  AssignFromMealPlanTemplateAthleteFragment,
  AssignFromMealPlanTemplateFragment,
  useAssignFromMealPlanTemplateCursorConnectionQuery,
} from "apps/web/src/types";
import classnames from "classnames";
import React, { Dispatch, useCallback, useState } from "react";
import MealPlanTemplateInfiniteScroll from "./MealPlanTemplateInfiniteScroll";
import { AssignFromMealPlanTemplateAction, AssignFromMealPlanTemplateState } from "./reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderBox: {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: "4px",
      height: "500px",
    },
    searchTemplates: {
      width: "500px",
    },
    textfield: { padding: theme.spacing(1) },
    header: { paddingBottom: theme.spacing(2) },
    selectGoal: {
      width: "400px",
    },
    selectTemplate: {
      display: "flex",
      justifyContent: "space-around",
      marginRight: theme.spacing(1),
    },
    goals: { marginLeft: theme.spacing(1) },
  })
);

interface SearchableMealPlanTemplatesProps {
  state: AssignFromMealPlanTemplateState;
  dispatch: Dispatch<AssignFromMealPlanTemplateAction>;
  athlete: AssignFromMealPlanTemplateAthleteFragment;
}
const SearchableMealPlanTemplates = ({ athlete, state, dispatch }: SearchableMealPlanTemplatesProps) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const searchResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useAssignFromMealPlanTemplateCursorConnectionQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) => ({
        variables: {
          pagination: { cursor, limit },
          searchText: debouncedSearchTerm,
          sortAscending: true,
        },
      }),
      [debouncedSearchTerm]
    ),
    queryKey: "allMealPlanTemplatesForDietitianCursorConnection",
    edgesAreEqual: useCallback((template1: AssignFromMealPlanTemplateFragment, template2: AssignFromMealPlanTemplateFragment) => {
      return template1.id === template2.id;
    }, []),
    limit: 25,
  });

  const goals = state.selectedTemplate?.macroProtocol.calorieBudget?.goals;

  return (
    <div className={classes.selectTemplate}>
      <div>
        <Typography
          variant="h3"
          color="textSecondary"
          className={classes.header}>
          Select Meal Plan Template to Copy From
        </Typography>

        <div className={classnames(classes.borderBox, classes.searchTemplates)}>
          <div className={classes.textfield}>
            <TextField
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {searchResults.loading ? <CircularProgress size={24} /> : <SearchIcon fontSize="small" />}
                  </InputAdornment>
                ),
              }}
              placeholder={"Search Templates"}
              fullWidth
            />
          </div>
          <MealPlanTemplateInfiniteScroll
            athlete={athlete}
            onClickItem={template => dispatch({ type: "CHANGE_SELECTED_TEMPLATE", payload: template })}
            selectedTemplate={state.selectedTemplate}
            searchResults={searchResults}
          />
        </div>
      </div>
      {state.selectedTemplate ? (
        <div className={classes.goals}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={classes.header}>
            Select Goal
          </Typography>
          <div className={classnames(classes.selectGoal, classes.borderBox)}>
            <List>
              {goals ? (
                goals.map(g => (
                  <ListItem
                    key={g.id}
                    button
                    onClick={() => dispatch({ type: "CHANGE_SELECTED_GOAL", payload: g })}>
                    <ListItemAvatar>
                      <Radio checked={g.id === state.selectedGoal?.id} />
                    </ListItemAvatar>
                    <ListItemText primary={g.name} secondary={`${g.goalType.name} | ${formatKcalOffset(g.kcalOffset)}`} />
                  </ListItem>
                ))
              ) : (
                <ListItem
                  key={"no-goal"}
                  button
                  onClick={() => dispatch({ type: "CHANGE_SELECTED_GOAL", payload: null })}>
                  <ListItemAvatar>
                    <Radio checked={true} />
                  </ListItemAvatar>
                  <ListItemText primary={"No Goal"} secondary={``} />
                </ListItem>
              )}
            </List>
          </div>
        </div>
      ) : (
        <div className={classes.selectGoal} />
      )}
    </div>
  );
};

export default SearchableMealPlanTemplates;
