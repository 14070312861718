import { Box, useTheme } from "@mui/material";
import { NutritionWhiteIcon } from "@notemeal/shared/ui/global/Icons";
import { PrimaryNav } from "@teamworksdev/react";
import { useReportingUserIdentify } from "apps/web/src/utils/reporting/useReportingUserIdentiy";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { useLogout, useUser } from "../../../utils/tokens";
import { DevRouter } from "../Dev/DevRouter";
import { getOrgUserDetails } from "../OrgUtils";
import {
  DEFAULT_USER_NAME,
  OrgAndStaffRouterProps,
  getStaffPrimaryNavLinks,
  staffAdditionalSettingsLinks,
  staffPrimaryNavTeam,
} from "../PrimaryNavUtils";
import { StaffRoute } from "../ProtectedRoutes";
import { FoodManagementRouter } from "./FoodManagement/FoodManagementRouter";
import { FormsAndScoresRouter } from "./FormsAndScores/FormsAndScoresRouter";
import { MealPlanManagementRouter } from "./MealPlanManagement/MealPlanManagementRouter";
import { OrgRouter } from "./Org/OrgRouter";
import { OrgManagementRouter } from "./OrgManagement/OrgManagementRouter";
import { RestaurantRouter } from "./Restaurant/RestaurantRouter";
import { RestaurantManagementRouter } from "./RestaurantManagement/RestaurantManagementRouter";
import { SettingsRouter } from "./Settings/SettingsRouter";

export const StaffRouter = ({ orgs, onOrgChange }: OrgAndStaffRouterProps) => {
  useReportingUserIdentify({ forStaff: true });
  const {
    palette: { primary },
  } = useTheme();
  const logout = useLogout();
  const user = useUser();
  const orgUserDetails = getOrgUserDetails(user);
  const { userName } = orgUserDetails;

  const logo = <NutritionWhiteIcon sx={{ alignSelf: "center", width: 40, height: 40 }} viewBox="0 0 20 20" />;

  return (
    <Box sx={{ flex: 1, display: "flex" }}>
      <PrimaryNav
        colors={{ bottom: primary.main, top: "black" }}
        links={getStaffPrimaryNavLinks()}
        app="nutrition"
        logo={logo}
        team={staffPrimaryNavTeam}
        teams={orgs}
        user={{ name: userName || DEFAULT_USER_NAME }}
        additionalSettingsLinks={staffAdditionalSettingsLinks}
        onTeamChange={onOrgChange}
        onLogout={logout}
      />
      <Box sx={{ flex: 1, overflowY: "hidden", backgroundColor: "black", pt: 1 }}>
        <Box
          sx={{
            flex: 1,
            height: "100%",
            backgroundColor: ({ palette: { mode } }) => (mode === "light" ? "white" : "black"),
            borderTopLeftRadius: 12,
            p: 3,
          }}
        >
          <Routes>
            <Route element={<StaffRoute />}>
              <Route
                path="/*"
                index
                element={<OrgManagementRouter />} />
              <Route path="org-management/*" element={<OrgManagementRouter />} />
              <Route path="food-management/*" element={<FoodManagementRouter />} />
              <Route path="restaurant-management/*" element={<RestaurantManagementRouter />} />
              <Route path="meal-plan-management/*" element={<MealPlanManagementRouter />} />
              <Route path="forms-and-scores/*" element={<FormsAndScoresRouter />} />
              <Route path="dev/*" element={<DevRouter org={false} />} />
              <Route path="restaurants/:restaurantId/*" element={<RestaurantRouter />} />
              <Route path="orgs/:orgId/*" element={<OrgRouter />} />
              <Route path="settings/*" element={<SettingsRouter />} />
            </Route>
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};
