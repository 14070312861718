import { checkForSchemaVersionUpdate } from "@notemeal/shared/ui/utils/schemaVersion";
import { UserOrgMembership } from "@notemeal/shared/ui/utils/tokens";
import { Team as TWTeam } from "@teamworksdev/react";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom-v5-compat";
import { useCookieContext } from "../../contexts/Cookie";
import { clearReportingUser } from "../../reporting/reporting";
import { useApiCredentialsAndSchemaQuery, useCanBeStaffQuery } from "../../types";
import { setLocalStorageValue } from "../../utils/localStorage";
import { REFRESH_TOKEN_PAYLOAD_AND_HEADER_COOKIE, refresh, useUser } from "../../utils/tokens";
import { ActivateUserPage } from "../NoAuth/ActivateUserPage";
import { ApiDocsPage } from "../NoAuth/ApiDocsPage";
import { NoAuthNavLayout } from "../NoAuth/NoAuthNavLayout";
import { NAV_API_DOCS } from "../NoAuth/NoAuthPaths";
import { AppUpdateCheckPage } from "./AppUpdateCheckPage";
import { NAV_MOBILE_WELCOME, NAV_ROOT, NAV_SWITCH_ORG, NAV_UPDATE_CHECK } from "./AuthPaths";
import { MobileWelcomePage } from "./MobileWelcomePage";
import { useMemberships, useSwitchOrgs } from "./Org/OrgMembershipUtils";
import { OrgApp } from "./OrgApp";
import { getFallbackUri, getOrgUserDetails } from "./OrgUtils";
import { STAFF_ORG_ID, staffOrg } from "./PrimaryNavUtils";
import { StaffRouter } from "./Staff/StaffRouter";
import { SwitchOrgPage } from "./SwitchOrgPage";

export const AuthRouter = () => {
  const navigate = useNavigate();
  const user = useUser();
  const orgUserDetails = getOrgUserDetails(user);
  const { isStaff } = orgUserDetails;
  const memberships = useMemberships();
  const { getCookieValue } = useCookieContext();
  const { data: canBeStaffData } = useCanBeStaffQuery();
  const { data: schemaData, loading: schemaLoading } = useApiCredentialsAndSchemaQuery({ fetchPolicy: "network-only" });

  const canBeStaff = !!canBeStaffData?.canBeStaff;
  const isOrCanBeStaff = isStaff || canBeStaff;
  const canSwitchOrgs = !!(memberships && ((isOrCanBeStaff && memberships.length) || memberships.length > 1));
  const orgFromStaff = isOrCanBeStaff ? [staffOrg] : [];
  const orgsFromMemberships = memberships?.map(({ org: { id, name } }: UserOrgMembership): TWTeam => ({ id, name })) || [];
  const orgs = canSwitchOrgs ? [...orgFromStaff, ...orgsFromMemberships] : [];

  const hasRefreshToken = !!getCookieValue(REFRESH_TOKEN_PAYLOAD_AND_HEADER_COOKIE);
  const fallbackUri = getFallbackUri(hasRefreshToken, orgUserDetails);

  useEffect(() => {
    if (schemaData && !schemaLoading) {
      const update = checkForSchemaVersionUpdate({
        serverSchemaVersion: schemaData.webVersion,
        clientSchemaVersion: process.env.REACT_APP_WEB_SCHEMA_VERSION,
      });
      if (update === "majorUpdate" || update === "minorUpdate") {
        navigate(NAV_UPDATE_CHECK);
      }
      setLocalStorageValue("nixAppId", schemaData.nixAppId);
      setLocalStorageValue("nixAppKey", schemaData.nixAppKey);
    }
  }, [schemaData, schemaLoading, navigate]);

  const switchOrgs = useSwitchOrgs();
  const handleOrgChange = async (orgId: string) => {
    clearReportingUser();
    if (orgId === STAFF_ORG_ID) {
      await refresh();
    } else {
      await switchOrgs(orgId);
    }
    navigate(NAV_ROOT);
  };

  return (
    <>
      <Routes>
        <Route path="/org/*" element={<OrgApp orgs={orgs} onOrgChange={handleOrgChange} />} />
        <Route path="/staff/*" element={<StaffRouter orgs={orgs} onOrgChange={handleOrgChange} />} />
        <Route path={NAV_UPDATE_CHECK} element={<AppUpdateCheckPage />} />
        <Route path={NAV_SWITCH_ORG} element={<SwitchOrgPage />} />
        <Route element={<NoAuthNavLayout />}>
          <Route path={NAV_MOBILE_WELCOME} element={<MobileWelcomePage />} />
          <Route path="/activate/:activateToken" element={<ActivateUserPage />} />
        </Route>
        <Route path={NAV_API_DOCS} element={<ApiDocsPage />} />
        <Route path="*" element={<Navigate to={fallbackUri} replace />} />
      </Routes>
    </>
  );
};
