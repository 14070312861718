import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Switch, Typography, styled } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { RecipePrintDialog } from "apps/web/src/views/Recipes/components/Printing/RecipePrintDialog";
import React, { useReducer, useState } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { EditRecipeFullInput, EditRecipePartialInput, RecipeFullFragment } from "../../../types";
import DiscardChangesDialog from "../../universal/DiscardChangesDialog";
import Form from "../Form/RecipeForm";
import { canSaveRecipeFormTooltips } from "../Form/utils";
import { editRecipeReducer, editRecipeStateToInput, recipeToEditState } from "./utils";

const RevisionDiv = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: spacing(),
}));

const revisionIconStyle = {
  paddingRight: 1,
};

interface RecipeEditDialogContentProps {
  open: boolean;
  onClose: () => void;
  onEditFull: (input: EditRecipeFullInput) => void;
  onEditPartial: (input: EditRecipePartialInput) => void;
  recipe: RecipeFullFragment;
  onDuplicate: () => void;
  forStaff?: boolean;
  readonly?: boolean;
}

export const RecipeEditDialogContent = ({
  open,
  onClose,
  onEditFull,
  onEditPartial,
  recipe,
  onDuplicate,
  forStaff,
  readonly,
}: RecipeEditDialogContentProps) => {
  const [state, dispatch] = useReducer(editRecipeReducer, recipeToEditState(recipe));
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();

  const canSaveTooltips = canSaveRecipeFormTooltips(state.form);

  const handleSave = () => {
    if (!readonly) {
      const maybeInput = editRecipeStateToInput(state);
      if (maybeInput?.type === "full") {
        onEditFull(maybeInput.input);
      } else if (maybeInput?.type === "partial") {
        onEditPartial(maybeInput.input);
      }
    }
  };

  const cancelRevision = () => dispatch({ type: "CANCEL_REVISION" });

  const handleDuplicate = () => {
    onClose();
    onDuplicate();
  };

  React.useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(state.form.edited);
  }, [state.form.edited, setBrowserBackAndRefreshWarningEnabled]);

  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const handleClose = () => {
    if (state.form.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const [printOpen, setPrintOpen] = useState(false);

  return (
    <Dialog
      PaperProps={{
        sx: { minHeight: "calc(100% - 20px)" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle title={readonly ? "View Recipe" : "Edit Recipe"} onClose={handleClose} />
      <DialogContent sx={{ pt: 0 }}>
        <Form
          state={state.form}
          dispatch={dispatch}
          sx={{ flexGrow: 1 }}
          disabled={readonly}
          onAiGenerateClicked={null} />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="outlined" onClick={() => setPrintOpen(true)}>
            Print
          </Button>
          <Button variant="outlined" onClick={handleDuplicate}>
            Duplicate
          </Button>
          <FormControlLabel
            label={forStaff ? "Share with orgs" : "Share with athletes"}
            labelPlacement="end"
            checked={state.form.isShared}
            onChange={() =>
              dispatch({
                type: "CHANGE_IS_SHARED",
                value: !state.form.isShared,
              })
            }
            control={<Switch sx={{ ml: 1.5, mr: 1 }} />}
          />
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
          {!readonly && (
            <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
              <Button onClick={handleSave} disabled={canSaveTooltips.length > 0}>
                Save
              </Button>
            </TWItemizedTooltip>
          )}
        </Box>
      </DialogActions>

      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}

      {state.pendingRevisionAction !== null && (
        <Dialog
          open={state.pendingRevisionAction !== null}
          onClose={cancelRevision}
          maxWidth="sm"
          fullWidth>
          <DialogTitle title="Create Revision of Recipe?" onClose={cancelRevision} />
          <DialogContent>
            <Typography>Any changes to a recipe that affects its nutrients will create a new revision.</Typography>
            <RevisionDiv>
              <CheckIcon sx={revisionIconStyle} />
              <Typography>
                Usage on <b>Meal Plans</b> will preserve existing nutrients.
              </Typography>
            </RevisionDiv>
            <RevisionDiv>
              <WarningIcon sx={revisionIconStyle} />
              <Typography>
                Usage on <b>Menus</b> will <b>NOT</b> be updated to new revision.
              </Typography>
            </RevisionDiv>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={cancelRevision}>
              Cancel
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: "MAKE_REVISION",
                })
              }
            >
              Create Revision
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {printOpen && <RecipePrintDialog recipeId={state.id} onClose={() => setPrintOpen(false)} />}
    </Dialog>
  );
};
