import { useApolloClient } from "@apollo/client";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../../../componentLibrary";
import { RecipeEditDialog } from "../../../../components/Recipe/EditDialog/RecipeEditDialog";
import RecipeCreateDialog from "../../../../components/Recipe/RecipeCreateDialog";
import RecipeDuplicateDialog from "../../../../components/Recipe/RecipeDuplicateDialog";
import {
  StaffDashboardRecipePreviewFragment,
  useDeleteRecipeMutation,
  useEditRecipeIsSharedMutation,
  useStaffDashboardRecipesQuery,
} from "../../../../types";
import GeneralizedStaffContent from "../../../../views/Staff/GeneralizedStaffContent";
import { UseFetchQueryArgs } from "../../../../views/Staff/GeneralizedStaffContent/types";

const headerRowInfo = [
  { key: "name", displayName: "Name" },
  { key: "createdByName", displayName: "Created By" },
  { key: "mealTypes", displayName: "Meal Types" },
  { key: "yields", displayName: "Yields" },
];

const useFetchQuery = (query: UseFetchQueryArgs) => {
  const { data, loading } = useStaffDashboardRecipesQuery(query);

  return {
    data: data?.recipeOffsetConnection,
    loading,
  };
};

const getCreatedByName = ({ createdBy }: StaffDashboardRecipePreviewFragment): string => {
  if (!createdBy) {
    return `--`;
  }
  if (createdBy.firstName || createdBy.lastName) {
    return `${createdBy.firstName} ${createdBy.lastName}`;
  }
  return createdBy.id;
};

const convertObjToRowData = (recipe: StaffDashboardRecipePreviewFragment) => ({
  name: recipe.name,
  createdByName: getCreatedByName(recipe),
  mealTypes:
    recipe.mealTypes
      ?.map(mt => {
        const mealTypeString = mt.toString();
        return mealTypeString.charAt(0).toUpperCase() + mealTypeString.slice(1);
      })
      .sort()
      .join(", ") ?? "",
  yields: recipe.servings.map(serving => `${serving.perRecipeYield} ${serving.units}`).join(", "),
  isShared: recipe.isShared, // TODO: update when we expose sharing scopes
});

export const RecipesPage = () => {
  const { setMessage } = useSnackbar();
  const apolloClient = useApolloClient();

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "recipeOffsetConnection",
      broadcast: true,
    });
  };

  const [deleteRecipe] = useDeleteRecipeMutation({
    onCompleted: () => {
      resetTable();
      setDeleteableRecipe(null);
      setMessage("success", `Successfully deleted recipe`);
    },
  });
  const handleDelete = (recipeId: string) => {
    deleteRecipe({
      variables: { input: { recipeId } },
    });
  };

  const [editRecipeIsShared] = useEditRecipeIsSharedMutation();
  const handleEditIsShared = (id: string, isShared: boolean) => {
    const message = isShared ? "Shared recipe with orgs" : "Unshared recipe";
    setMessage("success", message);
    apolloClient.cache.modify({
      id: `Recipe:${id}`,
      fields: {
        isShared: () => isShared,
      },
    });
    editRecipeIsShared({
      variables: { input: { id, isShared } },
    });
  };

  const [editRecipeId, setEditRecipeId] = useState<string | null>(null);
  const [duplicateRecipeId, setDuplicateRecipeId] = useState<string | null>(null);
  const [deleteableRecipe, setDeleteableRecipe] = useState<StaffDashboardRecipePreviewFragment | null>(null);

  return (
    <>
      <GeneralizedStaffContent
        togglable={{
          headerRowInfo: { displayName: "Shared", key: "isShared" },
          getToggleValue: recipe => recipe.isShared,
          onToggle: recipe => handleEditIsShared(recipe.id, !recipe.isShared),
          disabled: recipe => recipe.isShared,
          tooltip: () => "Notemeal recipes cannot be unshared",
        }}
        onTableRowClick={recipe => setEditRecipeId(recipe.id)}
        headerRowInfo={headerRowInfo}
        convertObjToRowData={convertObjToRowData}
        queryCacheKey="allTeamLabels"
        useFetchQuery={useFetchQuery}
        entityName="Recipe"
        getObjDisplay={recipe => recipe.name}
        onCreateSuccessMessage={recipe => `Succesfully created ${recipe.name}`}
        onEditSuccessMessage={recipe => `Succesfully edited ${recipe.name}`}
        actionMenuItems={[
          { label: "Duplicate", action: recipe => setDuplicateRecipeId(recipe.id) },
          { label: "Delete", action: recipe => setDeleteableRecipe(recipe) },
        ]}
        renderCreateDialog={({ open, onClose, onCreateSuccess }) => (
          <RecipeCreateDialog
            forStaff={true}
            open={open}
            onClose={onClose}
            onCreate={thing => {
              resetTable();
              onCreateSuccess(thing);
            }}
          />
        )}
      />
      {editRecipeId !== null && (
        <RecipeEditDialog
          forStaff={true}
          onEdit={() => {
            resetTable();
            setMessage("success", "Edited recipe");
          }}
          open={editRecipeId !== null}
          onClose={() => setEditRecipeId(null)}
          recipeId={editRecipeId}
          onDuplicate={() => setDuplicateRecipeId(editRecipeId)}
        />
      )}
      {duplicateRecipeId && (
        <RecipeDuplicateDialog
          forStaff={true}
          duplicatedRecipeId={duplicateRecipeId}
          open={!!duplicateRecipeId}
          onClose={() => setDuplicateRecipeId(null)}
          onCreate={() => {
            resetTable();
            setMessage("success", `Created recipe`);
          }}
        />
      )}
      {deleteableRecipe !== null && (
        <ConfirmationDialog
          open={!!deleteableRecipe}
          title="Delete Recipe"
          message={`Are you sure you want to delete this recipe?${
            deleteableRecipe.isShared ? " It has already been shared with orgs." : ""
          }`}
          onCancel={() => setDeleteableRecipe(null)}
          onConfirm={() => handleDelete(deleteableRecipe.id)}
          variant="containedDestructive"
        />
      )}
    </>
  );
};
