import { Box, SxProps, Typography } from "@mui/material";
import { getNextPosition } from "@notemeal/shared/ui/utils/getNextPosition";
import newId from "@notemeal/shared/ui/utils/newId";
import { sortByKey } from "@notemeal/utils/sort";
import React, { Dispatch, useState } from "react";
import CropperModal from "../../../universal/Image/CropModal";
import ImageFileDragAndDrop from "../../../universal/Image/ImageFileDragAndDrop";
import { useUploadPhoto } from "../../../universal/Image/uploadPhoto";
import { Image, RecipeFormAction, RecipeFormState } from "../utils";
import { LoadedImage, LoadingImage, UploadImage } from "./Image";

interface ImagesProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  sx: SxProps;
  disabled?: boolean;
}

const Images = ({ state, dispatch, sx, disabled }: ImagesProps) => {
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);

  const handleDrop = (url: string) => {
    setImageToCrop(url);
  };

  const handleCropComplete = (croppedImage: string) => {
    uploadPhoto(croppedImage);
  };

  const [uploadPhoto, imageUploading] = useUploadPhoto({
    onComplete: (imageUrl: string): void => {
      if (imageUrl) {
        const incomingImage: Image = {
          url: imageUrl,
          position: getNextPosition(state.images),
          id: newId(),
        };
        const images = [...state.images, incomingImage];
        dispatch({
          type: "CHANGE_IMAGES",
          value: images,
        });
      }
    },
  });

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", ...sx }}>
        {state.images.length === 0 ? (
          <ImageFileDragAndDrop
            disabled={disabled}
            onUpload={handleDrop}
            sx={{ py: 30 }}>
            <Typography variant="h3" sx={{ textAlign: "center", pb: 1, opacity: disabled ? 0.33 : 1 }}>
              Drop Image or Click to Upload
            </Typography>
          </ImageFileDragAndDrop>
        ) : (
          <Box sx={{ overflow: "auto", display: "flex", flexWrap: "wrap" }}>
            {sortByKey(state.images, "position").map(image => {
              return (
                <LoadedImage
                  disabled={disabled}
                  image={image}
                  images={state.images}
                  onChangeImages={images => {
                    dispatch({ type: "CHANGE_IMAGES", value: images });
                  }}
                />
              );
            })}
            {imageUploading && <LoadingImage key="loading" />}
            <UploadImage disabled={disabled} handleDrop={handleDrop} />
          </Box>
        )}
      </Box>
      {imageToCrop && (
        <CropperModal
          open={!!imageToCrop}
          onClose={() => setImageToCrop(null)}
          imageUrl={imageToCrop}
          onSave={handleCropComplete}
        ></CropperModal>
      )}
    </>
  );
};

export default Images;
