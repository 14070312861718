import { FeatureFlagsFragment, useMyFeatureFlagsQuery } from "../types";

interface useHasFeatureOptions {
  skip?: boolean;
}

export const useHasFeature = (flag: keyof FeatureFlagsFragment, opts?: useHasFeatureOptions) => {
  const skip = opts?.skip ?? false;
  const { data } = useMyFeatureFlagsQuery({
    skip,
  });

  return Boolean(data && data.myFeatureFlags[flag]);
};
