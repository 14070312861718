import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { StaffDashboardOrgPreviewFragment, useEditOrgMutation } from "../../../../types";
import Form from "../Form/Edit";
import { editOrgMembershipFormReducer, editOrgMembershipFormToSaveTooltips, initialEditOrgMembershipFormState } from "../Form/utils";
import { AdminOrgMembershipTableRow } from "../Table";
import { getIntegrationInput } from "../utils";

interface OrgMembershipDialogProps {
  open: boolean;
  onClose: () => void;
  org: StaffDashboardOrgPreviewFragment;
  orgMembership: AdminOrgMembershipTableRow;
}

export const EditOrgMembershipDialog = ({ open, onClose, org, orgMembership }: OrgMembershipDialogProps) => {
  const [state, dispatch] = useReducer(editOrgMembershipFormReducer, initialEditOrgMembershipFormState(orgMembership, org.id));
  const { setMessage } = useSnackbar();
  const canSaveTooltips = editOrgMembershipFormToSaveTooltips(state, orgMembership);

  const isTeamworksUser = orgMembership.userTeamworksId !== null;

  const [editOrg] = useEditOrgMutation({
    onError: e => setMessage("error", e.message),
  });

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    const { roles, isActive, isAdmin, email, phoneNumber } = state;
    editOrg({
      variables: {
        input: {
          id: org.id,
          name: org.name,
          isActive: org.isActive,
          teamworksId: org.teamworksId,
          localeCode: org.localeCode,
          territoryName: org.territoryName,
          allowsPublicAccess: org.allowsPublicAccess,
          integrations: getIntegrationInput(org.integrations),
          agreementFormId: org.agreementForm?.id || null,
          scoringSystemId: org.scoringSystem?.id || null,
          editMemberships: [
            {
              id: orgMembership.id,
              roles,
              isActive,
              isAdmin,
              // catches case when existing email or phonenumber is removed via form
              email: email && email.length > 0 ? email : null,
              phoneNumber: phoneNumber && phoneNumber.length > 0 ? phoneNumber : null,
            },
          ],
          createMemberships: [],
        },
      },
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md">
      <DialogTitle title="Edit Member" onClose={onClose} />
      <DialogContent>
        <Form
          state={state}
          dispatch={dispatch}
          isTeamworksUser={isTeamworksUser} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};
