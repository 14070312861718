import { Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Switch } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import React, { useReducer, useState } from "react";
import TWItemizedTooltip from "../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { FullServingAmountFragment, GptRecipeFragment, RecipeFullFragment, useCreateRecipeMutation } from "../../types";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import DiscardChangesDialog from "../universal/DiscardChangesDialog";
import LoadingBackdrop from "../universal/LoadingBackdrop";
import Form from "./Form/RecipeForm";
import {
  RecipeFormState,
  canSaveRecipeFormTooltips,
  newRecipeFormState,
  newRecipeFormStateFromGPTRecipe,
  recipeFormReducer,
  recipeFormToInput,
  recipeServingFormToInput,
} from "./Form/utils";
import { GenerateWithGPTDialog } from "./GPT/Generator/GenerateWithGPTDialog";
import { TranscribeWithGPTDialog } from "./GPT/Transcriber/TranscribeWithGPTDialog";
import { TranscribeError, getUserFriendlyMessage } from "./GPT/Transcriber/utils";

interface RecipeCreateDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (recipe: RecipeFullFragment) => void;
  initialIngredients?: readonly FullServingAmountFragment[];
  initialName?: string;
  initialRecipeState?: RecipeFormState;
  forStaff?: boolean;
  setShowCreateRecipeModal?: (show: boolean) => void;
}

const RecipeCreateDialog = ({
  open,
  onClose,
  onCreate,
  initialIngredients,
  initialName,
  initialRecipeState,
  forStaff,
  setShowCreateRecipeModal,
}: RecipeCreateDialogProps) => {
  const [state, dispatch] = useReducer(recipeFormReducer, initialRecipeState || newRecipeFormState(initialName, initialIngredients));

  const _hasPerformanceKitchenFeatureFlag = useHasFeature("performanceKitchen", { skip: forStaff });
  const { setMessage } = useSnackbar();
  const [transcriberError, setTranscriberError] = useState<TranscribeError | null>(null);
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();
  const showAIRecipes = !forStaff && _hasPerformanceKitchenFeatureFlag;
  const showAiTranscriber = useHasFeature("aiTranscriber", { skip: forStaff });

  const [showGenerateRecipeModal, setShowGenerateRecipeModal] = useState(false);
  const [showTranscribeRecipeModal, setShowTranscribeRecipeModal] = useState(false);

  const onGenerateError = (error: any) => {
    const userFriendlyMessage = getUserFriendlyMessage(error);
    setMessage("error", userFriendlyMessage);
  };

  const onGPTRecipeComplete = (gptRecipe: GptRecipeFragment, closeModal: () => void) => {
    const newState = newRecipeFormStateFromGPTRecipe(gptRecipe);
    dispatch({
      type: "REPLACE_STATE",
      payload: newState,
    });
    closeModal();
    setShowTranscribeRecipeModal(false);

    setShowCreateRecipeModal && setShowCreateRecipeModal(true);
  };

  const [createRecipe, { called }] = useCreateRecipeMutation({
    onCompleted: data => {
      onClose();
      onCreate(data.createRecipeFull.recipe);
    },
    update: cache => {
      cache.evict({
        fieldName: "recipeOffsetConnection",
        broadcast: true,
      });
    },
  });

  const handleSave = () => {
    const recipeInput = recipeFormToInput(state);
    if (canSaveTooltips.length || !recipeInput) {
      return;
    }

    createRecipe({
      variables: {
        input: {
          recipe: recipeInput,
          servings: [state.serving].map(recipeServingFormToInput),
          copiedFromId: initialRecipeState?.id || null,
        },
      },
    });
  };

  React.useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(state.edited);
  }, [state.edited, setBrowserBackAndRefreshWarningEnabled]);

  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const handleClose = () => {
    if (state.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const canSaveTooltips = canSaveRecipeFormTooltips(state);

  const showAiButtons = !state.isAiGenerated && !state.isAiTranscribed;
  const onAiGenerateClicked = showAIRecipes && showAiButtons ? () => setShowGenerateRecipeModal(true) : null;
  const onAiTranscribeClicked = showAiTranscriber && showAiButtons ? () => setShowTranscribeRecipeModal(true) : undefined;

  return called ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      PaperProps={{
        sx: { minHeight: "calc(100% - 20px)" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle title="Create Recipe" onClose={handleClose} />
      <DialogContent sx={{ pt: 0 }}>
        <Form
          forStaff={forStaff}
          state={state}
          dispatch={dispatch}
          onAiGenerateClicked={onAiGenerateClicked}
          onAiTranscribeClicked={onAiTranscribeClicked}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <FormControlLabel
          sx={{ alignSelf: "start" }}
          label={forStaff ? "Share with orgs" : "Share with athletes"}
          labelPlacement="end"
          checked={state.isShared}
          onChange={() =>
            dispatch({
              type: "CHANGE_IS_SHARED",
              value: !state.isShared,
            })
          }
          control={<Switch />}
        />
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
            <Button onClick={handleSave} disabled={canSaveTooltips.length > 0}>
              Save
            </Button>
          </TWItemizedTooltip>
        </Box>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
      {showGenerateRecipeModal && (
        <GenerateWithGPTDialog
          open={showGenerateRecipeModal}
          onClose={() => setShowGenerateRecipeModal(false)}
          onGenerateComplete={onGPTRecipeComplete}
          onError={onGenerateError}
          initialName={state.name}
        />
      )}
      {showTranscribeRecipeModal && (
        <TranscribeWithGPTDialog
          open={showTranscribeRecipeModal}
          onClose={() => setShowTranscribeRecipeModal(false)}
          onTranscribeComplete={onGPTRecipeComplete}
          transcriberError={transcriberError}
          setTranscriberError={setTranscriberError}
          onError={onGenerateError}
        />
      )}
    </Dialog>
  );
};

export default RecipeCreateDialog;
