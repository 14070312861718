import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FormControl, IconButton, Menu, MenuItem, Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import { useOrgGroupRecipesTableQuery } from "apps/web/src/types";
import { useUser } from "apps/web/src/utils/tokens";
import { Score } from "libs/shared/ui/src/lib/Score/Score";
import React, { useEffect } from "react";
import { isRecipeOwner } from "../isRecipeOwner";
import { useRecipesPage } from "../useRecipesPage";

const MoreInfoMenu = () => {
  const user = useUser();

  const { setMoreInfo, moreInfo, setDuplicateRecipeId, setDeleteableRecipe, setPrintRecipeId } = useRecipesPage();

  if (!moreInfo) {
    return null;
  }

  const isOwner = isRecipeOwner(moreInfo.recipe, user);

  return (
    <Menu
      anchorEl={moreInfo?.moreAnchorElement}
      open={!!moreInfo}
      onClose={() => {
        setMoreInfo(null);
      }}
      keepMounted
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        dense
        onClick={() => {
          setDuplicateRecipeId(moreInfo.recipe.id);
          setMoreInfo(null);
        }}
      >
        Duplicate to My Org
      </MenuItem>
      <Tooltip title={isOwner ? "" : "Only the recipe manager can delete an org group recipe"}>
        <MenuItem
          dense
          onClick={() => {
            setDeleteableRecipe(moreInfo.recipe);
            setMoreInfo(null);
          }}
          disabled={!isOwner}
        >
          Delete
        </MenuItem>
      </Tooltip>
      <MenuItem
        dense
        onClick={() => {
          setPrintRecipeId(moreInfo.recipe.id);
          setMoreInfo(null);
        }}
      >
        Print
      </MenuItem>
    </Menu>
  );
};

export const OrgGroupRecipeRows = () => {
  const {
    paginationHooks,
    handleEditIsShared,
    selectedOwners,
    selectedMealTypes,
    setEditRecipe,
    setMoreInfo,
    setTotalRows,
    setRowsLoading,
    scoringSystem,
  } = useRecipesPage();
  const { limit, offset, query } = paginationHooks;

  const { data: recipeData, loading: recipeLoading } = useOrgGroupRecipesTableQuery({
    variables: {
      input: { limit, offset },
      sort: null,
      query,
      ownerIds: selectedOwners.map(o => o.id),
      mealTypes: selectedMealTypes.length > 0 ? selectedMealTypes : null,
    },
    onCompleted: data => {
      if (data) {
        setTotalRows(data.orgGroupRecipeOffsetConnection.pageInfo.total);
      }
    },
  });

  useEffect(() => {
    setRowsLoading(recipeLoading);
  }, [recipeLoading, setRowsLoading]);

  const rows = recipeData?.orgGroupRecipeOffsetConnection.edges || [];

  return (
    <>
      {rows.map(row => {
        const { id, name, isShared, mealTypes, servings, orgGroup, owner, copiedCount, score } = row;
        return (
          <TableRow
            key={id}
            id={id}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => setEditRecipe(row)}>
            <TableCell>
              <Tooltip title={"Share recipe with athletes"}>
                <FormControl>
                  <Switch
                    size="medium"
                    inputProps={{
                      "aria-label": "Share recipe with athletes",
                    }}
                    checked={isShared}
                    onClick={e => e.stopPropagation()}
                    onChange={e => {
                      handleEditIsShared(id, e.target.checked);
                    }}
                  />
                </FormControl>
              </Tooltip>
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{orgGroup?.name ?? "--"}</TableCell>
            <TableCell>{`${owner?.firstName} ${owner?.lastName}`}</TableCell>
            <TableCell>
              {mealTypes &&
                mealTypes
                  .map(mt => {
                    const mealTypeString = mt.toString();
                    return mealTypeString.charAt(0).toUpperCase() + mealTypeString.slice(1);
                  })
                  .sort()
                  .join(", ")}
            </TableCell>
            <TableCell>{servings.map(s => `${s.perRecipeYield} ${s.units}`).join(", ")}</TableCell>
            {scoringSystem && (
              <TableCell>
                {score && (
                  <>
                    {" "}
                    <Score
                      scoreValue={score.value}
                      scoringSystem={scoringSystem}
                      sx={{ gap: 0.5 }}
                      size="lg" />
                  </>
                )}
              </TableCell>
            )}
            <TableCell>{copiedCount}</TableCell>
            <TableCell>
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  setMoreInfo({ moreAnchorElement: e.currentTarget, recipe: row });
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      <MoreInfoMenu />
    </>
  );
};
