import { RetryLink } from "@apollo/client/link/retry";

const MAX_ATTEMPT_COUNT = 7;

export const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    // only retry if all of the operation definitions are queries
    const operationDefinitions = operation.query.definitions.filter(definition => definition.kind === "OperationDefinition");
    const isQuery =
      operationDefinitions.length > 0 &&
      operationDefinitions.every(definition => definition.kind === "OperationDefinition" && definition.operation === "query");
    if (isQuery && count < MAX_ATTEMPT_COUNT && !!error) {
      console.log(`got network error on mutation=${operation.operationName} retry_count=${count} max_retries=${MAX_ATTEMPT_COUNT}`);
      return true;
    }
    return false;
  },
});
