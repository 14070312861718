import { DayOfWeek, PlannedMenuDisabledDaysInput } from "@notemeal/graphql/types";
import { AthletesBirthdaysFragment, usePlannedMenuHeaderLazyQuery } from "apps/web/src/types";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { MenuBuilderMealRowItemType } from "./Meal/MenuBuilderMealSchema";
import { DAY_CELL_WIDTH, LEFT_PANEL_CELL_WIDTH } from "./Meal/constants";
import { MenuBuilderWeek, getDays, transformWeeks } from "./utils";

type Clipboard =
  | {
      type: "cell";
      formPath: string;
      items: MenuBuilderMealRowItemType[];
    }
  | {
      type: "row";
      formPath: string;
      items: MenuBuilderMealRowItemType[];
      diningStationName: string;
      foodType: string | null;
    };
type MenuBuilderGridContextValue = {
  loading: boolean;
  menuId: string;
  menuName: string;
  selectedWeekIndex: number;
  setSelectedWeekIndex: (index: number) => void;
  weeks: readonly MenuBuilderWeek[];
  days: DayOfWeek[];
  mealGridWidth: string;
  mealsToDelete: string[];
  setMealsToDelete: (mealIds: string[]) => void;
  rowsToDelete: string[];
  setRowsToDelete: (menuItems: string[]) => void;
  rowItemsToDelete: string[];
  setRowItemsToDelete: (menuItems: string[]) => void;
  clipboard?: Clipboard;
  setClipboard: (clipboard?: Clipboard) => void;
  disabledDays: PlannedMenuDisabledDaysInput[];
  setDisabledDays: (days: PlannedMenuDisabledDaysInput[]) => void;
  selectedDay?: DayOfWeek;
  setSelectedDay: (day?: DayOfWeek) => void;
  isEditable: boolean;
  setIsEditable: (isEditable: boolean) => void;
  athleteBirthdays: readonly AthletesBirthdaysFragment[];
};

const defaultState = {
  loading: true,
  menuId: "",
  menuName: "",
  selectedWeekIndex: 0,
  setSelectedWeekIndex: () => {},
  weeks: [],
  days: [],
  mealGridWidth: `calc(${LEFT_PANEL_CELL_WIDTH} + 5 * ${DAY_CELL_WIDTH} + 2px)`,
  mealsToDelete: [],
  setMealsToDelete: () => {},
  rowsToDelete: [],
  setRowsToDelete: () => {},
  rowItemsToDelete: [],
  setRowItemsToDelete: () => {},
  clipboard: undefined,
  setClipboard: () => {},
  disabledDays: [],
  setDisabledDays: () => {},
  selectedDay: undefined,
  setSelectedDay: () => {},
  isEditable: true,
  setIsEditable: () => {},
  athleteBirthdays: [],
};
const MenuBuilderGridPageContext = createContext<MenuBuilderGridContextValue>(defaultState);

export const MenuBuilderProvider: React.FC<{ plannedMenuId: string; children: ReactNode }> = ({ plannedMenuId, children }) => {
  // on first render get the menu, and any time the menu is fetched refetch its meals
  const [getPlannedMenu, { data: menu, loading: loadingMenu }] = usePlannedMenuHeaderLazyQuery({
    variables: { plannedMenuId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getPlannedMenu();
  }, []);

  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const [mealsToDelete, setMealsToDelete] = useState<string[]>([]);
  const [rowsToDelete, setRowsToDelete] = useState<string[]>([]);
  const [rowItemsToDelete, setRowItemsToDelete] = useState<string[]>([]);
  const [clipboard, setClipboard] = useState<Clipboard | undefined>(undefined);
  const [disabledDays, setDisabledDays] = useState<PlannedMenuDisabledDaysInput[] | undefined>(undefined);
  const [selectedDay, setSelectedDay] = useState<DayOfWeek | undefined>(undefined);
  const [isEditable, setIsEditable] = useState<boolean>(true);

  // assertion to avoid null checks everywhere
  if (!menu?.plannedMenu || menu.plannedMenu.weeks.length === 0) {
    return <MenuBuilderGridPageContext.Provider value={defaultState}>{children}</MenuBuilderGridPageContext.Provider>;
  }

  const days = getDays(menu?.plannedMenu.occurrence);
  // keeps same width when meal is collapsed as when its expanded
  const mealGridWidth = `calc(${LEFT_PANEL_CELL_WIDTH} + ${days.length} * ${DAY_CELL_WIDTH} + 2px)`;
  if (disabledDays === undefined) {
    setDisabledDays(
      menu.plannedMenu.weeks.map(week => {
        return {
          plannedMenuWeekId: week.id,
          disabledDays: week.disabledDays as DayOfWeek[],
        };
      })
    );
  }

  return (
    <MenuBuilderGridPageContext.Provider
      value={{
        loading: loadingMenu,
        menuId: plannedMenuId,
        menuName: menu.plannedMenu.name,
        selectedWeekIndex,
        setSelectedWeekIndex,
        weeks: transformWeeks(menu.plannedMenu.weeks, menu.plannedMenu),
        days,
        mealGridWidth,
        mealsToDelete,
        setMealsToDelete,
        rowsToDelete,
        setRowsToDelete,
        rowItemsToDelete,
        setRowItemsToDelete,
        clipboard,
        setClipboard,
        disabledDays: disabledDays ?? [],
        setDisabledDays,
        selectedDay,
        setSelectedDay,
        isEditable,
        setIsEditable,
        athleteBirthdays: menu.plannedMenu.athletesWithBirthdaysInRange || [],
      }}
    >
      {children}
    </MenuBuilderGridPageContext.Provider>
  );
};

export const useMenuBuilderContext = () => {
  const state = useContext(MenuBuilderGridPageContext);
  return state;
};
