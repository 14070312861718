import { useApolloClient } from "@apollo/client";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { RecipesPageHeader } from "apps/web/src/views/Recipes/components/RecipesPageHeader";
import React, { useState } from "react";
import { RecipeEditDialog } from "../../../../components/Recipe/EditDialog/RecipeEditDialog";
import RecipeCreateDialog from "../../../../components/Recipe/RecipeCreateDialog";
import RecipeDuplicateDialog from "../../../../components/Recipe/RecipeDuplicateDialog";
import { TableRecipeFragment, useDeleteRecipeMutation, useEditRecipeIsSharedMutation } from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import { MoveRecipeToOrgGroupDialog } from "../../../../views/Recipes/components/MoveRecipeToOrgGroupDialog";
import { RecipePrintDialog } from "../../../../views/Recipes/components/Printing/RecipePrintDialog";
import { RecipesPageTable } from "../../../../views/Recipes/components/RecipesPageTable";
import { RecipesPageProvider } from "../../../../views/Recipes/components/useRecipesPage";
import { ORGANIZATION_RECIPES } from "./Utils";

export interface RecipeMoreInfo {
  moreAnchorElement: HTMLElement;
  recipe: TableRecipeFragment;
}

export const RecipesPage = () => {
  const apolloClient = useApolloClient();
  const [tab, setTab] = useState(ORGANIZATION_RECIPES);
  const [editRecipe, setEditRecipe] = useState<TableRecipeFragment | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [duplicateRecipeId, setDuplicateRecipeId] = useState<string | null>(null);
  const [printRecipeId, setPrintRecipeId] = useState<string | null>(null);
  const [moveToOrgGroupRecipe, setMoveToOrgGroupRecipe] = useState<TableRecipeFragment | null>(null);
  const [deleteableRecipe, setDeleteableRecipe] = useState<TableRecipeFragment | null>(null);
  const { setMessage } = useSnackbar();
  const paginationHooks = useOffsetPagination(25);
  const { onChangePage } = paginationHooks;

  function resetTable() {
    apolloClient.cache.evict({
      fieldName: "recipeOffsetConnection",
      broadcast: true,
    });
    apolloClient.cache.evict({
      fieldName: "orgGroupRecipeOffsetConnection",
      broadcast: true,
    });
    onChangePage(0);
  }

  const [deleteRecipe] = useDeleteRecipeMutation({
    onCompleted: () => {
      resetTable();
      setDeleteableRecipe(null);
      setMessage("success", `Deleted recipe`);
    },
  });
  const handleDelete = (recipeId: string) => {
    deleteRecipe({
      variables: { input: { recipeId } },
    });
  };

  const [editRecipeIsShared] = useEditRecipeIsSharedMutation();
  const handleEditIsShared = (id: string, isShared: boolean) => {
    const message = isShared ? "Shared recipe with athletes" : "Unshared recipe";
    setMessage("success", message);
    apolloClient.cache.modify({
      id: `Recipe:${id}`,
      fields: {
        isShared: () => isShared,
      },
    });
    editRecipeIsShared({
      variables: { input: { id, isShared } },
    });
  };

  return (
    <RecipesPageProvider
      paginationHooks={paginationHooks}
      resetTable={resetTable}
      setCreateDialogOpen={setCreateDialogOpen}
      handleEditIsShared={handleEditIsShared}
      tab={tab}
      setTab={setTab}
      editRecipe={editRecipe}
      setEditRecipe={setEditRecipe}
      duplicateRecipeId={duplicateRecipeId}
      setDuplicateRecipeId={setDuplicateRecipeId}
      deleteableRecipe={deleteableRecipe}
      setDeleteableRecipe={setDeleteableRecipe}
      printRecipeId={printRecipeId}
      setPrintRecipeId={setPrintRecipeId}
      moveToOrgGroupRecipe={moveToOrgGroupRecipe}
      setMoveToOrgGroupRecipe={setMoveToOrgGroupRecipe}
    >
      <RecipesPageHeader />
      <RecipesPageTable />
      {createDialogOpen && (
        <RecipeCreateDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          onCreate={recipe => {
            resetTable();
            setMessage("success", `${recipe.name} has been created.`);
          }}
        />
      )}
      {editRecipe !== null && (
        <RecipeEditDialog
          onEdit={() => {
            resetTable();
            setMessage("success", "Edited recipe");
          }}
          readonly={!editRecipe.org && !editRecipe.orgGroup}
          open={editRecipe !== null}
          onClose={() => setEditRecipe(null)}
          recipeId={editRecipe.id}
          onDuplicate={() => setDuplicateRecipeId(editRecipe.id)}
        />
      )}
      {duplicateRecipeId && (
        <RecipeDuplicateDialog
          duplicatedRecipeId={duplicateRecipeId}
          open={!!duplicateRecipeId}
          onClose={() => setDuplicateRecipeId(null)}
          onCreate={() => {
            resetTable();
            setMessage("success", `Created recipe`);
            if (tab !== "org") {
              setTab("org");
            }
          }}
        />
      )}
      {deleteableRecipe !== null && (
        <ConfirmationDialog
          open={!!deleteableRecipe}
          title="Delete Recipe"
          message="Are you sure that you would like to delete the recipe?"
          onCancel={() => setDeleteableRecipe(null)}
          onConfirm={() => handleDelete(deleteableRecipe.id)}
          variant="containedDestructive"
        />
      )}
      {printRecipeId !== null && <RecipePrintDialog recipeId={printRecipeId} onClose={() => setPrintRecipeId(null)} />}
      {moveToOrgGroupRecipe !== null && (
        <MoveRecipeToOrgGroupDialog onClose={() => setMoveToOrgGroupRecipe(null)} setSuccessMsg={setMessage} />
      )}
    </RecipesPageProvider>
  );
};
