import Cookies from "universal-cookie";

export const getCookie = (cookieName: string): string | null | undefined => {
  const cookies = new Cookies();
  return cookies.get(cookieName, { doNotParse: true });
};

export const clearCookies = (cookieNames: string[]) => {
  const cookies = new Cookies();
  cookieNames.forEach(cookieName => {
    cookies.remove(cookieName, {
      domain: process.env.REACT_APP_WEB_DOMAIN_COOKIE,
    });
    cookies.remove(cookieName);
  });
};
