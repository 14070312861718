import { Box, FormControlLabel, Link, Radio, RadioGroup, Typography } from "@mui/material";
import { EmailSchema, PhoneNumberSchmea } from "@notemeal/validators";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import { TeamWithSportFragment } from "apps/web/src/types";
import React, { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import AthleteForm from "../../../components/Athlete/Form";
import { AthleteFormType } from "../../../components/Athlete/Form/AthleteFormSchema";
import { AccountVerificationMethodFormType } from "./AccountVerificationMethodFormSchema";

interface CreateAthleteFormProps {
  athleteForm: UseFormReturn<AthleteFormType>;
  accountVerificationMethodForm: UseFormReturn<AccountVerificationMethodFormType>;
  teams?: readonly TeamWithSportFragment[];
}

const CreateAthleteForm = ({ athleteForm, accountVerificationMethodForm, teams }: CreateAthleteFormProps) => {
  const { control, getValues, setValue } = accountVerificationMethodForm;
  const { watch: watchAthleteForm } = athleteForm;

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  useEffect(() => {
    const athleteFormWatcher = watchAthleteForm((data, { name }) => {
      const { accountVerificationMethod } = getValues();

      if (name === "email") {
        const isValid = EmailSchema.safeParse(data.email).success;
        setIsValidEmail(isValid);

        if (!isValid && accountVerificationMethod === "email") {
          setValue("accountVerificationMethod", "skip");
        }
      } else if (name === "phoneNumber") {
        const isValid = PhoneNumberSchmea.safeParse(data.phoneNumber).success;
        setIsValidPhoneNumber(isValid);

        if (!isValid && accountVerificationMethod === "txt") {
          setValue("accountVerificationMethod", "skip");
        }
      }
    });

    return () => athleteFormWatcher.unsubscribe();
  }, [watchAthleteForm, getValues, setValue]);

  return (
    <>
      <Box sx={{ p: 2, mt: 2, backgroundColor: "grey.200", borderRadius: "5px" }}>
        <Typography variant="body2" color="textSecondary">
          *{" "}
          <Box display="inline" sx={{ fontWeight: 600 }}>
            Name and sex
          </Box>{" "}
          are the only required fields. Additional information (e.g., birth date, anthropometric data) can be filled out by athletes during{" "}
          <Link
            sx={{ color: "info.main" }}
            target="_blank"
            href="https://teamworks.zendesk.com/hc/en-us/articles/13255660957332-Auto-Onboard-to-Notemeal"
          >
            auto-onboarding
          </Link>{" "}
          or by dietitians on the athlete summary page.
        </Typography>
      </Box>
      <AthleteForm
        teams={teams}
        displayUidFields
        isForSyncedAthlete={false}
        form={athleteForm} />
      <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
        <Typography variant="body1Medium" sx={{ mt: 1 }}>
          Send Invitation Link via
        </Typography>
        <Controller
          name="accountVerificationMethod"
          control={control}
          render={({ field: { ref, onChange, ...field } }) => (
            <RadioGroup
              {...field}
              onChange={(_, value) => onChange(value || null)}
              row
              aria-label="Send Invitation Link via"
              name="send-invite-via"
            >
              <FormControlLabel
                value={"skip"}
                control={<Radio />}
                label="Send Later" />
              <TWItemizedTooltip title={"Cannot send invitation link via text message:"} items={[]}>
                <FormControlLabel
                  disabled={!isValidPhoneNumber}
                  value="txt"
                  control={<Radio />}
                  label="Text Message" />
              </TWItemizedTooltip>
              <TWItemizedTooltip title={"Cannot send invitation link via email:"} items={[]}>
                <FormControlLabel
                  disabled={!isValidEmail}
                  value="email"
                  control={<Radio />}
                  label="Email" />
              </TWItemizedTooltip>
            </RadioGroup>
          )}
        />
      </Box>
    </>
  );
};

export default CreateAthleteForm;
