import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Fab, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { NutritionColorIcon, TeamworksBadgeIcon } from "@notemeal/shared/ui/global/Icons";
import { parseDate, safeSerializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { NAV_ORG_KITCHEN_KIOSK } from "../../../pages/Auth/Org/Kitchen/KitchenPaths";
import { KioskMealMenuPreviewFragment } from "../../../types";
import { HUB_CHECK_IN, KioskMode, ORDER } from "../utils";
import MenuSelectorCardWithSharingInfo from "./MenuSelectorCardWithSharingInfo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      padding: theme.spacing(2),
      flexGrow: 1,
    },
    cards: {
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(2),
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    fab: {
      float: "left",
      width: 400,
    },
    noMenus: {
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    infoIcon: {
      marginBottom: theme.spacing(1),
      fontSize: "2rem",
    },
  })
);

const styles = {
  configureOptions: {
    display: "flex",
    gap: ({ spacing }: Theme) => spacing(2),
  },
  dateTextField: {
    width: 170,
    alignContent: "center",
    // Bad solution, but will suffice until https://github.com/mui/mui-x/issues/8345 is resolved
    "& > div": {
      height: "100%",
    },
  },
};

interface KioskConfigurationProps {
  mealMenus: readonly KioskMealMenuPreviewFragment[];
  selectedMealMenuIds: readonly string[];
  kioskMode: KioskMode;
  setKioskMode: (mode: KioskMode) => void;
  onSelectMealMenu: (mealMenuId: string) => void;
  onChangeDate: () => void;
  onNext: () => void;
  date: string;
  restrictiveView?: boolean;
}

export const KioskConfiguration = ({
  mealMenus,
  onSelectMealMenu,
  kioskMode,
  setKioskMode,
  selectedMealMenuIds,
  onChangeDate,
  onNext,
  date,
}: KioskConfigurationProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isHubCheckinEnabled = useHasFeature("hubMenuCheckIn");

  const displayMealMenus = kioskMode === "Order" ? mealMenus.filter(mm => mm.isOrderable) : mealMenus;
  const hubCheckIn = {
    label: HUB_CHECK_IN,
    element: (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TeamworksBadgeIcon />
        x
        <NutritionColorIcon />
        {HUB_CHECK_IN}
      </Box>
    ),
  };
  const kioskConfigTabs = isHubCheckinEnabled ? [ORDER, hubCheckIn] : [ORDER];

  return (
    <div className={classes.root}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 2,
        }}
      >
        <TWTabGroup tabs={kioskConfigTabs} onSelected={selected => setKioskMode(selected as KioskMode)} />
        <DesktopDatePicker
          sx={styles.dateTextField}
          label="Select date"
          onChange={date => {
            onChangeDate();
            const serializedDate = safeSerializeDate(date);
            serializedDate && navigate(`${NAV_ORG_KITCHEN_KIOSK}/${serializedDate}`);
          }}
          value={parseDate(date)}
        />
      </Box>
      <div className={classes.content}>
        {displayMealMenus.length > 0 ? (
          <>
            <Typography
              variant="h2"
              color="textPrimary"
              gutterBottom>
              Select Menus
            </Typography>
            <div className={classes.cards}>
              {displayMealMenus.map(mm => (
                <MenuSelectorCardWithSharingInfo
                  mealMenu={mm}
                  onClick={mealMenuId => onSelectMealMenu(mealMenuId)}
                  selected={selectedMealMenuIds.includes(mm.id)}
                  key={mm.id}
                />
              ))}
            </div>
            <Fab
              disabled={selectedMealMenuIds.length === 0}
              onClick={onNext}
              className={classes.fab}
              variant="extended">
              Continue
              <ArrowRightIcon />
            </Fab>
          </>
        ) : (
          <div className={classes.noMenus}>
            <InfoOutlinedIcon color="action" className={classes.infoIcon} />
            <Typography variant="body2Semibold" gutterBottom>
              No Available Menus
            </Typography>
            <Typography>Looks like there are no available menus for this date.</Typography>
            <Typography>Select a new date or create a menu to share.</Typography>
          </div>
        )}
      </div>
    </div>
  );
};
