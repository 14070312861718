import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TableCell, TableRow, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import HelpTipIcon from "../../../../components/universal/HelpTipIcon";
import { getNavOrgAthlete } from "../../../../pages/Auth/Org/Athlete/AthletePaths";
import { FoodLogNotificationSettings } from "../../../../types";
import NamedTagPreviewChipList from "../../../Tags/NamedTagPreviewChipList";
import AthleteActions from "./Actions";
import { EnableAthlete } from "./Table";
import {
  AVOIDED_FOODS,
  ENGAGEMENT,
  IMAGE_UPLOAD_COUNT,
  MEALS_LOGGED_COUNT,
  MEALS_ORDERED_COUNT,
  MEAL_PLAN_VIEW_COUNT,
  PREFERENCES,
  PROFILES,
  SortType,
  TOTAL_MEALS_COUNT,
  TableView,
} from "./Toolbar";
import { IAthleteProfileRowInput } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
    },
    headerCell: { padding: theme.spacing(0.5) },
    paleGrey: {
      opacity: theme.palette.action.disabledOpacity,
    },
    smallFont: { fontSize: 13 },
    actions: { display: "flex", alignContent: "center" },
    sortableColumn: {
      "&:hover": {
        cursor: "pointer",
      },
      alignItems: "center",
      display: "flex",
    },
    icon: { width: theme.spacing(3) },
    noWrap: { whiteSpace: "nowrap" },
    lastChild: { "&:last-child": { marginRight: theme.spacing(0) } },
  })
);

interface AthleteTableRowProps {
  row: IAthleteProfileRowInput;
  view: TableView;
  onClickEnableAccount: (athlete: EnableAthlete) => void;
  displaySubscribeToAthleteModal: (args: { athleteNotificationSettings: FoodLogNotificationSettings | null; athleteId: string }) => void;
  teamFoodLogNotificationSettings: FoodLogNotificationSettings | null;
  setEditing: (editing: boolean) => void;
  isNotemealLinked: boolean;
}

export const AthletesTableRow = ({
  row,
  onClickEnableAccount,
  displaySubscribeToAthleteModal,
  teamFoodLogNotificationSettings,
  setEditing,
  isNotemealLinked,
}: AthleteTableRowProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isMetricLocale } = useLocaleContext();

  const athleteSubscriptionProps = {
    athleteNotificationSettings: row.foodLogNotificationSettings,
    teamNotificationSettings: teamFoodLogNotificationSettings,
  };

  const handleClickSubscribeToAthlete = () => {
    displaySubscribeToAthleteModal({
      athleteId: row.id,
      athleteNotificationSettings: athleteSubscriptionProps.athleteNotificationSettings,
    });
  };

  const displayEnableAccount = !isNotemealLinked || row.isProfileNotemealOnly;

  const displayHeight = isMetricLocale ? row.heightInCm : row.height;
  const displayWeight = isMetricLocale ? row.weightInKg : row.weight;
  const displayLeanBodyMass = isMetricLocale ? row.leanBodyMassInKg : row.leanBodyMass;

  return (
    <TableRow onClick={() => navigate(getNavOrgAthlete(row.id))}>
      <AthleteActions
        onClickEnableAccount={onClickEnableAccount}
        row={row}
        displaySubscribeToAthleteModal={handleClickSubscribeToAthlete}
        teamFoodLogNotificationSettings={teamFoodLogNotificationSettings}
        setEditing={setEditing}
        displayEnableAccount={displayEnableAccount}
      />

      <TableCell>{row.firstName}</TableCell>
      <TableCell>{row.lastName}</TableCell>

      <TableCell>{row.jerseyNumber}</TableCell>
      <TableCell>{row.username}</TableCell>
      <TableCell>{row.position?.name || ""}</TableCell>
      <TableCell className={classes.noWrap}>
        <NamedTagPreviewChipList
          namedTagsPreview={row.myNamedTagsPreview}
          hideAfter={2}
          chipClassName={classes.lastChild} />
      </TableCell>
      <TableCell className={classes.noWrap}>{row.birthDate}</TableCell>
      <TableCell>{displayHeight}</TableCell>
      <TableCell>{displayWeight}</TableCell>
      <TableCell>{displayLeanBodyMass}</TableCell>
      <TableCell className={classes.noWrap}>{row.goalType?.name || ""}</TableCell>
      <TableCell>{row.kcalOffset}</TableCell>
      <TableCell>{row.phoneNumber}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.secaUid}</TableCell>
      <TableCell>{row.inbodyUid}</TableCell>
    </TableRow>
  );
};

interface AthletesTableHeaderRowProps {
  view: TableView;
  sort: SortType | null;
  setSort: (sort: SortType | null) => void;
}

export const AthletesTableHeaderRow = ({ view, sort, setSort }: AthletesTableHeaderRowProps) => {
  const classes = useStyles();

  const handleSort = (newSort: SortType | null) => {
    if (newSort === sort) {
      setSort(null);
    } else {
      setSort(newSort);
    }
  };

  return (
    <TableRow>
      <TableCell>Actions</TableCell>
      <TableCell>First Name</TableCell>
      {view !== PROFILES ? (
        <TableCell onClick={() => handleSort(null)}>
          <div className={classes.sortableColumn}>
            Last Name
            {!sort ? <ArrowDownIcon /> : <div className={classes.icon} />}
          </div>
        </TableCell>
      ) : (
        <TableCell>Last Name</TableCell>
      )}

      {view === PROFILES && (
        <>
          <TableCell className={classes.noWrap}>Jersey #</TableCell>
          <TableCell>Username</TableCell>
        </>
      )}
      <TableCell>Position</TableCell>
      {view === PROFILES && (
        <>
          <TableCell>Tags</TableCell>
          <TableCell>Birthdate</TableCell>
          <TableCell>Height</TableCell>
          <TableCell>Weight</TableCell>
          <TableCell>Lean Mass</TableCell>
          <TableCell>Goal Type</TableCell>
          <TableCell>Goal kcal offset</TableCell>
          <TableCell>Phone #</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>SECA ID</TableCell>
          <TableCell>Inbody ID</TableCell>
        </>
      )}
      {view === ENGAGEMENT && (
        <>
          <TableCell onClick={() => handleSort(TOTAL_MEALS_COUNT)}>
            <div className={classes.sortableColumn}>
              Total Meals
              {sort === TOTAL_MEALS_COUNT ? <ArrowDownIcon /> : <div className={classes.icon} />}
            </div>
            <HelpTipIcon tip={"All meals are counted. Note: some meals can be both 'Logged' and 'Ordered'."} />
          </TableCell>
          <TableCell onClick={() => handleSort(MEALS_LOGGED_COUNT)}>
            <div className={classes.sortableColumn}>
              Meals Logged
              {sort === MEALS_LOGGED_COUNT ? <ArrowDownIcon /> : <div className={classes.icon} />}
              <HelpTipIcon tip={"Meals are only counted as 'Logged' if they include at least one comment, image or logged food."} />
            </div>
          </TableCell>
          <TableCell onClick={() => handleSort(MEALS_ORDERED_COUNT)}>
            <div className={classes.sortableColumn}>
              Meals Ordered
              {sort === MEALS_ORDERED_COUNT ? <ArrowDownIcon /> : <div className={classes.icon} />}
              <HelpTipIcon tip={"Meals are only counted as 'Ordered' if they include at least one In-House or Catering order."} />
            </div>
          </TableCell>
          <TableCell onClick={() => handleSort(MEAL_PLAN_VIEW_COUNT)}>
            <div className={classes.sortableColumn}>
              Meal Plan Views
              {sort === MEAL_PLAN_VIEW_COUNT ? <ArrowDownIcon /> : <div className={classes.icon} />}
              <HelpTipIcon
                tip={
                  "Meal Plan Views are counted everytime an athlete opens the Nutrition app and views their meal plan. It includes when an athlete logs from a meal plan, or when viewing a TXT msg link."
                }
              />
            </div>
          </TableCell>
          <TableCell onClick={() => handleSort(IMAGE_UPLOAD_COUNT)}>
            <div className={classes.sortableColumn}>
              Image Uploads
              {sort === IMAGE_UPLOAD_COUNT ? <ArrowDownIcon /> : <div className={classes.icon} />}
            </div>
          </TableCell>
        </>
      )}

      {view === PREFERENCES && (
        <TableCell onClick={() => handleSort(AVOIDED_FOODS)}>
          <div className={classes.sortableColumn}>
            Disliked Foods
            {sort === AVOIDED_FOODS ? <ArrowDownIcon /> : <div className={classes.icon} />}
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
