import { Box, Button, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Role } from "@notemeal/graphql/types";
import { useStreamChat } from "apps/web/src/contexts/StreamChatContext";
import { useUser } from "apps/web/src/utils/tokens";
import React, { PropsWithChildren } from "react";
import { ChannelListMessengerProps, ChannelSearch, LoadingChannels, NullComponent, useTranslationContext } from "stream-chat-react";

const useStyles = makeStyles(({ palette: { divider, info, common, greyscaleLight, text }, spacing }: Theme) =>
  createStyles({
    "@global .str-chat__channel-search.str-chat__channel-search--inline": {
      padding: 0,
      background: "transparent",
      width: "100%",
    },
    "@global input.str-chat__channel-search-input": {
      background: "transparent",
      border: `1.5px solid ${divider}`,
      borderRadius: "6px",
      height: "36px",
    },
    "@global input.str-chat__channel-search-input:focus": {
      // outline: `1px solid ${info.main} !important`,
      border: `1.5px solid ${info.main} !important`,
      boxShadow: "none !important",
    },
    "@global .str-chat__channel-search-container.str-chat__channel-search-result-list.inline": {
      position: "absolute",
      top: "36px",
      background: common.white,
      boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
      borderRadius: "6px",
    },
    "@global .str-chat__channel-search-results-header, .str-chat__channel-search-container-empty, .str-chat__channel-search-container-searching":
      {
        fontSize: "13px",
        marginLeft: spacing(1),
        color: text.secondary,
        padding: 0 + " !important",
      },
    "@global .str-chat__channel-search-result": {
      padding: spacing(1) + " !important",
    },
    "@global .str-chat__channel-search-result:hover": {
      fontWeight: 400 + " !important",
      background: greyscaleLight[200] + " !important",
    },
    "@global .str-chat__channel-search-container-empty svg": {
      display: "none",
    },
  })
);

const getStreamChatRolePrefix = (role: Role) => {
  if (role === Role.dietitian) {
    return "D";
  } else if (role === Role.chef) {
    return "C";
  } else if (role === Role.foodmanager) {
    return "FM";
  } else if (role === Role.athlete) {
    return "A";
  }

  // fallback to something that won't match any roles
  return "wont-match";
};
const getStreamChatNutritionRole = (orgId: string, role: Role) => `${orgId.split("-")[0]}-${getStreamChatRolePrefix(role)}`;

export const UnMemoizedChannelListContainer = (props: PropsWithChildren<ChannelListMessengerProps> & { onCreateChannel: () => void }) => {
  const { children, error = null, loading, LoadingErrorIndicator = NullComponent, LoadingIndicator = LoadingChannels } = props;
  const { t } = useTranslationContext("ChannelListMessenger");
  const { palette } = useTheme();
  const { startChat } = useStreamChat();
  useStyles();
  const user = useUser();

  if (error || !user) {
    return <LoadingErrorIndicator />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  // default to dietician filter
  const filterRoles: string[] | undefined = [];
  const orgMembership = user.orgMembership;

  if (!orgMembership) {
    // shouldn't get here, but fallback to force no lookups
    return ["fail-please"];
  }

  if (
    !user.orgMembership?.roles.includes(Role.dietitian) &&
    (user.orgMembership?.roles.includes("chef") || user.orgMembership?.roles.includes("foodmanager"))
  ) {
    filterRoles.push(getStreamChatNutritionRole(orgMembership.org.id, Role.dietitian));
    filterRoles.push(getStreamChatNutritionRole(orgMembership.org.id, Role.chef));
    filterRoles.push(getStreamChatNutritionRole(orgMembership.org.id, Role.foodmanager));
  }

  return (
    <Box
      sx={{
        background: palette.common.white,
        width: "384px",
        display: "flex",
        flexDirection: "column",
        borderRight: `1px solid ${palette.divider}`,
        height: "100%",
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${palette.divider}`,
          pY: 2,
          pl: "1px",
          pr: 2,
          height: "72.62px",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <ChannelSearch
          channelType="messaging"
          onSelectResult={(_, r) => startChat({ withUserId: r.id || "", name: "" })}
          searchQueryParams={{
            userFilters: {
              filters: {
                ...(filterRoles.length > 0 ? { nutritionRoles: { $in: filterRoles } } : {}),
              },
            },
          }}
        />
        <Button onClick={props.onCreateChannel} variant="outlined">
          Create
        </Button>
      </Box>
      <Box
        flex={1}
        sx={{ overflowY: "scroll" }}
        aria-label={t("aria/Channel list")}>
        {children}
      </Box>
    </Box>
  );
};

export const ChannelListContainer = React.memo(UnMemoizedChannelListContainer) as typeof UnMemoizedChannelListContainer;
