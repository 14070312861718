import InfoIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import CuisineFilter from "./Filters/CuisineFilter";
import DietFilter from "./Filters/DietFilter";
import PaymentMethodFilter from "./Filters/PaymentMethodFilter";
import RestaurantTypeFilter from "./Filters/RestaurantTypeFilter";
import TeamAndDeliveryLocationSelect from "./TeamAndDeliveryLocationSelect";
import { RestaurantSearchAction, RestaurantSearchState, hasLength, nullOrEmptyToUndefined } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    clearAllButton: {
      color: theme.palette.error.light,
    },
    textField: {
      width: "100%",
      paddingBottom: theme.spacing(2),
    },
  })
);

interface FilterContainerProps {
  state: RestaurantSearchState;
  dispatch: React.Dispatch<RestaurantSearchAction>;
  deliveryLocationTipForceOpen: boolean;
}

const FilterContainer = ({ state, dispatch, deliveryLocationTipForceOpen }: FilterContainerProps) => {
  const classes = useStyles();
  const [deliveryLocationTipOpen, setDeliveryLocationTipOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const filtersDisabled = !!state.teamLabel && !state.deliveryLocation;

  const hasFilter =
    hasLength(state.query) ||
    hasLength(state.cuisines) ||
    hasLength(state.diets) ||
    hasLength(state.paymentMethods) ||
    hasLength(state.restaurantTypes) ||
    !!state.teamLabel ||
    !!state.deliveryLocation;

  return (
    <>
      <div className={classes.flexSpaceBetween}>
        <Typography variant="h3">Filters</Typography>
        {hasFilter && (
          <Button
            size="small"
            variant="text"
            className={classes.clearAllButton}
            onClick={() =>
              dispatch({
                type: "RESET_FILTERS",
              })
            }
          >
            Clear All
          </Button>
        )}
      </div>
      <Typography variant="body1Medium">Restaurant</Typography>
      <TextField
        value={state.query}
        inputRef={inputRef}
        disabled={filtersDisabled}
        onChange={e =>
          dispatch({
            type: "CHANGE_QUERY",
            payload: { query: e.target.value },
          })
        }
        placeholder="Restaurant"
        className={classes.textField}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.flexSpaceBetween}>
        <Typography variant="body1Medium">Team and Delivery Location</Typography>
        <Tooltip
          open={deliveryLocationTipForceOpen || deliveryLocationTipOpen}
          onOpen={() => setDeliveryLocationTipOpen(true)}
          onClose={() => setDeliveryLocationTipOpen(false)}
          title={<Typography variant="body2Medium">Browse Teams and Delivery Locations to see nearby Restaurant Locations</Typography>}
        >
          <InfoIcon color="action" />
        </Tooltip>
      </div>
      <TeamAndDeliveryLocationSelect
        deliveryLocation={state.deliveryLocation}
        teamLabel={state.teamLabel}
        onChange={({ teamLabel, deliveryLocation }) =>
          dispatch({
            type: "CHANGE_TEAM_AND_DELIVERY_LOCATION",
            payload: { teamLabel, deliveryLocation },
          })
        }
      />
      <CuisineFilter
        selectedCuisines={state.cuisines}
        disabled={filtersDisabled}
        handleChange={newCuisines =>
          dispatch({
            type: "CHANGE_CUISINES",
            payload: {
              cuisines: nullOrEmptyToUndefined(newCuisines),
            },
          })
        }
      />
      <DietFilter
        selectedDiets={state.diets}
        disabled={filtersDisabled}
        handleChange={newDiets =>
          dispatch({
            type: "CHANGE_DIETS",
            payload: {
              diets: nullOrEmptyToUndefined(newDiets),
            },
          })
        }
      />
      <PaymentMethodFilter
        selectedPaymentMethods={state.paymentMethods}
        disabled={filtersDisabled}
        handleChange={newPaymentMethods =>
          dispatch({
            type: "CHANGE_PAYMENT_METHODS",
            payload: {
              paymentMethods: nullOrEmptyToUndefined(newPaymentMethods),
            },
          })
        }
      />
      <RestaurantTypeFilter
        selectedRestaurantTypes={state.restaurantTypes}
        disabled={filtersDisabled}
        handleChange={newRestaurantTypes =>
          dispatch({
            type: "CHANGE_RESTAURANT_TYPES",
            payload: {
              restaurantTypes: nullOrEmptyToUndefined(newRestaurantTypes),
            },
          })
        }
      />
    </>
  );
};

export default FilterContainer;
