import { Column, Workbook } from "exceljs";
import { addSelectionOptionsToMenuItem } from "@notemeal/shared/ui/MenuItem/utils";
import saveAs from "file-saver";
import { OrderPageMenuOrderItemFragment } from "apps/web/src/types";

interface OrderAthlete {
  firstName: string;
  lastName: string;
  jerseyNumber: string | null;
}

export const getAthleteDisplayNameForOrder = ({ firstName, lastName, jerseyNumber }: OrderAthlete) => {
  const fullName = `${lastName}, ${firstName}`;
  if (jerseyNumber) {
    return `${fullName} (#${jerseyNumber})`;
  } else {
    return fullName;
  }
};

type Header = "Pickup Time" | "Item" | "# Orders" | "Order #" | "Station Name" | "Ordered By" | "Options" | "Special Requests";

interface SerializedExportRow {
  item: string;
  pickupTime: string;
  orderNumber: string;
  stationName: string;
  orderedBy: string;
  options: string;
  specialRequests: string;
}

interface ExportColumnDef {
  header: Header;
  key: keyof SerializedExportRow;
  width: number;
  style?: Column["style"];
}

const exportColumns: ExportColumnDef[] = [
  { header: "Order #", key: "orderNumber", width: 8 },
  { header: "Item", key: "item", width: 20 },
  { header: "Pickup Time", key: "pickupTime", width: 10 },
  { header: "Station Name", key: "stationName", width: 12 },
  { header: "Ordered By", key: "orderedBy", width: 16 },
  { header: "Options", key: "options", width: 30 },
  { header: "Special Requests", key: "specialRequests", width: 30, style: { alignment: { wrapText: true } } },
];

interface ExportToExcelProps {
  orderItems: OrderPageMenuOrderItemFragment[];
  exportFilename: string;
  itemIdsToDiningStationName: { [menuOrderItemId: string]: string };
}

const serializeRows = (
  orderItems: OrderPageMenuOrderItemFragment[],
  menuOrderItemIdsToDiningStationName: { [menuOrderItemId: string]: string }
): SerializedExportRow[] => {
  return orderItems.map(o => {
    const athlete = o.order.athlete;
    return {
      item: o.menuItem.name,
      pickupTime: new Date(o.order.pickupTime).toLocaleTimeString(),
      stationName: menuOrderItemIdsToDiningStationName[o.id],
      orderNumber: o.order.code,
      orderedBy: athlete ? getAthleteDisplayNameForOrder(athlete) : o.order.userFullName,
      options: addSelectionOptionsToMenuItem(o.menuItem, o.options)
        .choices.map(
          c =>
            c.options.length > 0 &&
            `${c.name}: ${c.options
              .flatMap(o => (o.menuSelectionItemOption ? [`${o.name} (${o.menuSelectionItemOption.amount})`] : []))
              .join(", ")}`
        )
        .filter(r => !!r)
        .join("\r\n"),
      specialRequests: o.specialRequests ?? "",
    };
  });
};

export const exportToExcel = async ({ orderItems, exportFilename, itemIdsToDiningStationName }: ExportToExcelProps) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  worksheet.columns = exportColumns;
  worksheet.getRow(1).alignment = { horizontal: "center", vertical: "middle" };
  const rows = serializeRows(orderItems, itemIdsToDiningStationName);
  worksheet.addRows(rows);
  saveAs(new Blob([await workbook.xlsx.writeBuffer()]), exportFilename);
};
