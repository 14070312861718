import { LinkedProfile, teamworksProfileHasRequiredFields } from "@notemeal/profile-sync";
import React from "react";
import ProfilesList from "./ProfilesList";

interface LinkedProfilesListProps {
  profiles: LinkedProfile[];
  selectedId: string | null;
}

const LinkedProfilesList = (props: LinkedProfilesListProps) => {
  return (
    <ProfilesList
      {...props}
      title="Linked Profiles"
      columnMode="NoAction"
      disableOnSelected
      profiles={props.profiles.map(p => ({ ...p, id: p.teamworks.id }))}
      getProfileForDisplay={({ teamworks }) => ({
        firstName: teamworks.firstName,
        lastName: teamworks.lastName,
        email: teamworks.email,
        phoneNumber: teamworks.cellPhone,
        missingTeamworksRequiredFields: !teamworksProfileHasRequiredFields(teamworks),
      })}
    />
  );
};

export default LinkedProfilesList;
