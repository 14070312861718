import { InputAdornment, MenuItem, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { getHeightUnitForLocale, getWeightUnitForLocale } from "@notemeal/shared/utils/macro-protocol";
import React, { Dispatch } from "react";
import { SexType } from "../../../../types";
import { MacroProtocolAction, MacroProtocolState } from "../reducer/macroProtocolReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    horizontalContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(4, 10, 0, 10),
    },
    input: { flex: "45%", maxWidth: "45%", margin: theme.spacing(2) },
  })
);

interface SampleDataProps {
  state: MacroProtocolState;
  dispatch: Dispatch<MacroProtocolAction>;
}

const SampleDataForm = ({ state, dispatch }: SampleDataProps) => {
  const classes = useStyles();
  const { isMetricLocale } = useLocaleContext();

  const {
    anthropometry,
    calorieBudget: { goals, selectedPreviewGoal },
  } = state;
  const { __typename, sampleAgeInput, sampleSex } = anthropometry;
  let requireHeight = false;
  let requireWeight = false;
  let requirePercentBodyFat = false;
  let sampleWeight = "";
  let sampleHeight = "";
  let samplePercentBodyFat = "";

  if (__typename === "FormFields") {
    const { formFields, sampleWeightInput, sampleHeightInput, samplePercentBodyFatInput } = anthropometry;
    requireHeight = formFields.includes("height");
    requireWeight = formFields.includes("weight");
    requirePercentBodyFat = formFields.includes("percentBodyFat");
    sampleWeight = sampleWeightInput;
    sampleHeight = sampleHeightInput;
    samplePercentBodyFat = samplePercentBodyFatInput;
  }

  const weightText = getWeightUnitForLocale(isMetricLocale);
  const heightText = getHeightUnitForLocale(isMetricLocale);

  return (
    <div className={classes.verticalContainer}>
      <Typography className={classes.title}>
        Enter sample athlete data to see nutrition target estimates. Values entered will not affect any athlete data, but can be helpful
        when building the rest of the meal plan template.
      </Typography>
      <div className={classes.horizontalContainer}>
        <TextField
          className={classes.input}
          label="Age"
          value={sampleAgeInput}
          type="number"
          onChange={e => dispatch({ type: "EDIT_SAMPLE_AGE", payload: e.target.value })}
          InputProps={{ endAdornment: <InputAdornment position="end">yrs</InputAdornment> }}
        />
        <TextField
          className={classes.input}
          select
          value={sampleSex}
          label="Sex"
          onChange={e => dispatch({ type: "EDIT_SAMPLE_SEX", payload: e.target.value as SexType })}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
        </TextField>
        {requireWeight && (
          <TextField
            className={classes.input}
            label="Weight"
            value={sampleWeight}
            type="number"
            onChange={e => dispatch({ type: "EDIT_SAMPLE_WEIGHT", payload: e.target.value, isMetricLocale })}
            InputProps={{ endAdornment: <InputAdornment position="end">{weightText}</InputAdornment> }}
          />
        )}
        {requireHeight && (
          <TextField
            className={classes.input}
            label="Height"
            value={sampleHeight}
            type="number"
            onChange={e => dispatch({ type: "EDIT_SAMPLE_HEIGHT", payload: e.target.value, isMetricLocale })}
            InputProps={{ endAdornment: <InputAdornment position="end">{heightText}</InputAdornment> }}
          />
        )}
        {requirePercentBodyFat && (
          <TextField
            className={classes.input}
            label="Percent Body Fat"
            value={samplePercentBodyFat}
            type="number"
            onChange={e => dispatch({ type: "EDIT_SAMPLE_PERCENT_BODY_FAT", payload: e.target.value })}
            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
          />
        )}
        <TextField
          className={classes.input}
          select
          value={selectedPreviewGoal?.id ?? ""}
          onChange={event => {
            const newGoal = goals.find(goal => goal.id === (event.target.value as string));
            dispatch({
              type: "SET_SELECTED_PREVIEW_GOAL",
              payload: newGoal ?? null,
            });
          }}
          label="Goal Type"
        >
          {goals.map(goal => (
            <MenuItem key={goal.id} value={goal.id}>
              {goal.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
};

export default SampleDataForm;
