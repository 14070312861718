import CloseIcon from "@mui/icons-material/Close";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import RightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton, styled, useTheme } from "@mui/material";
import ImageWithLockedAspectRatio from "@notemeal/shared/ui/Image/ImageWithLockedAspectRatio";
import { ASPECT_16_9 } from "@notemeal/shared/ui/Image/utils";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import ImageFileDragAndDrop from "../../../universal/Image/ImageFileDragAndDrop";
import { Image } from "../utils";
import { IMAGE_WIDTH, decrementPosition, incrementPosition } from "./utils";

const ImageItem = styled(Box)(() => ({ width: `${IMAGE_WIDTH}px` }));

const ImageGridItem = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  margin: 2,
}));

const InsetButton = styled(IconButton)(() => ({
  color: "white",
  backgroundColor: "black",
  opacity: 0.6,
  "&:hover": {
    color: "white",
    backgroundColor: "black",
  },
}));
InsetButton.defaultProps = {
  size: "small",
};

interface UploadImageProps {
  handleDrop: (url: string) => void;
  disabled?: boolean;
}

export const UploadImage = ({ handleDrop, disabled }: UploadImageProps) => {
  return (
    <ImageGridItem>
      <Box sx={{ width: `${IMAGE_WIDTH}px`, height: `${IMAGE_WIDTH / ASPECT_16_9}px` }}>
        <ImageFileDragAndDrop disabled={disabled} onUpload={handleDrop} />
      </Box>
    </ImageGridItem>
  );
};

export const LoadingImage = () => {
  return (
    <ImageGridItem>
      <ImageItem>
        <Loading progressSize="md" />
      </ImageItem>
    </ImageGridItem>
  );
};

interface LoadedImageProps {
  image: Image;
  images: Image[];
  onChangeImages: (images: Image[]) => void;
  disabled?: boolean;
}

export const LoadedImage = ({ image, images, onChangeImages, disabled }: LoadedImageProps) => {
  const { spacing } = useTheme();

  const deleteImage = (id: string) => {
    const remainingImages = images.filter(image => image.id !== id);
    const imagesWithUpdatedPositions = remainingImages.map((image, index) => {
      return { url: image.url, position: index + 1, id: image.id } as Image;
    });
    onChangeImages(imagesWithUpdatedPositions);
  };

  return (
    <ImageGridItem key={image.id}>
      <ImageItem>
        <ImageWithLockedAspectRatio imageUrl={image.url} borderRadius={spacing(0.5)}></ImageWithLockedAspectRatio>
      </ImageItem>

      <Box sx={{ position: "absolute", top: spacing(0.5), left: spacing(0.5) }}>
        <InsetButton disabled={disabled} onClick={() => deleteImage(image.id)}>
          <CloseIcon fontSize="small" />
        </InsetButton>
      </Box>

      <Box sx={{ position: "absolute", display: "flex", top: spacing(0.5), right: spacing(0.5) }}>
        <InsetButton
          disabled={image.position === 1 || images.length === 1}
          onClick={() => onChangeImages(decrementPosition(image.position, images))}
        >
          <LeftIcon fontSize="small" />
        </InsetButton>
        <InsetButton
          disabled={image.position === images.length || images.length === 1}
          onClick={() => onChangeImages(incrementPosition(image.position, images))}
        >
          <RightIcon fontSize="small" />
        </InsetButton>
      </Box>
    </ImageGridItem>
  );
};
