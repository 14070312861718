import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { createStyles, makeStyles } from "@mui/styles";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Avatar, Box, LinearProgress, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React, { ChangeEvent, ReactNode, createRef, useState } from "react";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    avatarBackground: {
      backgroundColor: palette.greyscale[300],
    },
    avatarHoverBackground: {
      backgroundColor: palette.accents.blueDark[700],
    },
    iconColor: {
      color: palette.highEmphasisText,
    },
    iconHoverColor: {
      color: "white",
    },
    boxColor: {
      backgroundColor: palette.greyscale[50],
      borderColor: palette.greyscale[200],
    },
    boxColorHover: {
      backgroundColor: palette.info.lighter,
      borderColor: palette.info.main,
    },
    boxColorDisabled: {
      backgroundColor: palette.greyscale[200],
      borderColor: palette.greyscale[200],
    },
    boxColorError: {
      backgroundColor: palette.error.lighter,
      borderColor: palette.error.main,
    },
    textStyle: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
    },
  })
);

interface ImageFileDragAndDropProps {
  onUpload: (imageUrl: string, filename: string) => void;
  children?: ReactNode;
  sx?: SxProps;
  loading?: boolean;
  hasError?: boolean;
  errorMessage?: ReactNode;
  disabled?: boolean;
  hasFile?: boolean;
}

export const ImageFileDragAndDrop = ({
  onUpload,
  children,
  sx,
  loading,
  hasError,
  errorMessage,
  disabled,
  hasFile,
}: ImageFileDragAndDropProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setMessage } = useSnackbar();
  const [isHover, setIsHover] = useState(false);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsHover(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
    setIsHover(true);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    await convertFileToUrl(validateFile(files));
    setIsHover(false);
  };

  const handleDragExit = (event: React.DragEvent<HTMLDivElement>) => {
    setIsHover(false);
  };

  const inputRef = createRef<HTMLInputElement>();
  const openFileFinder = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const convertFileToUrl = async (file: File | null): Promise<void> => {
    if (file && file.name) {
      const url = await getUrlPromiseFromFile(file);
      onUpload(url, file.name);
    }
  };

  const getUrlPromiseFromFile = (file: File) => {
    return new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result.toString());
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const validateFile = (files: FileList | null): File | null => {
    if (files) {
      if (files.length !== 1) {
        setMessage("error", `Please import one image at a time.`);
        return null;
      }
      const validatedFile = Array.from(files).filter(file => {
        if (file.type.startsWith("image")) {
          return true;
        } else {
          setMessage("error", `'${file.name}' is not an image file and will not be uploaded.`);
          return false;
        }
      });
      return validatedFile[0];
    }
    return null;
  };

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragExit={handleDragExit}
      onDragLeave={handleDragExit}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={openFileFinder}
      sx={{
        ...sx,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid lightgray",
        borderRadius: "6px",
        padding: 0,
        flexDirection: "column",
        borderWidth: "2px",
      }}
      className={
        hasError
          ? classes.boxColorError
          : !disabled && isHover
          ? classes.boxColorHover
          : disabled
          ? classes.boxColorDisabled
          : classes.boxColor
      }
    >
      {hasError ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding={theme.spacing(3)}
          gap={1}>
          <Avatar sx={{ backgroundColor: theme.palette.error.main }}>
            <InfoOutlined sx={{ fill: "white", color: "transparent" }} />
          </Avatar>
          <Typography
            variant="caption"
            color={theme.palette.error.main}
            fontWeight={500}>
            Upload failed, please try again
          </Typography>
          {errorMessage}
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding={theme.spacing(2, 3)}
          gap={1}>
          {children}
          <Avatar className={!disabled && isHover ? classes.avatarHoverBackground : classes.avatarBackground}>
            <CloudUploadIcon className={disabled || isHover ? classes.iconHoverColor : classes.iconColor} fontSize="large" />
          </Avatar>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              component="div"
              className={classes.textStyle}
              display="inline">
              <Typography
                display="inline"
                fontSize="12px"
                fontWeight={600}
                sx={{
                  color: disabled ? theme.palette.lowEmphasisText : theme.palette.info.main,
                  ...(!disabled && { "&:hover": { cursor: "pointer", color: theme.palette.info.dark } }),
                }}
              >
                {hasFile ? "Replace file " : "Click to upload "}
              </Typography>
              or drag and drop file here.
            </Typography>
            <Typography className={classes.textStyle}>PNG or JPEG</Typography>
          </Box>
        </Box>
      )}
      <input
        disabled={disabled}
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        accept="image/*"
        onChange={async (event: ChangeEvent<HTMLInputElement>) => await convertFileToUrl(validateFile(event.target.files))}
      />
      {loading && (
        <Box width="100%">
          <LinearProgress
            sx={{
              height: theme.spacing(1),
              backgroundColor: theme.palette.greyscale[200],
              "& .MuiLinearProgress-bar": {
                backgroundColor: theme.palette.info.main,
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};
