import { useAthleteTransferOrgGroupsQuery } from "apps/web/src/types";
import { useUser } from "apps/web/src/utils/tokens";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { AdminRoute, ProtectedRoute, RoleRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { TagsPage } from "./TagsPage";
import {
  NAV_TEAM_MANAGEMENT_TAGS,
  NAV_TEAM_MANAGEMENT_TEAMS,
  NAV_TEAM_MANAGEMENT_TRANSFERS,
  teamManagementLink,
} from "./TeamManagementPaths";
import { TeamsPage } from "./TeamsPage";
import { TransfersPage } from "./TransfersPage";

interface SecondaryNavLayoutPropsArgs {
  isTransfersAllowed: boolean;
}

const transfersLink = {
  name: "Transfers",
  to: NAV_TEAM_MANAGEMENT_TRANSFERS,
  requiresAdmin: true,
};

const getSecondaryNavLayoutProps = ({ isTransfersAllowed }: SecondaryNavLayoutPropsArgs): SecondaryNavLayoutProps => ({
  current: teamManagementLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Teams",
      to: NAV_TEAM_MANAGEMENT_TEAMS,
    },
    {
      name: "Tags",
      to: NAV_TEAM_MANAGEMENT_TAGS,
    },
    ...(isTransfersAllowed ? [transfersLink] : []),
  ],
});

export const TeamManagementRouter = () => {
  const user = useUser();
  const { data: athleteTransferOrgGroupsData } = useAthleteTransferOrgGroupsQuery({
    skip: !user?.orgMembership?.roles.includes("dietitian"),
  });
  const isTransfersAllowed = (athleteTransferOrgGroupsData?.athleteTransferOrgGroups.length ?? 0) > 0;

  return (
    <Routes>
      <Route element={<SecondaryNavLayout {...getSecondaryNavLayoutProps({ isTransfersAllowed })} />}>
        <Route element={<RoleRoute roles={["dietitian"]} />}>
          <Route index element={<TeamsPage />} />
          <Route path={NAV_TEAM_MANAGEMENT_TEAMS} element={<TeamsPage />} />
          <Route path={NAV_TEAM_MANAGEMENT_TAGS} element={<TagsPage />} />
          <Route
            path={NAV_TEAM_MANAGEMENT_TRANSFERS}
            element={
              <ProtectedRoute isAllowed={() => isTransfersAllowed}>
                <AdminRoute>
                  <TransfersPage />
                </AdminRoute>
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};
