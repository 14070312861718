import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { getViewAttendancePath } from "apps/web/src/pages/Auth/Org/ViewAttendance/ViewAttendanceRouter";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom-v5-compat";
import { ViewAttendanceForm } from "./ViewAttendanceForm";
import { ViewAttendanceFormSchema, ViewAttendanceFormType } from "./ViewAttendanceFormSchema";

interface ViewAttendanceDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ViewAttendanceDialog = ({ open, onClose }: ViewAttendanceDialogProps) => {
  const navigate = useNavigate();
  const { setMessage } = useSnackbar();
  const { formatDateWithLocale } = useDateFormatting();

  const form = useForm<ViewAttendanceFormType>({
    resolver: zodResolver(ViewAttendanceFormSchema),
    mode: "onChange",
  });

  const { isValid } = form.formState;
  const handleClose = () => {
    onClose();
  };

  const submit = ({ startDate, endDate }: ViewAttendanceFormType) => {
    const startDateEndDateParamString = `?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    const successMessage = `Attendance Report for ${formatDateWithLocale(startDate)} to ${formatDateWithLocale(endDate)} has been built`;
    setMessage("success", successMessage);
    navigate(`${getViewAttendancePath()}${startDateEndDateParamString}`);
  };

  return (
    <Dialog
      PaperProps={{
        sx: { height: "400px" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle title="View Attendance Report" onClose={handleClose} />
      <DialogContent>
        <ViewAttendanceForm form={form} />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={!isValid} onClick={form.handleSubmit(submit)}>
            Continue
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
