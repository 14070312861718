import { loadWorkbook } from "../../../../../utils/import/load";
import { parseWorksheet, ImportedField } from "../../../../../utils/import/parse";
import { IFileImport } from "../../../../universal/Import/Button";
import { ImportableAthlete } from "../types";
import { SexType } from "../../../../../types";
import { stripPunctuation } from "../../../../../utils/import/punctuation";
import { RichText } from "exceljs";

interface GeneralXlsxRosterFileImportFields {
  "First Name": string;
  "Last Name": string;
  Position: string;
  Team: string;
  Birthdate: string | null; // 01/01/1992
  Email: string | null;
  Phone: string | null;
  Sex: SexType | null;
  Height: string | null;
  Weight: string | null;
  "Inbody ID": string | null;
}

export type GeneralXlsxRosterFileImportFieldsKeys = { [key in keyof GeneralXlsxRosterFileImportFields]: string };
type I = ImportableAthlete;
type R = GeneralXlsxRosterFileImportFieldsKeys;

const numberFields: ImportedField<R, I>[] = [
  {
    fields: ["Weight"],
    importableField: "weight",
  },
  {
    fields: ["Height"],
    importableField: "height",
  },
];
const stringFields: ImportedField<R, I>[] = [
  {
    fields: ["Team"],
    importableField: "teamName",
    required: true,
  },
  {
    fields: ["First Name"],
    importableField: "firstName",
    required: true,
  },
  {
    fields: ["Last Name"],
    importableField: "lastName",
    required: true,
  },
  // {field: 'Weight', importableField: 'weight'},
  {
    fields: ["Position"],
    importableField: "positionName",
  },
  {
    fields: ["Email"],
    importableField: "email",
    transform: (email: string | { richText: RichText[] } | null) =>
      typeof email === "string" || email === null ? email : email.richText[0].text,
  },
  {
    fields: ["Phone"],
    importableField: "phoneNumber",
    transform: (phoneNumber: string | null) => {
      if (!phoneNumber) {
        return phoneNumber;
      }
      const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      const groups = phoneNumber.match(re);
      if (!groups) {
        return phoneNumber;
      }
      const [countryCode, areaCode, exchange, subscriberNumber, extension] = groups.slice(1);
      return `+${countryCode || "1"}(${areaCode}) ${exchange}-${subscriberNumber}${extension || ""}`;
    },
  },
  {
    fields: ["Sex"],
    importableField: "sex",
    transform: (sexish: string) =>
      ["m", "male", "m", "men", "mens"].includes(stripPunctuation(sexish?.toLowerCase() || ""))
        ? "male"
        : ["f", "female", "w", "women", "womens"].includes(stripPunctuation(sexish?.toLowerCase() || ""))
        ? "female"
        : null,
  },
  { fields: ["Inbody ID"], importableField: "inbodyUid" },
];

const dateFields: ImportedField<R, I>[] = [{ fields: ["Birthdate"], importableField: "birthDate" }];

export const loadGeneralXlsxImportableAthletes = async ({ file, onError, locale }: IFileImport): Promise<ImportableAthlete[] | null> => {
  // type F = GeneralXlsxRosterFileImportFields;
  const worksheet = await loadWorkbook({ file, onError });
  if (worksheet === null) {
    return null;
  }
  const rows = parseWorksheet<R, I>({
    worksheet,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return rows;
  // TODO: convert everything to lowercase
};
