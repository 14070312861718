import React from "react";
import { RecipeFullFragment, useRecipeDuplicateDialogQuery } from "../../types";
import LoadingBackdrop from "../universal/LoadingBackdrop";
import RecipeCreateDialog from "./RecipeCreateDialog";
import { recipeToFormState } from "./utils";

interface RecipeDuplicateDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (recipe: RecipeFullFragment) => void;
  duplicatedRecipeId: string;
  forStaff?: boolean;
}

const RecipeDuplicateDialog = ({ open, onClose, onCreate, duplicatedRecipeId, forStaff }: RecipeDuplicateDialogProps) => {
  const { data, loading } = useRecipeDuplicateDialogQuery({
    variables: {
      id: duplicatedRecipeId,
    },
  });

  const duplicatedRecipeForForm = data && recipeToFormState({ ...data.recipe, name: "[Copy of] " + data.recipe.name });

  return loading || !data ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <RecipeCreateDialog
      open={open}
      onClose={onClose}
      onCreate={onCreate}
      initialRecipeState={duplicatedRecipeForForm}
      forStaff={forStaff}
    />
  );
};

export default RecipeDuplicateDialog;
